import {
  FaBookOpen,
  FaRankingStar,
  FaRegStarHalfStroke,
} from "react-icons/fa6";
import { SiRoblox } from "react-icons/si";

import "../../style/stats.css";
import { useEffect, useState } from "react";
import { TbTargetArrow } from "react-icons/tb";

import { FaUsers } from "react-icons/fa6";
import { Incentive } from "./readingRoom/Incentive";
import { RiVerifiedBadgeLine } from "react-icons/ri";
import { Badges } from "./Badges";

export const StudentStats = (props) => {
  const ld = props?.studentData?.user;
  const [totalStars, setTotalStars] = useState(0);
  const [totalReads, setTotalReads] = useState(0);
  const [badge, setBadge] = useState("");
  const [totalQuizzes, setTotalQuizzes] = useState(0);
  const [totalMembers, setTotalMembers] = useState(0);

  const type = props.type;
  const teamData = type === "in-team" ? props.teamData : {};

  useEffect(() => {
    if (props.leaderBoardData) {
      const array = props.leaderBoardData;
      let ts = 0;
      let tr = 0;

      let tq = 0;
      array.map((member, index) => {
        ts = ts + parseInt(member.stars);
        tr = tr + parseInt(member.read);

        tq = tq + parseInt(member.quiz);
      });
      setTotalStars(ts);
      setTotalReads(tr);

      setTotalQuizzes(tq);
      setTotalMembers(array.length);
    }
  }, [props]);

  function CircularProgressBar(props) {
    return (
      <div className="stat-box">
        <div
          style={{
            display: "flex",
            maxWidth: "fit-content",
            width: "5em",
          }}
        >
          <div style={{ width: "100%" }}>{props.children}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="stats-container">
      <div className="stats-table">
        <div className="stat-box">
          <div className="stat-box-top stat-box-stars">
            <FaRegStarHalfStroke className="stats-icon" />

            {props?.type === "in-team"
              ? totalStars
              : props?.readerProfile?.stars}
          </div>
          <span className="stat-box-bottom">Stars</span>
        </div>

        {type !== "in-team" && (
          <div className="stat-box">
            <div className="stat-box-top">
              <FaRankingStar className="stats-icon" />
              {type !== "in-team"
                ? props.rank
                : props.teamRank || props?.readerProfile?.rank || ""}
            </div>
            <span className="stat-box-bottom">Rank</span>
          </div>
        )}

        {type !== "in-team" && (
          <div className="stat-box">
            <div className="stat-box-top">
              <SiRoblox />
              {props?.studentData?.studentInfo?.robux ||
                props?.readerProfile?.roblox ||
                0}
            </div>
            <span className="stat-box-bottom">Robux</span>
          </div>
        )}

        {type === "in-team" && (
          <div className="stat-box">
            <div className="stat-box-top">
              <FaUsers />

              {totalMembers || 3}
            </div>

            <span className="stat-box-bottom">Members</span>
          </div>
        )}

        <div className="stat-box">
          <div className="stat-box-top">
            <FaBookOpen className="stats-icon" />
            {props?.type === "in-team" ? totalReads : props.readerProfile?.read}
          </div>

          <span className="stat-box-bottom">Reads</span>
        </div>

        <div className="stat-box">
          <div className="stat-box-top">
            <TbTargetArrow className="stats-icon" />
            {props?.type === "in-team"
              ? totalQuizzes
              : props.readerProfile?.perfectScore}
          </div>
          <span className="stat-box-bottom">Bullseye</span>
        </div>

        <div className="stat-box">
          <div className="stat-box-top">
            <RiVerifiedBadgeLine className="stats-icon" />
            {badge?.level || ""}
          </div>

          <span className="stat-box-bottom stat-box-badge-bottom">
            {props.readerProfile?.badge || ""}
          </span>
        </div>
      </div>

      <Badges studentInfo={props.studentData} />
      {!props.readerProfile?.hideIncentive && (
        <Incentive
          readerProfile={props?.readerProfile}
          increment={0}
          setBadge={setBadge}
        />
      )}
    </div>
  );
};
