import { ToastContainer, toast } from "react-toastify";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../style/student.css";

import { logout } from "../../firebase-functions/auth";
import logo from "../../assets/img/logo.png";
import { FcCollapse } from "react-icons/fc";

import {
  FcHome,
  FcReading,
  FcMoneyTransfer,
  FcSms,
  FcKey,
  FcSportsMode,
  FcInvite,
  FcQuestions,
  FcPortraitMode,
  FcReadingEbook,
} from "react-icons/fc";
import { PopUpAreYouSure } from "../utils/PopUpAreYouSure";

export const StudentNavbar = (props) => {
  const [toggleNavbar, setNavbar] = useState(true);
  const [links, setLinks] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [popUpLogout, setPopUpLogout] = useState(false);

  const handleLogout = () => {
    logout().then((res) => {
      if (res.success) {
        toast.success("logged out successfully");
        setTimeout(() => {
          window.location.replace("/login");
        }, 500);
      } else {
        toast.error("Something went horribly wrong");
      }
    });
  };

  const linkDirectory = {
    guest: [
      {
        name: "home",
        link: "/",
      },
      {
        name: "about",
        link: "/about",
      },
      {
        name: "contact",
        link: "/contact",
      },
    ],
    reader: [
      {
        name: "dashboard",
        link: "/",
      },
      // {
      //   name: "teams",
      //   link: "/teams",
      // },
      {
        name: "reading room",
        link: "/reading-room",
      },

      {
        name: "contact",
        link: "/contact",
      },
      {
        name: "account",
        link: "/account",
      },
    ],
    not_verified: [
      {
        name: "dashboard",
        link: "/",
      },
      {
        name: "contact",
        link: "/contact",
      },
      {
        name: "account",
        link: "/account",
      },
    ],
    expired_reader: [
      {
        name: "dashboard",
        link: "/",
      },

      {
        name: "contact",
        link: "/contact",
      },
      {
        name: "account",
        link: "/account",
      },
    ],
    demo_reader: [
      {
        name: "reading room",
        link: "/reading-room",
      },
      {
        name: "leaderboard",
        link: "/dashboard",
      },

      {
        name: "contact",
        link: "/contact",
      },
      {
        name: "account",
        link: "/account",
      },
    ],
    accountant: [
      {
        name: "pending payments",
        link: "/pending-payments",
      },
      {
        name: "payments",
        link: "/payments",
      },
      {
        name: "contact",
        link: "/contact",
      },
      {
        name: "account",
        link: "/account",
      },
    ],
    admin: [
      {
        name: "books",
        link: "/books",
      },
      {
        name: "competition",
        link: "/competition",
      },
      {
        name: "email",
        link: "/email",
      },
    ],
  };

  const iconDirectory = {
    home: <FcHome className="navbar-icon" />,
    about: <FcQuestions className="navbar-icon" />,
    dashboard: <FcHome className="navbar-icon" />,
    leaderboard: <FcHome className="navbar-icon" />,
    "reading room": <FcReading className="navbar-icon" />,
    contact: <FcSms className="navbar-icon" />,
    payments: <FcMoneyTransfer className="navbar-icon" />,
    "pending payments": <FcMoneyTransfer className="navbar-icon" />,
    "make payment": <FcMoneyTransfer className="navbar-icon" />,
    competition: <FcReading className="navbar-icon" />,
    email: <FcInvite className="navbar-icon" />,
    login: <FcKey className="navbar-icon" />,
    logout: <FcSportsMode className="navbar-icon" />,
    account: <FcPortraitMode />,
    books: <FcReadingEbook />,
  };
  const [isNavOpen, setNavOpen] = useState(false);

  const toggleNav = () => {
    setNavOpen(!isNavOpen);
  };

  useEffect(() => {
    setActiveTab(window.location.pathname);
  }, [window.location.pathname]);

  const [isMobile, setIsMobile] = useState(false);
  const role = props?.role;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Initial check
    handleResize();

    // Add event listener for resizing
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [toggleNavbar]);

  useEffect(() => {
    if (role) {
      setLinks(linkDirectory[role.split(" ").join("_")]);
    }
  }, [role]);

  return (
    <div>
      <ToastContainer />
      <nav
        className={`navbar ${isMobile ? "mobile" : ""}  ${
          toggleNavbar ? "" : "navbar-container-closed"
        }`}
      >
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="logo book-battle" className="img-logo" />
          </Link>
        </div>
        {isMobile ? (
          <></>
        ) : (
          <div className={`navbar-container`}>
            <button
              className={
                toggleNavbar ? "btn-collapse-navbar" : "btn-expand-navbar"
              }
              onClick={() => {
                setNavbar(!toggleNavbar);
              }}
            >
              <FcCollapse />
            </button>
            <ul className="nav-links">
              {links?.map((li) => (
                <li key={li.name}>
                  <Link to={li.link}>{li.name}</Link>
                </li>
              ))}

              {role === "guest" && (
                <Link to="login" className="btn-logout">
                  Login
                </Link>
              )}
              {["admin", "new", "not verified"].includes(role) && (
                <button
                  className="btn-logout"
                  onClick={() => {
                    handleLogout();
                  }}
                >
                  Logout
                </button>
              )}

              {role === "student" && (
                <button
                  className="btn-logout"
                  onClick={() => {
                    handleLogout();
                  }}
                >
                  Logout
                </button>
              )}
            </ul>
          </div>
        )}
      </nav>
      <div className="burger">
        <ul className="nav-links-mobile-open">
          {links?.map((li, i) => (
            <li
              key={i}
              className={activeTab === li.link ? "mobile-link-active" : ""}
            >
              <Link
                to={li.link}
                onClick={() => {
                  setActiveTab(li.link);
                }}
              >
                {iconDirectory[li.name]}
              </Link>
            </li>
          ))}

          {role === "guest" && (
            <Link
              to="login"
              className="btn-logout"
              onClick={() => {
                setNavOpen(false);
              }}
            >
              {iconDirectory["login"]}
            </Link>
          )}
          {["admin", "new", "not verified", "student"].includes(role) && (
            <FcSportsMode
              className="btn-logout"
              style={{ margin: "auto", fontSize: "2em" }}
              onClick={() => {
                handleLogout();
              }}
            />
          )}
        </ul>
      </div>
    </div>
  );
};
