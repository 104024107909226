import { useEffect } from "react";
import tributes from "../../assets/json/tribute.json";
import { createDocument } from "../../firebase-functions/database";
import { auth } from "../../firebase-functions/config";
export const UploadTribute = () => {
  const competition = "pilot";

  useEffect(() => {
    tributes.map((tribute) => {
      tribute.competition = competition;
      //   createDocument("tributes", tribute.name, tribute, auth.currentUser).then(
      //     (res) => {
      //       if (res.success) {
      //         console.log(tribute.name, "have been uploaded successfully");
      //       } else {
      //         console.error(res.message);
      //       }
      //     }
      //   );
    });
  }, []);

  return (
    <div className="upload-container">
      <h1>Upload Tribute</h1>
    </div>
  );
};
