import { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa6";
import { toast } from "react-toastify";
import Achievement from "../../utils/Acheivement";
import { MdOutlineStar } from "react-icons/md";
import AnimatedNumber from "./AnimatedNumber";
import CupGame from "../cupGame/CupGame";
import { Incentive } from "./Incentive";
import { updateDocument } from "../../../firebase-functions/database";
import { auth } from "../../../firebase-functions/config";

export const Quiz = (props) => {
  const [questions, setQuestions] = useState();
  const [questionIndex, setQuestionIndex] = useState(0);
  const [activeQuestion, setActiveQuestion] = useState();
  const [answers, setAnswers] = useState({});
  const [showWpm, setShowWpm] = useState(false);
  const [score, setScore] = useState(0);
  const [stars, setStars] = useState(0);
  const [passed, setPassed] = useState(false);
  const [isQuizOver, setIsQuizOver] = useState(false);
  const [isPerfectScore, setIsPerfectScore] = useState(false);
  const [showAchievement, setShowAchievement] = useState(false);
  const [cupGame, setCupGame] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [mobileQuestionIndexes, setMobileIndexes] = useState([8, 9, 0, 1, 2]);

  useEffect(() => {
    const q = props?.bookInfo?.data?.quiz?.questions;

    if (q?.length > 0) {
      setQuestions(q.filter((qu) => qu.choices?.length > 0));

      setActiveQuestion(q[0]);
    }
    if (window.screen.orientation.type == "portrait-primary") {
      setIsMobile(true);
    }
  }, [props?.bookInfo]);

  useEffect(() => {
    if (questions && questionIndex !== undefined) {
      setActiveQuestion(questions[questionIndex]);
    }
    if (isMobile) {
      let newArray = [
        (questionIndex - 2 + questions.length) % questions.length,
      ];
      for (let i = 1; i < 5; i++) {
        newArray.push(
          // questionIndex + i - 1 === 9
          //   ? 10
          //   :
          (questionIndex + i - 2 + questions.length) % questions.length
        );
      }
      setMobileIndexes(newArray);
    }
  }, [questionIndex]);

  useEffect(() => {
    if (isMobile) {
      let newArray = [
        (questionIndex - 2 + questions.length) % questions.length,
      ];
      for (let i = 1; i < 5; i++) {
        newArray.push(
          // questionIndex + i - 1 === 9
          //   ? 10
          //   :
          (questionIndex + i - 2 + questions.length) % questions.length
        );
      }
      setMobileIndexes(newArray);
    }
  }, [questions]);

  const decodedText = (text) => {
    if (text) {
      return text.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
    } else {
      return "";
    }
  };

  const getChoice = (index) => {
    const choices = ["A", "B", "C", "D", "E", "F", "G"];
    return choices[index];
  };

  const handleAnswer = (answer) => {
    const newAnswerObject = { ...answers };

    newAnswerObject[questionIndex] = answer;

    setAnswers(newAnswerObject);
  };

  const handleSubmit = () => {
    if (!isQuizOver) {
      if (answers) {
        const tempScore = Object.values(answers).filter(
          (answer) => answer.isTrue === true
        ).length;
        setScore(tempScore);
        setIsQuizOver(true);

        const percentile = tempScore / questions?.length;
        let tempStars = 0;
        let tempPerfectScore = props?.studentInfo?.data?.perfectScore || 0;

        if (percentile >= 0.95) {
          setIsPerfectScore(true);

          setStars(10);
          tempPerfectScore = tempPerfectScore + 1;
          tempStars = 10;
          toast.success("Bullseye!");
        } else if (percentile >= 0.9) {
          setStars(8);
          tempStars = 8;

          toast.success("Great Work!");
        } else if (percentile >= 0.7) {
          setStars(6);
          tempStars = 6;
          toast.success("Good job!");
        } else if (percentile >= 0.5) {
          setStars(5);
          tempStars = 5;
          toast.success("Nice! You Passed!");
        } else {
          setStars(0);

          toast.error("Sorry! You failed!");
        }

        if (percentile >= 0.5) {
          let history = props?.studentInfo?.readHistory || [];

          history.push(props?.bookInfo?.data?.bookId);
          setPassed(true);
          let performance = props?.studentInfo?.performance || [];

          performance.push({
            book: {
              id: props?.bookInfo?.data?.bookId,
              title: props?.bookInfo?.data?.title,
              wordLength: parseInt(
                props?.bookInfo?.data.type.split(" ")[
                  props?.bookInfo?.data.type.split(" ").length - 2
                ]
              ),
              pages: props.totalPages - 1,
            },
            stats: {
              score: tempScore,
              percentile: percentile,
              wpm: props.wpm,
              duration: props.readingTime,
            },

            dateRead: new Date(),
          });

          updateDocument(
            "students",
            auth.currentUser.uid,
            {
              readHistory: history,
              performance: performance,
            },
            auth.currentUser
          ).then((res) => {
            if (!res.success) {
              toast.error("something went wrong student profile", res.message);
            }
          });
          let demoReaderPerformance = props?.readerProfile?.performance || [];
          demoReaderPerformance.push({
            book: {
              bookId: props?.bookInfo?.data?.bookId,
              title: props?.bookInfo?.data?.title,
              wordLength: parseInt(
                props?.bookInfo?.data.type.split(" ")[
                  props?.bookInfo?.data.type.split(" ").length - 2
                ]
              ),
              pages: props.totalPages - 1,
            },
            stats: {
              score: tempScore,
              percentile: percentile,
              wpm: props.wpm,
              duration: props.readingTime,
            },
            dateRead: new Date(),
          });
          if (props?.competitionInfo?.status === "in progress") {
            updateDocument(
              "demoReaders",
              props.readerProfile.id,
              {
                stars: parseInt(props.readerProfile.stars) + tempStars,
                quiz: parseInt(props.readerProfile.quiz) + 1,
                read: parseInt(props.readerProfile.read) + 1,
                perfectScore: tempPerfectScore
                  ? parseInt(props.readerProfile?.perfectScore + 1 || 1)
                  : parseInt(props.readerProfile?.perfectScore || 0),
                performance: demoReaderPerformance,
              },
              auth.currentUser
            ).then((res) => {
              if (!res.success) {
                toast.error("something went wrong reader profile", res.message);
              }
              setShowAchievement(true);
              setTimeout(() => {
                setShowAchievement(false);
              }, 6500);
            });
          }
        }
      }
    } else {
      toast.error("The quiz is already over");
    }
  };
  return (
    <div className="quiz-container">
      {!isQuizOver && (
        <>
          <div className="navigation-container">
            {!isMobile && (
              <button
                className={`btn-navigate ${
                  questionIndex === 0 ? "btn-navigate-disabled" : ""
                }`}
                onClick={() => {
                  if (questionIndex > 0) {
                    setQuestionIndex(questionIndex - 1);
                  }
                }}
              >
                Previous
              </button>
            )}
            <div className="quiz-indexes-container">
              {questions && !isMobile
                ? questions.map((question, index) => (
                    <button
                      key={question.question}
                      onClick={() => {
                        setQuestionIndex(index);
                      }}
                      className={`btn-index ${
                        answers[index] ? "btn-index-completed" : ""
                      } ${
                        activeQuestion?.question === question.question
                          ? answers[index]
                            ? "btn-index-completed-active"
                            : "btn-index-active"
                          : ""
                      }`}
                    >
                      {answers[index] ? (
                        <FaCheck
                          className="icon-check"
                          fill="#fff"
                          height={"1em"}
                        />
                      ) : (
                        <p className="index-number">{index + 1}</p>
                      )}
                    </button>
                  ))
                : mobileQuestionIndexes.map((qIndex) => (
                    <button
                      key={qIndex}
                      onClick={() => {
                        setQuestionIndex(qIndex);
                      }}
                      className={`btn-index ${
                        answers[qIndex] ? "btn-index-completed" : ""
                      }
                      
                      `}
                    >
                      {answers[qIndex] ? (
                        <FaCheck
                          className="icon-check"
                          fill="#fff"
                          height={"1em"}
                        />
                      ) : (
                        <p className="index-number">
                          {qIndex === questions?.length - 1
                            ? questions?.length
                            : (qIndex + 1 + questions?.length) %
                              questions?.length}
                        </p>
                      )}
                    </button>
                  ))}
            </div>
            {Object.values(answers)?.length >= questions?.length ? (
              <button onClick={handleSubmit} className="btn-next">
                Submit
              </button>
            ) : (
              <button
                className={`btn-navigate btn-next ${
                  questionIndex === questions?.length - 1
                    ? "btn-navigate-disabled"
                    : ""
                }`}
                onClick={() => {
                  if (questionIndex < questions.length - 1) {
                    setQuestionIndex(questionIndex + 1);
                  }
                }}
              >
                Next
              </button>
            )}
          </div>
          <div className="questions-container">
            <h4
              className="question-header"
              dangerouslySetInnerHTML={{
                __html: decodedText(activeQuestion?.question),
              }}
            ></h4>

            <div className="choices-container">
              {activeQuestion?.choices?.map((choice, index) => (
                <div
                  className={
                    choice.answer === answers[questionIndex]?.answer
                      ? "div-choice div-choice-active"
                      : "div-choice"
                  }
                  key={choice.answer}
                  onClick={() => {
                    handleAnswer(choice);
                  }}
                >
                  <h2 className="btn-choice-index">{getChoice(index)}</h2>

                  <h4
                    className="text-choice"
                    dangerouslySetInnerHTML={{
                      __html: decodedText(choice?.answer),
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {isQuizOver && !cupGame && (
        <div className="quiz-results-container">
          <div className="div-stars">
            <MdOutlineStar />
            <h3>
              <AnimatedNumber value={stars} />
            </h3>
          </div>

          <div className="readTime"></div>
          <div className="div-score">
            <h3>Score: </h3>
            <h3>
              {score}/{questions?.length}
            </h3>
          </div>
          <div className="div-score">
            <h3>Speed: </h3>
            <h3
              onMouseEnter={() => {
                setShowWpm(true);
              }}
              onMouseLeave={() => {
                setShowWpm(false);
              }}
            >
              <AnimatedNumber value={props.wpm} />
              {showWpm && <p className="wpm-helper">words per minute</p>}
            </h3>
          </div>
          {passed && props.competitionInfo?.status === "in progress" && (
            <Incentive
              readerProfile={props?.readerProfile}
              stars={stars}
              setStars={setStars}
              increment={1}
            />
          )}

          <div className="navigate-buttons">
            <button onClick={() => window.location.reload()}>
              Go back to books
            </button>
            {isPerfectScore && (
              <button
                className="btn-cupgame"
                onClick={() =>
                  setTimeout(() => {
                    setCupGame(true);
                  }, 1500)
                }
              >
                Play Cups Game to get extra 5 points
              </button>
            )}
          </div>
        </div>
      )}

      {showAchievement ? <Achievement /> : <></>}

      {cupGame && (
        <CupGame
          readerProfile={props?.readerProfile}
          stars={stars}
          studentInfo={props?.studentInfo}
        />
      )}
    </div>
  );
};
