import { Link } from "react-router-dom";
import { IoLogoAndroid } from "react-icons/io";
import { IoLogoApple } from "react-icons/io5";
import "../../style/utils.css";
import { getDocument } from "../../firebase-functions/database";
import { useEffect, useState } from "react";

export const Download = () => {
  const [updateVersion, setUpdateVersion] = useState(1);
  const [updateDescription, setUpdateDescription] = useState();
  const version = 1.1;

  useEffect(() => {
    getDocument("app", "current").then((res) => {
      if (res.success) {
        setUpdateVersion(res.data.version);
        setUpdateDescription(res.data?.description || "");
      }
    });
  }, []);

  return (
    <div className="download-container">
      {updateVersion === version ? (
        <h3>Download the App to keep better track of the competition</h3>
      ) : (
        <>
          <h3>New Version Available</h3>
          <h3>
            <strong>{updateVersion} : </strong> {updateDescription}
          </h3>
        </>
      )}
      <p>You can either download the app or add to your home screen</p>
      <div className="links">
        <Link to="/download/apk">
          Android <IoLogoAndroid />{" "}
        </Link>
        <Link to="/download/ios">
          iOS <IoLogoApple />
        </Link>
      </div>
    </div>
  );
};
