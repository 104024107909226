import { StudentNavbar } from "../student/StudentNavbar";
import sadDino from "../../assets/img/404/404dinosaur.jpeg";
import "../../style/404.css";

export const NotFound = (props) => {
  return (
    <div className="container-404">
      <div className="not-founf-box">
        <h2>Page Not Found</h2>
        <img src={sadDino} alt="404 dinosaur" className="img-dinosaur" />
      </div>
    </div>
  );
};
