import { IoIosFlash } from "react-icons/io";
import { FaDice, FaSkullCrossbones } from "react-icons/fa";
import { AiFillLike } from "react-icons/ai";
import { FaRegBookmark, FaBookmark } from "react-icons/fa";
import { useEffect, useState } from "react";
import { updateDocument } from "../../../firebase-functions/database";
import { auth } from "../../../firebase-functions/config";
import { toast } from "react-toastify";
import { FaHeadphones } from "react-icons/fa";

const decodedText = (text) => {
  if (text) {
    return text.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
  } else {
    return "";
  }
};

export const BookCard = (props) => {
  const bk = props.bk;
  const setOpenBookListen = props.setOpenBookListen;
  const setActiveBook = props.setActiveBook;
  const [bookMark, setBookMark] = useState(false);
  const studentInfo = props.studentInfo;
  const [info, setInfo] = useState(false);

  const addToBookmarks = () => {
    let studentBookMarks = props.bookmarks || [];
    studentBookMarks.push({
      id: bk.id,
      page: 0,
    });
    props.setBookmarks(studentBookMarks);
    updateDocument(
      "students",
      auth.currentUser.uid,
      { bookmarks: studentBookMarks },
      auth.currentUser
    ).then((res) => {
      if (res.success) {
        toast.success(bk.title + " added to bookmarks");
      } else {
        toast.error("Something went wrong with bookmarks", res.message);
      }
    });
  };

  const removeFromBookmarks = () => {
    let studentBookMarks =
      props.bookmarks?.filter((bkm) => bkm.id !== bk.id) || [];
    props.setBookmarks(studentBookMarks);
    updateDocument(
      "students",
      auth.currentUser.uid,
      { bookmarks: studentBookMarks },
      auth.currentUser
    ).then((res) => {
      if (res.success) {
        toast.success(bk.title + " removed from bookmarks");
      } else {
        toast.error("Something went wrong with bookmarks", res.message);
      }
    });
  };

  useEffect(() => {
    props?.bookmarks !== undefined &&
      studentInfo?.bookmarks &&
      studentInfo?.bookmarks?.map((bkm) => {
        if (bkm.id === bk.id) {
          setBookMark(true);
        }
      });
  }, [props.studentInfo]);
  return (
    <>
      {info && (
        <div
          className="active-book-div-closer"
          onClick={() => {
            setInfo(false);
          }}
        >
          <div className="active-book-div-closer-face"> </div>
        </div>
      )}
      <div
        className={
          !info
            ? "book-container"
            : bk.orientation === "portrait"
            ? "book-container book-container-active"
            : "book-container book-container-active book-container-active-landscape"
        }
        index={bk.id}
        onClick={() => {
          setInfo(!info);
        }}
      >
        <div className="book-container-clicker"></div>

        {!bookMark && (
          <FaRegBookmark
            className="bookmark"
            onClick={() => {
              setBookMark(true);
              addToBookmarks();
            }}
          />
        )}
        {bookMark && (
          <FaBookmark
            fill="orange"
            className="bookmark"
            onClick={() => {
              setBookMark(false);
              removeFromBookmarks();
            }}
          />
        )}

        <img
          className={
            bk.orientation === "portrait"
              ? "book-thumbnail"
              : "book-thumbnail book-thumbnail-landscape"
          }
          src={`
https://mi.content.readinga-z.com/raz_book_image/${bk.id}/projectable/small/1/book/page-0.jpg`}
          onClick={() => {
            // setActiveBook(bk);
            setInfo(true);
          }}
        />
        {bk.audio && (
          <div
            className={
              !info
                ? "book-card-listeing-icon-container"
                : "book-card-listeing-icon-container-open"
            }
          >
            <div className="div-book-card-listeing-icon">
              <FaHeadphones />
            </div>
          </div>
        )}
        {info && (
          <div className="extra-book-info-div">
            <p className="book-title">{bk.title}</p>
            <div className="div-tags">
              {bk.tags.map((tag) => (
                <p className="book-tag" key={tag}>
                  {" "}
                  {tag}
                </p>
              ))}

              <p className="book-tag">{bk.wordLength} words</p>
            </div>
            <p
              className="summary"
              dangerouslySetInnerHTML={{ __html: bk.summary }}
            ></p>
            <div className="div-info-active-buttons">
              <button
                onClick={() => {
                  setOpenBookListen(false);
                  setActiveBook(bk);
                }}
              >
                Read
              </button>
              {bk.audio && (
                <button
                  onClick={() => {
                    setOpenBookListen(true);
                    setActiveBook(bk);
                  }}
                >
                  Listen
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
