import { useEffect, useState } from "react";
import { AccountantNavbar } from "./AccountantNavbar";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase-functions/config";
import {
  getAllPayments,
  getAllPaymentsByCountry,
  getDocument,
} from "../../firebase-functions/database";

import "../../style/accountant.css";

export const Payments = (props) => {
  const [accountantInfo, setAccountantInfo] = useState();
  const [payments, setPayments] = useState([]);
  const [activePayment, setActivePayment] = useState();

  useEffect(() => {
    onAuthStateChanged(auth, (u) => {
      getDocument("accountants", auth.currentUser.uid).then((res) => {
        if (res.success) {
          setAccountantInfo(res.data);
        }
      });
    });
  }, []);

  useEffect(() => {
    if (accountantInfo) {
      getAllPaymentsByCountry(accountantInfo.country).then((res) => {
        if (res.success) {
          setPayments(res.message);
        }
      });
    } else if (props.userInfo.role === "admin") {
      getAllPayments().then((res) => {
        if (res.success) {
          setPayments(res.message);
        }
      });
    }
  }, [accountantInfo]);

  const getPaymentAge = (date) => {
    const currentDate = new Date();

    let paymentSince =
      parseInt(currentDate.getTime()) - parseInt(date.seconds) * 1000;

    const day = Math.floor(paymentSince / (1000 * 60 * 60 * 24));
    const hour = Math.floor(
      (paymentSince % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minute = Math.floor((paymentSince % (1000 * 60 * 60)) / (1000 * 60));

    if (day > 0) {
      return day + " day" + (day > 1 ? "s" : "") + " ago";
    }
    if (hour > 0) {
      return hour + " hour" + (hour > 1 ? "s" : "") + " ago";
    }
    if (minute > 0) {
      return minute + " minute" + (minute > 1 ? "s" : "") + " ago";
    } else {
      return "just now";
    }
  };

  const getRespondTime = (respondTime) => {
    const day = Math.floor(respondTime / (1000 * 60 * 60 * 24));
    const hour = Math.floor(
      (respondTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minute = Math.floor((respondTime % (1000 * 60 * 60)) / (1000 * 60));

    if (day > 0) {
      return "in " + day + " day" + (day > 1 ? "s" : "");
    }
    if (hour > 0) {
      return "in " + hour + " hour" + (hour > 1 ? "s" : "");
    }
    if (minute > 0) {
      return "in " + minute + " minute" + (minute > 1 ? "s" : "");
    } else {
      return "immediately";
    }
  };

  const CustomDateFieldForDateCreated = (props) => {
    const date = props.date.seconds;
    return (
      <td className="td-text-left">{`${new Date(
        date * 1000
      ).getDate()}/${new Date(date * 1000).getMonth()}/${
        new Date(date * 1000).getYear() + 1900
      } - ${new Date(date * 1000).getHours()}:${new Date(
        date * 1000
      ).getMinutes()} -\t(${getPaymentAge(props.date)})`}</td>
    );
  };

  const CustomDateFieldForDateUpdated = (props) => {
    const payment = props.payment;

    const date =
      payment.status === "confirmed"
        ? payment?.dateApproved?.seconds
        : payment.status === "denied"
        ? payment?.dateDeclined?.seconds
        : 0;

    const respondTime = (date - payment.dateCreated.seconds) * 1000;
    if (payment?.status === "new") {
      return <td></td>;
    } else {
    }
    return (
      <td className="td-text-left">
        {`${new Date(date * 1000).getDate()}/${new Date(
          date * 1000
        ).getMonth()}/${new Date(date * 1000).getYear() + 1900} - ${new Date(
          date * 1000
        ).getHours()}:${new Date(
          date * 1000
        ).getMinutes()} -\t ( ${getRespondTime(respondTime)})`}
      </td>
    );
  };

  return (
    <div className="container">
      <h2>Payments</h2>

      {payments.length > 0 && (
        <table className="payments-table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Name</th>
              <th scope="col">Amount</th>
              <th scope="col">Status</th>
              <th scope="col">Created At</th>
              <th scope="col">Updated At</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment, index) => {
              return (
                <tr
                  key={payment.paymentSlip}
                  onClick={() => setActivePayment(payment)}
                >
                  <td>{index + 1}</td>
                  <td>{payment?.student?.name}</td>
                  <td>{payment.fee}</td>

                  <td>
                    <div className={`payment-status status-${payment.status}`}>
                      {payment.status}
                    </div>
                  </td>
                  <CustomDateFieldForDateCreated date={payment.dateCreated} />
                  <CustomDateFieldForDateUpdated payment={payment} />
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {activePayment && (
        <div>
          <div className="payment-pop-up-container">
            <h2>Payment</h2>
            <img
              src={activePayment.paymentSlip}
              className="payment-slip-large"
              alt="paymentSlip"
            />
          </div>

          <div
            className="payment-pop-up-background"
            onClick={() => setActivePayment()}
          ></div>
        </div>
      )}
    </div>
  );
};
