import { useEffect, useState } from "react";
import library from "../../assets/json/library.json";

import "../../style/adminBooks.css";
import {
  getBookIdsWithAudio,
  getBookInfo,
} from "../../firebase-functions/database";
import TextToSpeech from "../audio/TextToSpeech";
import { GenerateSpeech } from "../audio/GenerateSpeech";

export const AdminBooks = () => {
  const [search, setSearch] = useState();
  const [books, setBooks] = useState();
  const [levelFilter, setLevelFilter] = useState();
  const [activeBook, setActiveBook] = useState();
  const [page, setPage] = useState(0);
  const [bookContent, setBookContent] = useState();

  const [audioOrder, setAudiOrder] = useState();

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      "https://book-battle.com/guest-room/" +
        (activeBook.id * process.env.REACT_APP_SALT + 17).toString(36)
    );
  };

  useEffect(() => {
    if (!search && (!levelFilter || levelFilter !== undefined)) {
      setBooks(library);
      return;
    }
    if (levelFilter !== undefined && search) {
      setBooks(
        library
          .filter((book) => book.level === levelFilter)
          .filter((book) =>
            JSON.stringify(book).toLowerCase().includes(search.toLowerCase())
          )
      );
      return;
    }
    if (levelFilter) {
      setBooks(library.filter((book) => book.level === levelFilter));
      return;
    }

    if (search) {
      setBooks(
        library.filter((book) =>
          JSON.stringify(book).toLowerCase().includes(search.toLowerCase())
        )
      );
    }
  }, [search, levelFilter]);

  //
  useEffect(() => {
    if (activeBook) {
      let order = -1;
      const array = library
        .filter((bk) => bk.level === activeBook.level)
        .sort((a, b) => a.id - b.id);
      array.map((bk, i) => {
        if (bk.id === activeBook.id) {
          order = i;
        }
        setAudiOrder(order);
      });
    }
  }, [activeBook]);

  return (
    <div className="admin-books-container">
      <div className="div-sidebar">
        <h2>Books</h2>
        <div className="admin-books-search-bar-div">
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <select
            name="filter"
            id="filterLevel"
            value={levelFilter}
            onChange={(e) => {
              setLevelFilter(e.target.value);
            }}
          >
            <option value={undefined}>-</option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="D">D</option>
            <option value="E">E</option>
            <option value="F">F</option>
            <option value="G">G</option>
            <option value="H">H</option>
            <option value="I">I</option>
            <option value="J">J</option>
            <option value="K">K</option>
            <option value="L">L</option>
            <option value="M">M</option>
            <option value="N">N</option>
            <option value="O">O</option>
            <option value="P">P</option>
            <option value="Q">Q</option>
            <option value="R">R</option>
            <option value="S">S</option>
            <option value="T">T</option>
            <option value="U">U</option>
            <option value="V">V</option>
            <option value="W">W</option>
            <option value="X">X</option>
            <option value="Y">Y</option>
            <option value="Z">Z</option>
            <option value="Z1">Z1</option>
            <option value="Z2">Z2</option>
          </select>
        </div>

        <div className="admin-books-buttons-container">
          {books &&
            books.map((bk, index) => (
              <div
                key={bk.id + "-" + index}
                onClick={() => {
                  setActiveBook(bk);
                  getBookInfo(bk.id).then((res) => {
                    if (res.success) {
                      setBookContent(res.message.data.content);
                    }
                  });
                }}
              >
                <button>{bk.title}</button>
              </div>
            ))}
        </div>
      </div>
      <div className="div-main-bar">
        {activeBook && (
          <div className="div-main-grid">
            <div className="admin-books-action-buttons">
              <button
                onClick={() => {
                  handleCopyLink();
                }}
              >
                copy share link
              </button>
            </div>
            <div className="div-book-page-content">
              <div className="admin-books-page-container">
                <div
                  className="btn-last-page"
                  onClick={() => {
                    setPage(page - 1);
                  }}
                >
                  {" "}
                </div>
                <div
                  className="btn-next-page"
                  onClick={() => {
                    setPage(page + 1);
                  }}
                >
                  {" "}
                </div>
                <img
                  src={
                    "https://mi.content.readinga-z.com/raz_book_image/" +
                    activeBook.id +
                    "/projectable/large/1/book/page-" +
                    page +
                    ".jpg"
                  }
                  alt=""
                />
              </div>
              <div className="div-content-play">
                <h1>{audioOrder}</h1>
                <div className="div-play">
                  <TextToSpeech content={bookContent} />
                  <GenerateSpeech content={bookContent} />
                </div>
                <textarea
                  name="content"
                  id="content"
                  value={bookContent}
                  onChange={(e) => {
                    setBookContent(e.target.value);
                  }}
                ></textarea>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
