import React from "react";
import "../../style/guest.css";
import "../../style/about.css";

const AboutPage = () => {
  return (
    <div className="about-page">
      <h2>About Us</h2>
      <p>
        Welcome to our online reading platform designed to encourage kids to
        read more books!
      </p>

      <h3>Our Solution</h3>
      <p>
        We tackle the common problem of kids not wanting to read by setting up a
        fun competition. Our system calculates kids' reading speed and
        administers an extensive quiz after each book, making it difficult to
        cheat. We reward accurate quiz scores over the number of books read.
      </p>

      <h3>Pricing</h3>
      <p>
        Choose the plan that suits your needs. We offer flexible payment options
        for both individual users and schools.
      </p>

      <table>
        <thead>
          <tr>
            <th>Package</th>
            <th>Monthly Price (USD)</th>
            <th>3 Months Price (USD)</th>
            <th>1 Year Price (USD)</th>
            <th>Books</th>
            <th>AudioBooks</th>
            <th>Game Passes per Month</th>
            <th>Badge Rewards</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Library Plan (Base)</td>
            <td>$3</td>
            <td>$6</td>
            <td>$10</td>
            <td>700</td>
            <td>250</td>
            <td>1</td>
            <td>No</td>
          </tr>
          <tr>
            <td>Competition Plan (Premium)</td>
            <td>$5</td>
            <td>$10</td>
            <td>$20</td>
            <td>700</td>
            <td>250</td>
            <td>5</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Enterprise Plan (Schools)</td>
            <td>Contact Us</td>
            <td>Contact Us</td>
            <td>Contact Us</td>
            <td>700</td>
            <td>250</td>
            <td>5</td>
            <td>Custom</td>
          </tr>
        </tbody>
      </table>

      <p>
        Our Library Plan (Base) offers unlimited access to books and includes 1
        free competition pass. The Competition Plan (Premium) includes unlimited
        books and 5 competitions every month.
      </p>
      <p>
        Schools can contact us for enterprise pricing tailored to their number
        of students. We offer flexible and competitive rates.
      </p>

      <p>
        Join our{" "}
        <a href="https://t.me/+UiSMCWLpVeBiNmQ9" target="_blank">
          Telegram group
        </a>{" "}
        or follow our{" "}
        <a
          href="https://www.facebook.com/profile.php?id=61560882686825"
          target="_blank"
        >
          Facebook Page
        </a>{" "}
        to connect with us!
      </p>

      <p>
        Our Library Plan (Base) offers unlimited access to books and includes 1
        free competition pass. The Competition Plan (Premium) includes unlimited
        books and 5 competitions every month.
      </p>
      <p>
        Schools can contact us for enterprise pricing tailored to their number
        of students. We offer flexible and competitive rates.
      </p>

      <p>
        Join our Book-Battle community today and start your reading adventure!
      </p>

      <h3>Books</h3>
      <p>
        We offer over 2500 high-quality reading books, focusing on early phonics
        up to intermediate levels. Our books feature rich vocabulary, positive
        morals, and an appealing design.
      </p>

      <h3>Reading Tools</h3>
      <p>
        Our platform is designed to simulate the experience of reading a
        physical book. With features like page-flipping animations, book
        thumbnails, and summaries, the desktop version feels just like reading a
        book.
      </p>
    </div>
  );
};

export default AboutPage;
