import { PiDogFill } from "react-icons/pi";
import { IoLogoOctocat } from "react-icons/io5";
import { GiParrotHead } from "react-icons/gi";
import { GiTigerHead } from "react-icons/gi";

import { GiKoala } from "react-icons/gi";

import { RiMotorbikeFill } from "react-icons/ri";
import { FaTruckMonster } from "react-icons/fa6";
import { GiSteamLocomotive } from "react-icons/gi";
import { GoRocket } from "react-icons/go";
import { GiMiniSubmarine } from "react-icons/gi";

export const RoomIcons = (props) => {
  const pet = props.pet;
  const car = props.car;
  const color = props.color;
  const handleClick = props.handleClick;

  return (
    <div className="username-icon-box-container">
      <h3>Choose your pet</h3>

      <div className="username-icon-box">
        {(!pet || pet === 1) && (
          <PiDogFill
            onClick={() => {
              handleClick("pet", 1);
            }}
          />
        )}
        {(!pet || pet === 2) && (
          <IoLogoOctocat
            onClick={() => {
              handleClick("pet", 2);
            }}
          />
        )}
        {(!pet || pet === 3) && (
          <GiParrotHead
            onClick={() => {
              handleClick("pet", 3);
            }}
          />
        )}
        {(!pet || pet === 4) && (
          <GiTigerHead
            onClick={() => {
              handleClick("pet", 4);
            }}
          />
        )}
        {(!pet || pet === 5) && (
          <GiKoala
            onClick={() => {
              handleClick("pet", 5);
            }}
          />
        )}
      </div>
      <div className="username-icon-box-container">
        <h3>Choose your vehicle</h3>

        <div className="username-icon-box">
          {(!car || car === 1) && (
            <RiMotorbikeFill
              onClick={() => {
                handleClick("car", 1);
              }}
            />
          )}
          {(!car || car === 2) && (
            <FaTruckMonster
              onClick={() => {
                handleClick("car", 2);
              }}
            />
          )}
          {(!car || car === 3) && (
            <GiSteamLocomotive
              onClick={() => {
                handleClick("car", 3);
              }}
            />
          )}
          {(!car || car === 4) && (
            <GoRocket
              onClick={() => {
                handleClick("car", 4);
              }}
            />
          )}
          {(!car || car === 5) && (
            <GiMiniSubmarine
              onClick={() => {
                handleClick("car", 5);
              }}
            />
          )}
        </div>
      </div>
      <div className="username-icon-box-container">
        <h3>Choose your color</h3>

        <div className="username-icon-box">
          {(!color || color === 1) && (
            <div
              className="username-icon-box-color"
              style={{
                background: `linear-gradient(to right, #ff6666 0%, #dd0000 100%)`,
              }}
              onClick={() => {
                handleClick("color", 1);
              }}
            >
              &nbsp;{" "}
            </div>
          )}
          {(!color || color === 2) && (
            <div
              className="username-icon-box-color"
              style={{
                background: `linear-gradient(to right, #0000FF 0%, #1E90FF 100%)`,
              }}
              onClick={() => {
                handleClick("color", 2);
              }}
            >
              &nbsp;{" "}
            </div>
          )}
          {(!color || color === 3) && (
            <div
              className="username-icon-box-color"
              style={{
                background: `linear-gradient(to right, #A2FFFF 0%, #40E0D0 100%)`,
              }}
              onClick={() => {
                handleClick("color", 3);
              }}
            >
              &nbsp;{" "}
            </div>
          )}
          {(!color || color === 4) && (
            <div
              className="username-icon-box-color"
              style={{
                background: `linear-gradient(to right, #FF69B4 0%, #FF1493 100%)`,
              }}
              onClick={() => {
                handleClick("color", 4);
              }}
            >
              &nbsp;{" "}
            </div>
          )}
          {(!color || color === 5) && (
            <div
              className="username-icon-box-color"
              style={{
                background: `linear-gradient(to right, #FFDAB9 0%, #FFA07A 100%)`,
              }}
              onClick={() => {
                handleClick("color", 5);
              }}
            >
              &nbsp;{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const IconsRow = (props) => {
  const pet = props.pet;
  const car = props.car;
  const color = props.color;
  return (
    <div className="room-icons-row">
      {(!pet || pet === 1) && <PiDogFill />}
      {(!pet || pet === 2) && <IoLogoOctocat />}
      {(!pet || pet === 3) && <GiParrotHead />}
      {(!pet || pet === 4) && <GiTigerHead />}
      {(!pet || pet === 5) && <GiKoala />}
      {(!car || car === 1) && <RiMotorbikeFill />}
      {(!car || car === 2) && <FaTruckMonster />}
      {(!car || car === 3) && <GiSteamLocomotive />}
      {(!car || car === 4) && <GoRocket />}
      {(!car || car === 5) && <GiMiniSubmarine />}

      {(!color || color === 1) && (
        <div
          className="room-icons-row-box-color"
          style={{
            background: `linear-gradient(to right, #ff6666 0%, #dd0000 100%)`,
          }}
        >
          &nbsp;{" "}
        </div>
      )}
      {(!color || color === 2) && (
        <div
          className="room-icons-row-box-color"
          style={{
            background: `linear-gradient(to right, #0000FF 0%, #1E90FF 100%)`,
          }}
        >
          &nbsp;{" "}
        </div>
      )}
      {(!color || color === 3) && (
        <div
          className="room-icons-row-box-color"
          style={{
            background: `linear-gradient(to right, #A2FFFF 0%, #40E0D0 100%)`,
          }}
        >
          &nbsp;{" "}
        </div>
      )}
      {(!color || color === 4) && (
        <div
          className="room-icons-row-box-color"
          style={{
            background: `linear-gradient(to right, #FF69B4 0%, #FF1493 100%)`,
          }}
        >
          &nbsp;{" "}
        </div>
      )}
      {(!color || color === 5) && (
        <div
          className="room-icons-row-box-color"
          style={{
            background: `linear-gradient(to right, #FFDAB9 0%, #FFA07A 100%)`,
          }}
        >
          &nbsp;{" "}
        </div>
      )}
    </div>
  );
};
