import { useEffect, useState } from "react";
import "../../style/guest.css";
import { getDefaultCompetition } from "../../firebase-functions/database";
import { CountDown } from "../student/CountDown";

import { Link } from "react-router-dom";

import { WhyBookBattle } from "./WhyBookBattle";

export const LandingPage = () => {
  const [competitionInfo, setCompetitionInfo] = useState();
  useEffect(() => {
    getDefaultCompetition().then((res) => {
      setCompetitionInfo(res.message);
    });
  }, []);
  return (
    <div className="landing-container">
      <div className="section-promo"></div>
      <div className="section-promo-background"></div>
      <div className="overlay-container">
        <div className="overlay-box">
          <h1>
            Encourage a Love for Reading with{" "}
            <span className="h1-number">Book-Battle</span>
          </h1>
          <p>
            Transform your child's screen time into a productive and engaging
            reading journey!
          </p>
          <div className="div-join-us">
            <Link to="/guest-room/5ebeijod" className="join-us-button">
              Check out our books
            </Link>
          </div>
        </div>
      </div>
      <div className="why-book-battle">
        <WhyBookBattle competitionInfo={competitionInfo} />
      </div>
      <div className="countdown">
        <CountDown competition={competitionInfo} />
      </div>
    </div>
  );
};
