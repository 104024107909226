import { useState } from "react";
import { GamePassPassCode, UniqueIconById } from "../auth/Password";
import { FaRobot, FaLine } from "react-icons/fa6";
import { FaTelegram, FaFacebook } from "react-icons/fa";

export const JoinReadingCompetition = (props) => {
  const [passCode, setPassCode] = useState();
  const [passArray, setPassArray] = useState([]);
  const [isParent, setParent] = useState(false);
  return (
    <div className="join-reading-room-contianer">
      <div
        className="bg-close-join-competition"
        onClick={() => {
          props.setPopUpJoin(false);
        }}
      >
        {" "}
      </div>
      {!isParent ? (
        <div className="passcode-main-container">
          <div className="header-container">
            <h2>Enter the Passcode</h2>
            <p>Ask your teacher or your parents about it.</p>
          </div>
          <div className="passcode-boxes-container">
            {[0, 1, 2, 3, 4].map((i) =>
              passArray[i] || passArray[i] === 0 ? (
                <UniqueIconById
                  id={passArray.sort((a, b) => b - a)[i]}
                  key={passArray[i]}
                />
              ) : (
                <FaRobot className="icon-empty" />
              )
            )}
          </div>
          <div className="pass-code-contianer">
            <GamePassPassCode
              total={5}
              setPassword={setPassCode}
              setPassArray={setPassArray}
            />
          </div>
          <div className="div-action-buttons">
            <button
              className="im-an-adult"
              onClick={() => {
                setParent(true);
              }}
            >
              I am a Parent
            </button>
            <button
              className={
                passArray.length === 5 ? "btn-join" : "btn-join-disabled"
              }
              onClick={() => {
                passArray.length === 5 && props.handleJoinCompetition(passCode);
              }}
            >
              Join
            </button>
          </div>
        </div>
      ) : (
        <div className="passcode-main-container">
          <h1>How to get a Passcode?</h1>
          <p>
            A <strong>Passcode</strong> is a single-time-use code that allows
            students join competitions. We are currently working on making deals
            with schools. You can contact your school to check if they have an
            access. If you only want to enroll one kid, it is $3 per person, or
            99 baht, if you live in Thailand.
          </p>
          <div className="contact-list">
            <h3>You can buy passes or learn more here</h3>
            <div className="contact-list-grid">
              <a href="https://t.me/sura_saru" target="_blank">
                <FaTelegram /> Khmer
              </a>
              <a href="https://t.me/davranbekr" target="_blank">
                <FaTelegram /> English
              </a>
              <a href="https://line.me/ti/p/Jq_GQiOr3e" target="_blank">
                <FaLine />
                English
              </a>
              <a
                href="https://www.facebook.com/people/BookBattle/61560882686825/"
                target="_blank"
              >
                <FaFacebook /> Facebook
              </a>
            </div>
            <button onClick={() => setParent(false)}>Go Back</button>
          </div>
        </div>
      )}
    </div>
  );
};
