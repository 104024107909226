import { sendEmailVerification } from "firebase/auth";
import { auth } from "../../firebase-functions/config";
import "../../style/auth.css";
import { ToastContainer, toast } from "react-toastify";

export const VerifyEmail = () => {
  return (
    <div className="verify-email-container">
      <ToastContainer />
      <div className="verify-email-box">
        <h1>Please Verify Your Email Address First</h1>

        <p>Check Your Email and Click on the Link </p>
        <p>
          If you can't see the verificatiion email in your inbox please check
          your spam folder{" "}
        </p>
        <p>
          Provided email: <strong>{auth.currentUser.email}</strong>
        </p>

        <p>
          <strong>Wrong email?</strong> <br />
          You can log out and create a new account
        </p>
        <button
          onClick={() => {
            sendEmailVerification(auth.currentUser);
            toast.success("Please check your email");
          }}
        >
          I didn't receive Email
        </button>
      </div>
    </div>
  );
};
