import React from "react";

import WordCloud from "react-d3-cloud";

export const WordCloudPage = (props) => {
  const data = props?.wordList || [];
  const fontSize = (word) => Math.log2(word.value * 2.5) * 4;

  const colorOptions = [
    "#388E3C", // Forest green
    "#2D9CDB", // Sky blue
    "#EB4C42", // Light coral
    "#F4D03F", // Lemon yellow
    "#9B59B6", // Lavender
    "#FF9933", // Sunset orange
    "#66B3FF", // Light blue (alternative)
    "#99CC33", // Light green
    "#C2C2F0", // Light lavender
    "#FFCC99", // Peach
    "#FFEB3B", // Canary yellow
    "#B3399C", // Amethyst
    "#E0E4CC", // Light gray (for subtle contrast)
    "#69D2E7", // Sky blue (alternative)
    "#FCE4EC", // Light pink
  ];

  return (
    <div className="word-cloud-container bg-blue-100 p-5">
      <h2>Here are the most common words from the books you read</h2>
      <WordCloud
        data={data}
        width={window.screen.width}
        font="Impact"
        fontSize={fontSize}
        rotate={() => ~~(Math.random() * 2) * 90}
        // height={window.screen.height}
        fontWeight="bold"
        spiral="rectangular"
        fill={colorOptions[Math.floor(Math.random * colorOptions.length)]} // Use the defined fill function
        random={Math.random}
      />
    </div>
  );
};
