import { Link } from "react-router-dom";
import { NavbarTeams } from "./NavbarTeams";
import { StudentNavbar } from "./StudentNavbar";
import { FaPlus } from "react-icons/fa6";
import "../../style/teams.css";
import { useEffect, useState } from "react";
import { GiCardRandom } from "react-icons/gi";

import {
  RegExpMatcher,
  englishDataset,
  englishRecommendedTransformers,
} from "obscenity";
import { toast } from "react-toastify";
import { auth } from "../../firebase-functions/config";
import { createTeam } from "../../firebase-functions/database";
import { TeamLeaderBoard } from "./TeamLeaderBoard";

export const StudentTeams = (props) => {
  const [toggleCreate, setToggleCreate] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [teamRank, setTeamRank] = useState();
  const [competitionInfo, setDefaultCompetitionInfo] = useState();

  const generateRandomTeamName = () => {
    var adjs = [
        "autumn",
        "hidden",
        "bitter",
        "misty",
        "silent",
        "empty",
        "dry",
        "dark",
        "summer",
        "icy",
        "delicate",
        "quiet",
        "white",
        "cool",
        "spring",
        "winter",
        "patient",
        "twilight",
        "dawn",
        "crimson",
        "wispy",
        "weathered",
        "blue",
        "billowing",
        "broken",
        "cold",
        "damp",
        "falling",
        "frosty",
        "green",
        "long",
        "late",
        "lingering",
        "bold",
        "little",
        "morning",
        "muddy",
        "old",
        "red",
        "rough",
        "still",
        "small",
        "sparkling",
        "throbbing",
        "shy",
        "wandering",
        "withered",
        "wild",
        "black",
        "young",
        "holy",
        "solitary",
        "fragrant",
        "aged",
        "snowy",
        "proud",
        "floral",
        "restless",
        "divine",
        "polished",
        "ancient",
        "purple",
        "lively",
        "nameless",
      ],
      nouns = [
        "waterfall",
        "river",
        "breeze",
        "moon",
        "rain",
        "wind",
        "sea",
        "morning",
        "snow",
        "lake",
        "sunset",
        "pine",
        "shadow",
        "leaf",
        "dawn",
        "glitter",
        "forest",
        "hill",
        "cloud",
        "meadow",
        "sun",
        "glade",
        "bird",
        "brook",
        "butterfly",
        "bush",
        "dew",
        "dust",
        "field",
        "fire",
        "flower",
        "firefly",
        "feather",
        "grass",
        "haze",
        "mountain",
        "night",
        "pond",
        "darkness",
        "snowflake",
        "silence",
        "sound",
        "sky",
        "shape",
        "surf",
        "thunder",
        "violet",
        "water",
        "wildflower",
        "wave",
        "water",
        "resonance",
        "sun",
        "wood",
        "dream",
        "cherry",
        "tree",
        "fog",
        "frost",
        "voice",
        "paper",
        "frog",
        "smoke",
        "star",
      ];

    setTeamName(
      adjs[Math.floor(Math.random() * (adjs.length - 1))] +
        " " +
        nouns[Math.floor(Math.random() * (nouns.length - 1))]
    );
  };

  const handleCreateTeam = () => {
    if (teamName.length < 3) {
      toast.warning("Team Name needs to be minimum 3 letters long.");
    } else {
      const matcher = new RegExpMatcher({
        ...englishDataset.build(),
        ...englishRecommendedTransformers,
      });

      if (matcher.hasMatch(teamName)) {
        toast.warning("Let's keep it family friendly.");
      } else if (props?.user?.team) {
        toast.warning(
          "You already have a team. Please leave your existing team first!"
        );
      } else {
        const newTeamData = {
          teamName: teamName,
          league: props.readerProfile.league,
          competition: props.readerProfile.competition,
          createdAt: new Date(),
        };

        createTeam(newTeamData, auth.currentUser).then((res) => {
          if (res.success) {
            toast.success(res.message);
          } else {
            toast.error(res.message);
          }
        });
      }
    }
  };

  useEffect(() => {}, [props]);
  return (
    <div className="team-container">
      {props.readerProfile?.team && <NavbarTeams userData={props.user} />}
      <div className="div-create-team">
        <p className="btn-create-team" onClick={() => setToggleCreate(true)}>
          <FaPlus />
          <span> Create</span>
        </p>
      </div>
      {toggleCreate && (
        <>
          <div className="create-team-form">
            <h3>Create a new team</h3>
            <div className="row-team-name">
              <input
                type="text"
                maxLength={20}
                lang="en"
                value={teamName}
                onChange={(e) => {
                  setTeamName(e.target.value);
                }}
              />
              <button onClick={generateRandomTeamName}>
                <GiCardRandom />
              </button>
            </div>
            <button onClick={handleCreateTeam} className="btn-create">
              create
            </button>
          </div>
          <div
            className="create-team-form-background"
            onClick={() => setToggleCreate(false)}
          ></div>
        </>
      )}

      <TeamLeaderBoard
        user={props.user}
        rank={teamRank}
        readerProfile={props.readerProfile}
        setTeamRank={setTeamRank}
      />
    </div>
  );
};
