import { useEffect, useState } from "react";
import {
  getTeamLeaderBoard,
  updateDocument,
  joinTeam,
} from "../../firebase-functions/database";
import "../../style/leaderBoard.css";
import { FaRegStarHalfStroke } from "react-icons/fa6";
import { toast } from "react-toastify";

import { IoMdRibbon } from "react-icons/io";
import { SiRoblox } from "react-icons/si";
import { onAuthStateChanged } from "firebase/auth";
import { MdPeopleAlt } from "react-icons/md";
import { LiaCrownSolid } from "react-icons/lia";

import { auth } from "../../firebase-functions/config";

export const TeamLeaderBoard = (props) => {
  const [leaderBoardData, setLeaderBoardData] = useState([]);
  const setRank = props.setTeamRank;
  const [activeTeam, setActiveTeam] = useState();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (props?.readerProfile?.league) {
        getTeamLeaderBoard(props?.readerProfile).then((res) => {
          setLeaderBoardData(res.teamLeaderboards);
        });
      }
    });
  }, [props]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      leaderBoardData.map((ld, index) => {
        if (ld.id === props?.readerProfile?.team) {
          props.setTeamRank(index + 1);
        }
      });
    });
  }, [leaderBoardData, props.readerProfile]);

  const Reward = (props) => {
    const index = props.index;
    return (
      <div>
        {index === 0 ? 1000 : index === 1 ? 500 : index === 2 ? 250 : 0}
      </div>
    );
  };

  const handleJoinTeam = () => {
    const user = props?.user;

    if (user && activeTeam) {
      joinTeam(auth.currentUser, props.readerProfile, activeTeam).then(
        (res) => {
          if (res.success) {
            toast.success(res.message);
          } else {
            toast.error(res.message);
          }
        }
      );
    }
  };

  return (
    <div className="leaderboard-container">
      {leaderBoardData.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Team</th>
              <th>Stars</th>
              <th>Rewards</th>
            </tr>
          </thead>
          <tbody>
            {leaderBoardData.map(
              (ld, index) =>
                ld.teamName !== "-" && (
                  <tr
                    onClick={() => {
                      setActiveTeam(ld);
                    }}
                    key={ld.id}
                    className={
                      ld.id === props.readerProfile.team
                        ? "self-class"
                        : index % 2 === 0
                        ? "table-odd"
                        : "table-even"
                    }
                  >
                    <td>
                      {index + 1}
                      {index === 0 && <IoMdRibbon className="ribbon-gold" />}
                      {index === 1 && <IoMdRibbon className="ribbon-silver" />}
                      {index === 2 && <IoMdRibbon className="ribbon-bronze" />}
                    </td>

                    <td>{ld.teamName}</td>
                    <td className="td-stars">
                      <>
                        <FaRegStarHalfStroke className="star-sign" />
                        {ld.stars}
                      </>
                    </td>
                    <td className="row-reward">
                      <SiRoblox className="icon-roblox" />
                      {<Reward index={index} />}
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </table>
      )}

      {leaderBoardData.length === 0 && <h3>No data available</h3>}

      {activeTeam && (
        <div className="active-team-container">
          <div className="active-team-box">
            <h2>{activeTeam?.teamName}</h2>
            <div className="grid-2">
              <MdPeopleAlt />
              <p>{activeTeam?.numberOfMembers}</p>

              <LiaCrownSolid />
              <p>{activeTeam?.leader?.name}</p>
            </div>

            {props.user.team !== activeTeam?.id && (
              <button
                onClick={() => {
                  handleJoinTeam();
                }}
              >
                join
              </button>
            )}

            {props.user.team === activeTeam?.id && (
              <p className="already-member-text">
                You are already a member of this team
              </p>
            )}
          </div>
          <div
            className="active-team-background"
            onClick={() => {
              setActiveTeam();
            }}
          ></div>
        </div>
      )}
    </div>
  );
};
