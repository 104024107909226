import React, { useState, useEffect } from "react";
import "../../style/achievement.css"; // Assuming you have your CSS in a separate file
import { FaTrophy } from "react-icons/fa";

function Achievement() {
  const [expanded, setExpanded] = useState(false);
  const [widen, setWiden] = useState(false);
  const [showCopy, setShowCopy] = useState(false);

  useEffect(() => {
    showAchievement();
  }, []);

  const showAchievement = () => {
    document.querySelector("#achievement .circle")?.classList.remove("rotate");
    setTimeout(() => {
      setExpanded(true);
      setTimeout(() => {
        setWiden(true);
        setTimeout(() => {
          setShowCopy(true);
        }, 1000);
      }, 1000);
    }, 1000);
    setTimeout(() => {
      hideAchievement();
    }, 4000);
  };

  const hideAchievement = () => {
    setTimeout(() => {
      setShowCopy(false);
      setTimeout(() => {
        setWiden(false);
        document.querySelector("#achievement .circle")?.classList.add("rotate");
        document
          .querySelector("#achievement .circle")
          ?.classList.remove("expand");
        document.querySelector("#achievement")?.classList.remove("show");
        document.querySelector("#achievement")?.classList.remove("rotate");
      }, 1000);
    }, 3000);
  };

  //   const hideAchievement = () => {
  //     setTimeout(() => {
  //       $('#achievement .copy').removeClass('show');
  //       setTimeout(() => {
  //         $('#achievement').removeClass('widen');
  //         $('#achievement .circle').addClass('rotate');
  //         setTimeout(() => {
  //           $('#achievement').removeClass('expand');
  //           $('.refresh').fadeIn(300);
  //         }, 1000);
  //       }, 1000);
  //     }, 3000);
  //   };
  return (
    <div
      id="achievement"
      className={`achievement ${expanded ? "expand" : ""} ${
        widen ? "widen" : ""
      }`}
    >
      <div className="circle"></div>
      <div className={`copy ${showCopy ? "show" : ""}`}>
        <h4>Bullseye!</h4>
        <p>Cup Game Unlcoked!</p>
      </div>
    </div>
  );
}

export default Achievement;
