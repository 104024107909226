import { useEffect, useState } from "react";
import { InTeamLeaderBoard } from "./InTeamLeaderBoard";
import { NavbarTeams } from "./NavbarTeams";
import { StudentNavbar } from "./StudentNavbar";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase-functions/config";
import { getDocument, leaveTeam } from "../../firebase-functions/database";
import { StudentStats } from "./StudentStats";
import { CountDown } from "./CountDown";
import { FaPersonWalkingLuggage } from "react-icons/fa6";
import { toast } from "react-toastify";

export const StudentTeam = (props) => {
  const [teamRank, setTeamRank] = useState();
  const [inTeamRank, setInTeamRank] = useState();
  const [teamData, setTeamData] = useState();
  const [inTeamLeaderBoard, setInTeamLeaderBoard] = useState([]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (props.readerProfile?.team) {
        getDocument("teams", props.readerProfile.team).then((res) => {
          if (res.success) {
            setTeamData(res.data);
          }
        });
      }
    });
  }, [props.readerProfile]);
  return (
    <div>
      <NavbarTeams userData={props.user} />
      {props.user.team && (
        <div className="div-create-team">
          <p
            className="btn-create-team btn-leave-team"
            onClick={() => {
              leaveTeam(
                auth.currentUser,
                props.readerProfile.league,
                props.readerProfile.team
              ).then((res) => {
                if (res.success) {
                  toast.success(res.message);
                } else {
                  toast.error(res.message);
                }
              });
            }}
          >
            <span> Leave</span>
            <FaPersonWalkingLuggage />
          </p>
        </div>
      )}
      <StudentStats
        teamData={teamData}
        studentData={props}
        rank={teamRank}
        type="in-team"
        leaderBoardData={inTeamLeaderBoard}
      />
      <CountDown competition={props.competitionInfo} />
      <InTeamLeaderBoard
        type="in-team"
        user={props.user}
        setTeamRank={setTeamRank}
        inTeamRank={inTeamRank}
        setLeaderBoardData={setInTeamLeaderBoard}
        leaderBoardData={inTeamLeaderBoard}
        readerProfile={props.readerProfile}
      />
    </div>
  );
};
