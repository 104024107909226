export const decodeHash = (message) => {
  // Function to convert a base-36 string to a base-10 number
  function base36ToBase10(base36String) {
    return parseInt(base36String, 36);
  }

  // Function to find 5 unique powers of 2 that sum up to a given number
  function findPowersOf2(sum) {
    let powers = [];
    for (let i = 31; i >= 0; i--) {
      let power = Math.pow(2, i);
      if (sum >= power) {
        powers.push(i);
        sum -= power;
      }
      if (powers.length === 5) {
        break;
      }
    }
    return powers;
  }

  // Given base-36 string, salt, and hash values
  const base36String = message;
  const salt = process.env.REACT_APP_SALT;
  const hash = process.env.REACT_APP_HASH;

  // Step 1: Convert base-36 string to base-10
  const base10Number = base36ToBase10(base36String) - salt;

  // Step 2: Divide by salt
  const quotient = Math.floor(base10Number / hash);

  // Step 3: Find 5 unique powers of 2 that sum to the quotient
  const powersOf2 = findPowersOf2(quotient);

  return powersOf2;
};

export const encodeHash = (passwordArray) => {
  if (passwordArray.length === 0) {
    return "";
  }

  let sum = 0;
  passwordArray.map((i) => {
    sum = sum + 2 ** i;
  });
  let res =
    sum * parseFloat(process.env.REACT_APP_HASH) +
    parseFloat(process.env.REACT_APP_SALT);

  if (passwordArray.length > 0) {
    return res.toString(36);
  }
};

export const createRandomIntegerArray = (size, min, max, isUnique) => {
  let newArray = [];

  if (isUnique && max - min < size) {
    return [];
  }

  while (newArray.length < size) {
    let randomInteger = Math.floor(Math.random() * (max - min)) + min;
    if (!newArray.includes(randomInteger)) {
      newArray.push(randomInteger);
    }
  }
  return newArray;
};
