import { StepThree } from "./StepThree";

export const Extend = (props) => {
  return (
    <div className="container">
      <div className="signup-container">
        <StepThree readerProfile={props.readerProfile} />
      </div>
    </div>
  );
};
