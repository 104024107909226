import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { auth, onAuthStateChanged } from "./firebase-functions/config";

// Components
import SignUp from "./components/auth/SignUp";
import Login from "./components/auth/Login";
import StudentDashboard from "./components/student/StudentDashboard";
import { StudentTeams } from "./components/student/StudentTeams";

import { StudentTeam } from "./components/student/StudentTeam";
import { ReadingRoom } from "./components/student/readingRoom/ReadingRoom";

import {
  getDefaultCompetition,
  getDemoReaderDocument,
  getDocument,
  getReaderDocument,
} from "./firebase-functions/database";
import { toast } from "react-toastify";

import { Payments } from "./components/accountant/Payments";

import { PendingPayments } from "./components/accountant/PendingPayments";
import { NotFound } from "./components/utils/NotFound";
import { StudentNavbar } from "./components/student/StudentNavbar";

import { LandingPage } from "./components/guest/LandingPage";

import { VerifyEmail } from "./components/auth/VerifyEmail";
import { StepLeague } from "./components/auth/SignUpSteps.jsx/StepLeague";
import { DemoExpired } from "./components/utils/DemoExpired";

import { Contact } from "./components/utils/Contact";
import ForgotPassword from "./components/auth/ForgotPassword";
import AboutPage from "./components/guest/AboutPage";
import { Competition } from "./components/admin/Competition";
import { Email } from "./components/admin/Email";
import { Apk } from "./components/utils/Apk";
import { StepThree } from "./components/auth/SignUpSteps.jsx/StepThree";
import { DownloadIOS } from "./components/student/DownloadIOS";
import { Tribute } from "./components/tribute/Tribute";
import { UploadTribute } from "./components/admin/UploadTribute";
import { Account } from "./components/auth/Account";
import { ResetPassword } from "./components/auth/ResetPassword";
import { Extend } from "./components/auth/SignUpSteps.jsx/Extend";
import { GuestReadingRoom } from "./components/student/readingRoom/GuestReadingRoom";
import { AdminBooks } from "./components/admin/AdminBooks";
import { AudioBooks } from "./components/admin/AudoBooks";

const App = () => {
  const [user, setUser] = useState({});
  const [userInfo, setUserInfo] = useState();
  const [studentInfo, setStudentInfo] = useState();

  const [readerProfile, setReaderProfile] = useState();
  const [competitionInfo, setDefaultCompetitionInfo] = useState();
  const [role, setRole] = useState();

  useEffect(() => {
    onAuthStateChanged(auth, (u) => {
      if (u?.uid) {
        getDocument("users", u?.uid).then((res) => {
          if (res.success) {
            setUserInfo(res.data);
            if (res?.data?.role === "accountant") {
            }
          } else {
            toast.error(res.message);
          }
        });
        getDocument("students", auth.currentUser.uid).then((res) => {
          if (res.success) {
            setStudentInfo(res.data);
          } else {
          }
        });

        // getDefaultCompetition().then((resComp) => {
        //   if (resComp.success) {
        //     setDefaultCompetitionInfo(resComp.message);
        //   }
        // });
      }
    });
  }, []);

  useEffect(() => {
    if (!auth?.currentUser) {
      setRole("guest");
    } else if (!auth?.currentUser?.emailVerified) {
      setRole("not verified");
    } else if (userInfo?.role === "new") {
      setRole("new");
    }
    if (userInfo?.role === "student") {
      // if (competitionInfo) {
      //   getReaderDocument(
      //     // "bo6vynBKgOWRP39UwSP1iMAzWS93",
      //     auth.currentUser.uid,
      //     competitionInfo.id
      //   ).then((res) => {
      //     if (res.success) {
      //       if (res.message) {
      //         let tempProfile = { ...res.message };
      //         tempProfile.type = "paid";
      //         setReaderProfile(tempProfile);
      //         setRole("reader");
      //       } else {
      //         getDemoReaderDocument(
      //           auth.currentUser.uid,
      //           competitionInfo.id
      //         ).then((resDemoReader) => {
      //           if (resDemoReader.success) {
      //             const now = new Date();

      //             if (resDemoReader.message) {

      //               setRole("demo reader");

      //               let tempProfile = { ...resDemoReader.message };
      //               tempProfile.type = "demo";

      //               setReaderProfile(tempProfile);
      //               console.log(tempProfile);

      //             } else {
      //               setRole("student");
      //             }
      //           }
      //         });
      //       }
      //     }
      //   });
      // }

      // if (competitionInfo) {
      //   getReaderDocument(auth.currentUser.uid, competitionInfo.id).then(
      //     (res) => {}
      //   );
      // }

      getDemoReaderDocument(auth.currentUser.uid).then((res) => {
        if (res.success) {
          if (res.message) {
            setReaderProfile(res.message);
            setRole("demo reader");
            if (res.message.competition) {
              getDocument("competitions", res.message.competition).then(
                (resCompetition) => {
                  if (resCompetition.success) {
                    setDefaultCompetitionInfo(resCompetition.data);
                  } else {
                    toast.error("couldn't load the competition");
                  }
                }
              );
            }
          } else {
            setRole("student");
          }
        }
      });
    }
    if (userInfo?.role === "accountant") {
      setRole("accountant");
    }
    if (userInfo?.role === "admin") {
      setRole("admin");
    }
  }, [userInfo, auth.currentUser]);

  return (
    <BrowserRouter>
      <StudentNavbar role={role} />

      <Routes>
        {role === "not verified" && (
          <Route>
            <Route path="/" element={<VerifyEmail />} />{" "}
          </Route>
        )}
        {role === "guest" && (
          <Route>
            <Route path="/" element={<LandingPage />} />{" "}
            <Route path="/about" element={<AboutPage />} />{" "}
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Route>
        )}

        {role == "expired reader" && (
          <Route>
            <Route
              path="/"
              element={<DemoExpired readerProfile={readerProfile} />}
            ></Route>
            <Route
              path="/payment"
              element={<SignUp readerProfile={readerProfile} />}
            ></Route>
            <Route
              path="/extend"
              element={<Extend readerProfile={readerProfile} />}
            ></Route>
          </Route>
        )}
        {role === "demo reader" && (
          <Route>
            <Route
              path="/payment"
              element={<SignUp readerProfile={readerProfile} />}
            ></Route>
            <Route path="/reset-password" element={<ResetPassword />}></Route>

            <Route
              path="/"
              element={
                <ReadingRoom
                  user={user}
                  setUser={setUser}
                  readerProfile={readerProfile}
                  studentInfo={studentInfo}
                  competitionInfo={competitionInfo}
                  setDefaultCompetitionInfo={setDefaultCompetitionInfo}
                />
              }
            />
            <Route
              path="/dashboard"
              element={
                <StudentDashboard
                  user={user}
                  setUser={setUser}
                  studentInfo={studentInfo}
                  readerProfile={readerProfile}
                  competitionInfo={competitionInfo}
                  setDefaultCompetitionInfo={setDefaultCompetitionInfo}
                />
              }
            />
            <Route
              path="/reading-room"
              element={
                <ReadingRoom
                  user={user}
                  setUser={setUser}
                  readerProfile={readerProfile}
                  studentInfo={studentInfo}
                  competitionInfo={competitionInfo}
                  setDefaultCompetitionInfo={setDefaultCompetitionInfo}
                />
              }
            />
            <Route
              path="/account"
              element={
                <Account
                  user={user}
                  setUser={setUser}
                  readerProfile={readerProfile}
                  studentInfo={studentInfo}
                  competitionInfo={competitionInfo}
                  // setDefaultCompetitionInfo={setDefaultCompetitionInfo}
                />
              }
            />
            {/* <Route
              path="/teams"
              element={
                <StudentTeams
                  user={user}
                  setUser={setUser}
                  readerProfile={readerProfile}
                  competitionInfo={competitionInfo}
                  setDefaultCompetitionInfo={setDefaultCompetitionInfo}
                />
              }
            /> */}
            {/* <Route
              path="/teams/my-team"
              element={
                <StudentTeam
                  user={user}
                  setUser={setUser}
                  readerProfile={readerProfile}
                  competitionInfo={competitionInfo}
                  setDefaultCompetitionInfo={setDefaultCompetitionInfo}
                />
              }
            /> */}

            <Route
              path="/reading-room"
              element={
                <ReadingRoom
                  user={user}
                  setUser={setUser}
                  readerProfile={readerProfile}
                  studentInfo={studentInfo}
                  competitionInfo={competitionInfo}
                  // setDefaultCompetitionInfo={setDefaultCompetitionInfo}
                />
              }
            />
          </Route>
        )}

        {role === "reader" && (
          <Route>
            <Route
              path="/dashboard"
              element={
                <StudentDashboard
                  user={user}
                  studentInfo={studentInfo}
                  setUser={setUser}
                  readerProfile={readerProfile}
                  competitionInfo={competitionInfo}
                  // setDefaultCompetitionInfo={setDefaultCompetitionInfo}
                />
              }
            />
            <Route
              path="/"
              element={
                <StudentDashboard
                  user={user}
                  setUser={setUser}
                  readerProfile={readerProfile}
                  studentInfo={studentInfo}
                  competitionInfo={competitionInfo}
                  // setDefaultCompetitionInfo={setDefaultCompetitionInfo}
                />
              }
            />
            <Route
              path="/teams"
              element={
                <StudentTeams
                  user={user}
                  setUser={setUser}
                  readerProfile={readerProfile}
                  competitionInfo={competitionInfo}
                  // setDefaultCompetitionInfo={setDefaultCompetitionInfo}
                />
              }
            />
            <Route
              path="/teams/my-team"
              element={
                <StudentTeam
                  user={user}
                  setUser={setUser}
                  readerProfile={readerProfile}
                  competitionInfo={competitionInfo}
                  // setDefaultCompetitionInfo={setDefaultCompetitionInfo}
                />
              }
            />

            <Route
              path="/reading-room"
              element={
                <ReadingRoom
                  user={user}
                  setUser={setUser}
                  readerProfile={readerProfile}
                  studentInfo={studentInfo}
                  competitionInfo={competitionInfo}
                  // setDefaultCompetitionInfo={setDefaultCompetitionInfo}
                />
              }
            />
            <Route
              path="/account"
              element={
                <Account
                  user={user}
                  setUser={setUser}
                  readerProfile={readerProfile}
                  studentInfo={studentInfo}
                  competitionInfo={competitionInfo}
                  // setDefaultCompetitionInfo={setDefaultCompetitionInfo}
                />
              }
            />
            <Route
              path="*"
              element={
                <NotFound
                  user={user}
                  setUser={setUser}
                  readerProfile={readerProfile}
                  competitionInfo={competitionInfo}
                  // setDefaultCompetitionInfo={setDefaultCompetitionInfo}
                />
              }
            />
          </Route>
        )}
        {role === "new" && (
          <Route>
            <Route path="/" element={<SignUp />} />
          </Route>
        )}
        {role === "student" && (
          <Route>
            <Route
              path="/"
              element={
                <StepLeague
                  studentInfo={studentInfo}
                  competitionInfo={competitionInfo}
                />
              }
            />
          </Route>
        )}

        {userInfo?.role === "accountant" && (
          <Route>
            <Route
              path="/"
              element={<PendingPayments user={user} setUser={setUser} />}
            />
            <Route
              path="/pending-payments"
              element={<PendingPayments user={user} setUser={setUser} />}
            />
            <Route
              path="/payments"
              element={<Payments user={user} setUser={setUser} />}
            />
          </Route>
        )}

        {role === "admin" && (
          <Route>
            <Route path="/" element={<PendingPayments userInfo={userInfo} />} />
            <Route
              path="/pending-payments"
              element={<PendingPayments userInfo={userInfo} />}
            />
            <Route
              path="/payments"
              element={<Payments userInfo={userInfo} />}
            />
            <Route
              path="/competition"
              element={
                <Competition
                  userInfo={userInfo}
                  competitionInfo={competitionInfo}
                />
              }
            />
            <Route path="/audio-books" element={<AudioBooks />}></Route>

            <Route path="email" element={<Email />} />
            <Route path="upload" element={<UploadTribute />} />
          </Route>
        )}

        <Route path="/contact" element={<Contact />} />
        <Route path="/download/apk" element={<Apk />} />
        <Route path="/download/ios" element={<DownloadIOS />} />
        <Route path="/tribute/*" element={<Tribute />} />
        <Route path="/reset-password" element={<ResetPassword />}></Route>
        <Route path="/books" element={<AdminBooks />}></Route>

        <Route
          path="/guest-room/:bookId"
          element={<GuestReadingRoom />}
        ></Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
