import React from "react";

import { useEffect, useState } from "react";
import { Page } from "../../utils/Page";
import HTMLFlipBook from "react-pageflip";
import { DotLoader } from "react-spinners";
import { toast } from "react-toastify";

import { IoCloseSharp } from "react-icons/io5";
import { FaRegBookmark } from "react-icons/fa";
import { FaHeadphones } from "react-icons/fa";
import { MdOutlinePause } from "react-icons/md";

import {
  getBookInfo,
  updateDocument,
} from "../../../firebase-functions/database";

import { Quiz } from "./Quiz";
import { auth } from "../../../firebase-functions/config";
import { LandscapeFlipBook } from "./LandscapeFlipBook";

export const Book = (props) => {
  const openBookListen = props.openBookListen;
  const [bookInfo, setBookInfo] = useState();
  const [bookPages, setBookPages] = useState([]);
  const [bookAudioHeader, setBookAudioHeader] = useState();
  const [audioPages, setAudioPages] = useState([]);
  const [wordLength, setWordLength] = useState(0);

  const [isDoneLoading, setIsDoneLoading] = useState(false);
  const [doneReading, setDoneReading] = useState(false);
  const [readingTime, setReadingTime] = useState();
  const [previousPageNumber, setPreviousPageNumber] = useState();
  const [readingDuration, setReadingDuration] = useState(0);
  const [wpm, setWpm] = useState(0);
  const [isTooFast, setTooFast] = useState(false);
  const [showEndBook, setShowEndBook] = useState(false);
  const [bookmark, setBookmark] = useState();
  const [totalPages, setTotalPages] = useState();
  const [currentAudio, setCurrentAudio] = useState();
  const [currentAudio2, setCurrentAudio2] = useState();

  const [secondAudioPlaying, setSecondAudioPlaying] = useState(false);

  const [nextAudio, setNextAudio] = useState();
  const [togglePlay, setTogglePlay] = useState(false);
  const [varHeight] = useState(0.65);
  const [varWidth] = useState(0.85);

  const fetchPages = async (id) => {
    if (id) {
      let pages = [];
      setBookPages([]);

      for (let page = 0; page <= props.activeBook.pages; page++) {
        const url =
          "https://mi.content.readinga-z.com/raz_book_image/" +
          id +
          "/projectable/large/1/book/page-" +
          page +
          ".jpg";

        try {
          const response = await fetch(url);

          if (response.ok) {
            pages.push(response.url);
            setTotalPages(pages.length);
            setBookPages((previousPages) => [...previousPages, response.url]);
          }
        } catch (error) {
          console.log(error);
        }
        setIsDoneLoading(true);
      }
    }
  };

  useEffect(() => {
    if (bookAudioHeader && openBookListen) {
      Array.from(new Set(bookPages)).map(async (url) => {
        const pageString = url
          .split(".jpg")[0]
          .split("/book/")[1]
          .split("-")[1];
        const page = parseInt(pageString);

        if (
          page >= 3 ||
          (page === 0 && !audioPages.find((a) => a.page === page))
        ) {
          let urlAudio;
          if (page >= 3) {
            urlAudio = bookAudioHeader + "_p" + page + "_text.mp3";
          } else {
            urlAudio = bookAudioHeader + "_title_text.mp3";
          }

          try {
            const response = await fetch(urlAudio);

            if (response.ok) {
              const a = new Audio(response.url);

              setAudioPages((previousPages) => [
                ...previousPages,
                {
                  page: page,
                  url: response.url,
                  audio: a,
                },
              ]);
            }
          } catch (error) {}
        }
      });
    }
  }, [bookPages, bookAudioHeader, openBookListen]);

  const calculateReadingDuration = () => {
    let total = 0;

    Object.values(readingTime).map((pg) => {
      let pageTotal = 0;
      pg.map((flip) => {
        if (flip.end) {
          pageTotal = pageTotal + parseInt(flip.end - flip.start);
        }
      });
      total = total + pageTotal;
    });

    setReadingDuration(total);
    setWpm(Math.round((wordLength * 60000) / total));
  };

  useEffect(() => {
    if (!togglePlay) {
      if (currentAudio && !secondAudioPlaying) {
        currentAudio.pause();
        if (nextAudio) {
          clearTimeout(nextAudio);
        }
      }
      if (currentAudio2 && secondAudioPlaying) {
        currentAudio2.pause();
      }
    } else {
      if (secondAudioPlaying && currentAudio2) {
        currentAudio2.play();
      } else if (currentAudio) {
        currentAudio.play();
      }
    }
  }, [togglePlay]);

  const handleFlip = (e) => {
    const pageNumber = e.data;

    const tempTimeCounter = { ...readingTime };
    let newObjectArray;

    setSecondAudioPlaying(false);

    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
    }
    if (currentAudio2) {
      currentAudio2.pause();
      currentAudio2.currentTime = 0;
    }

    if (nextAudio) {
      clearTimeout(nextAudio);
    }

    let audio, audio2;

    if (!tempTimeCounter[pageNumber]) {
      newObjectArray = [
        {
          start: new Date(),
        },
      ];
    } else {
      newObjectArray = tempTimeCounter[pageNumber];
      newObjectArray.push({
        start: new Date(),
      });
    }
    tempTimeCounter[pageNumber] = newObjectArray;
    if (
      openBookListen &&
      audioPages &&
      audioPages.find((a) => a.page === pageNumber)
    ) {
      audio = audioPages.find((a) => a.page === pageNumber).audio;

      setCurrentAudio(audio);
      if (togglePlay) {
        audio.play();
      }

      if (
        window.innerWidth > window.innerHeight &&
        audioPages.find((a) => a.page === pageNumber + 1) &&
        bookInfo?.data?.orientation === "portrait"
      ) {
        audio2 = audioPages.find((a) => a.page === pageNumber + 1).audio;

        const nextOne = setTimeout(() => {
          if (togglePlay) {
            audio2.play();
            setSecondAudioPlaying(true);
            setCurrentAudio2(audio2);
          }
        }, audio.duration * 1000 + 500);
        setNextAudio(nextOne);
      }
    } else {
      if (
        openBookListen &&
        audioPages &&
        audioPages.find((a) => a.page === pageNumber + 1) &&
        togglePlay
      ) {
        if (
          window.innerWidth > window.innerHeight &&
          audioPages.find((a) => a.page === pageNumber + 1)
        ) {
          const audio2 = audioPages.find(
            (a) => a.page === pageNumber + 1
          ).audio;
          setCurrentAudio(audio2);

          audio2.play();
        }
      }
    }
    if (previousPageNumber && tempTimeCounter[previousPageNumber]) {
      const l = tempTimeCounter[previousPageNumber].length;
      tempTimeCounter[previousPageNumber][l - 1].end = new Date();
    }
    setPreviousPageNumber(pageNumber);
    setReadingTime(tempTimeCounter);

    if (pageNumber >= props.activeBook.pages - 2) {
      setShowEndBook(true);
    }

    if (pageNumber >= props.activeBook.pages - 2) {
      setTimeout(() => {
        setDoneReading(true);
        calculateReadingDuration();
      }, 2000);
    }
  };

  const addBookmark = () => {
    const tempArray = props?.studentInfo.bookmarks.filter(
      (bkm) => bkm.id !== props.bookId
    );
    tempArray.push({
      id: props.bookId,
      page: previousPageNumber || 0,
      readingTime: readingTime,
    });

    setBookmark(
      "students",
      auth.currentUser.uid,
      { bookmarks: tempArray },
      auth.currentUser
    );
    updateDocument(
      "students",
      auth.currentUser.uid,
      { bookmarks: tempArray },
      auth.currentUser
    ).then((res) => {
      if (res.success) {
        toast.success("Book saved successfully");
      } else {
        toast.error("Couldn't save the book", res.message);
      }
    });
  };

  useEffect(() => {
    if (bookPages.length === 0) {
      fetchPages(props?.bookId);
    }
  }, []);

  useEffect(() => {
    if (props?.bookId) {
      getBookInfo(parseInt(props?.bookId)).then((res) => {
        if (res.success) {
          setBookInfo(res.message);

          setWordLength(parseInt(res?.message?.data?.type.match(/\d+/)));

          if (res.message.data.audioLinks) {
            setBookAudioHeader(
              res.message?.data?.audioLinks.title.split("_title_")[0]
            );
          } else {
          }
        } else {
          toast.error("couldn't load the book info", res.message);
        }
      });
    }
  }, [props?.bookId]);

  useEffect(() => {
    if (wpm) {
      if (wpm > 600) {
        setTooFast(true);
      } else {
        setTooFast(false);
      }
    }
  }, [wpm]);

  useEffect(() => {
    if (
      props.studentInfo?.bookmarks?.filter((bkm) => bkm.id === props.bookId)
        ?.readingTime
    ) {
      setReadingTime(
        props.studentInfo?.bookmarks?.filter((bkm) => bkm.id === props.bookId)
          ?.readingTime
      );
    }
  }, [props.studentInfo]);

  return (
    <div className="reading-room-flip-book-container">
      {!doneReading && showEndBook && (
        <button
          className="btn-done"
          onClick={() => {
            setTimeout(() => {
              setDoneReading(true);
              calculateReadingDuration();
            }, 2000);
          }}
        >
          Done Reading
        </button>
      )}
      {!doneReading && (
        <button
          className="btn-close"
          onClick={() => {
            setTogglePlay(false);
            if (currentAudio) {
              currentAudio.pause();
            }
            if (currentAudio2) {
              currentAudio2.pause();
            }
            props.setActiveBook();
          }}
        >
          <IoCloseSharp />
        </button>
      )}

      {openBookListen && audioPages && audioPages.length > 0 && (
        <div className="play-button-container">
          <div className="div-play-button">
            <button
              onClick={() => {
                setTogglePlay(!togglePlay);
              }}
            >
              {!togglePlay ? <FaHeadphones /> : <MdOutlinePause />}
            </button>
          </div>
        </div>
      )}

      {bookPages.length >= 2 && !doneReading && (
        <FaRegBookmark
          className="page-bookmark"
          onClick={() => {
            addBookmark();
          }}
        />
      )}

      {bookPages.length >= 2 ? (
        !doneReading ? (
          <>
            {bookInfo?.data?.orientation === "landscape" ? (
              <LandscapeFlipBook
                handleFlip={handleFlip}
                childrenItems={
                  bookPages &&
                  Array.from(new Set(bookPages)).map((src, index) => (
                    <img src={src} />
                  ))
                }
              ></LandscapeFlipBook>
            ) : window.innerWidth > window.innerHeight ? (
              <HTMLFlipBook
                className="flip-book"
                id="flip-book"
                width={window.innerHeight * 0.65}
                height={window.innerHeight * 0.95}
                // width={window.innerWidth}
                // height={window.innerHeight}
                showCover
                autoSize={false}
                mobileScrollSupport={false}
                onFlip={(e) => {
                  handleFlip(e);
                }}
              >
                {bookPages &&
                  Array.from(new Set(bookPages)).map((src, index) => (
                    <Page key={src + "-" + index} number={index} ref={src}>
                      <img src={src} />
                      {bookAudioHeader}
                    </Page>
                  ))}
              </HTMLFlipBook>
            ) : (
              <HTMLFlipBook
                className="flip-book"
                id="flip-book"
                width={
                  window.innerWidth > window.innerHeight
                    ? window.innerHeight * varHeight
                    : window.innerWidth * 0.85
                }
                height={
                  window.innerWidth > window.innerHeight
                    ? window.innerHeight * varWidth
                    : window.innerHeight
                }
                // width={window.innerWidth}
                // height={window.innerHeight}
                // showCover={false}
                // autoSize={false}
                mobileScrollSupport
                onFlip={(e) => {
                  handleFlip(e);
                }}
              >
                {bookPages &&
                  Array.from(new Set(bookPages)).map((src, index) => (
                    <Page key={src + "-" + index} number={index} ref={src}>
                      <img src={src} />
                    </Page>
                  ))}
              </HTMLFlipBook>
            )}
          </>
        ) : !isTooFast ? (
          <Quiz
            bookInfo={bookInfo}
            readingTime={readingDuration}
            readerProfile={props.readerProfile}
            studentInfo={props.studentInfo}
            wpm={wpm}
            competitionInfo={props.competitionInfo}
            totalPages={totalPages}
          />
        ) : (
          <div className="too-fast-contianer">
            <h2 className="too-fast-header">Slow down Champ</h2>
            <p className="too-fast-text">
              An average fast reader reads at about 500-600 words per minute.
              <br />
              <br /> Your speed in this book was {wpm} words per minute.
            </p>
            <h3 className="too-fast-sub-header">
              Let's slow down and read again.
            </h3>
            <div className="too-fast-buttons">
              <button onClick={() => setDoneReading(false)}>Read Again</button>
              <button onClick={() => window.location.reload()}>
                Pick a different book
              </button>
            </div>
          </div>
        )
      ) : (
        <div className="spinner">
          <DotLoader color="#36d7b7" />
        </div>
      )}
    </div>
  );
};
