import React from "react";
import styles from "../../style/tribute-message.css"; // Assuming you still need custom styles

export const Message = (props) => {
  return (
    <div
      className={`grid h-screen place-content-center bg-${props.bgColor}${
        props?.bgAlpha ? "-" + props.bgAlpha : ""
      }`}
    >
      <BubbleText header={props.header} subtext={props.subtext} />
    </div>
  );
};

const BubbleText = (props) => {
  return (
    <>
      <h2 className="text-center text-5xl font-thin text-green-900  hover:text-green-800 ">
        {props.header.split("").map((child, idx) => (
          <span key={idx} className="hoverText">
            {child}
          </span>
        ))}
      </h2>
      <h3 className="text-center text-3xl font-light text-blue-900 hover:font-medium hover:text-blue-800">
        {props.subtext.split("").map((child, idx) => (
          <span key={idx} className="hoverText">
            {child}
          </span>
        ))}
      </h3>
    </>
  );
};
