import { useEffect, useState } from "react";
import { StudentLeaderBoard } from "../competition/StudentLeaderBoard";
import "../../style/auth.css";
import { getAllCompetitions } from "../../firebase-functions/database";
import { toast } from "react-toastify";
import { CreateCompetitionForm } from "./CreateCompetitionForm";
import { GamePasses } from "./GamePasses";
export const Competition = (props) => {
  const [league, setLeague] = useState("league-1");
  const [competitions, setCompetitions] = useState([]);
  const [competition, setCompetition] = useState([]);
  const [toggleCreateCompetition, setCreateCompetition] = useState(false);
  const [tab, setTab] = useState("details");
  const tabItems = ["details", "passes", "leaderboard"];
  useEffect(() => {
    getAllCompetitions().then((res) => {
      if (res.success) {
        setCompetitions(res.message);
      } else {
        toast.error("couldn't get the competitions");
      }
    });
  }, []);

  useEffect(() => {
    setCompetition(props?.competitionInfo?.data);
  }, [props?.competitionInfo]);

  return (
    <div className="competition-container">
      <h2 style={{ textAlign: "center" }}>
        {/* {competition?.name || props?.competitionInfo.data.name} */}
      </h2>

      <div className="competitions-container">
        <div className="competition-buttons">
          <button
            className="btn-league-1"
            onClick={() => {
              setCreateCompetition(true);
              setCompetition();
            }}
          >
            {" "}
            + New Competotion
          </button>
          {competitions.map((cmp, i) => {
            return (
              <button
                key={cmp.id}
                className={`btn-league-${i + 1}${
                  cmp?.id === competition?.id ? "-active" : ""
                }`}
                onClick={() => {
                  setCreateCompetition(false);
                  setCompetition(cmp);
                }}
              >
                {cmp.name}
              </button>
            );
          })}
        </div>
        {toggleCreateCompetition && (
          <CreateCompetitionForm
            setOpen={setCreateCompetition}
            userInfo={props.userInfo}
          />
        )}
        {competition && (
          <div className="container-competition-details">
            <div className="competition-detail-tabs">
              {tabItems.map((item) => (
                <button
                  className={
                    tab === item
                      ? "competition-tab-button competition-tab-button-active"
                      : "competition-tab-button"
                  }
                  key={item}
                  onClick={() => {
                    setTab(item);
                  }}
                >
                  {item}
                </button>
              ))}
            </div>
            {tab === "leaderboard" && (
              <div className="league-buttons">
                {competition?.leagues &&
                  competition?.leagues.map((lg) => {
                    return (
                      <button
                        key={lg}
                        className={`btn-${lg}${lg === league ? "-active" : ""}`}
                        onClick={() => {
                          setLeague(lg);
                        }}
                      >
                        {lg}
                      </button>
                    );
                  })}
              </div>
            )}
            {tab === "leaderboard" && (
              <StudentLeaderBoard
                competitionInfo={competition}
                readerProfile={{
                  league: league,
                  competition:
                    competition?.id ||
                    props?.competitionInfo?.data?.id ||
                    "ivfuZixTcHtZKefFOMgU",
                }}
              />
            )}
            {tab === "details" && (
              <CreateCompetitionForm
                setOpen={setCreateCompetition}
                userInfo={props.userInfo}
                competition={competition}
              />
            )}

            {tab === "passes" && (
              <GamePasses userInfo={props.userInfo} competition={competition} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
