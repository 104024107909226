import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { auth } from "../../firebase-functions/config";
import { onAuthStateChanged } from "firebase/auth";
import { logInWithEmailAndPassword } from "../../firebase-functions/auth";
import { Link } from "react-router-dom";
import { FaRegEyeSlash, FaEye } from "react-icons/fa";
import { FaKeyboard } from "react-icons/fa";
import { FaBookSkull } from "react-icons/fa6";
import { Password } from "./Password";
import { UserBox } from "./UserBox";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [seePasswrod, setSeePassword] = useState(false);
  const [iconPassword, setIconPassword] = useState(true);
  const [iconEmail, setIconEmail] = useState(true);
  useEffect(() => {
    onAuthStateChanged(auth, (u) => {
      if (u) {
        const toastId = "AlreadyLoggedIn";
        if (!toast.isActive(toastId)) {
          toast.info(
            "You are already Logged in. Redirecting to the dashboard",
            {
              toastId: toastId,
            }
          );
        }
        setTimeout(() => {
          window.location.replace("/");
        }, 5000);
      }
    });
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      logInWithEmailAndPassword(email, password).then((res) => {
        if (res.success) {
          toast.success("logged in successfully!");
          setTimeout(() => {
            window.location.replace("/");
          }, 3000);
        } else {
          toast.error("Email or password is wrong!");
        }
      });
    } catch (error) {
      toast.error("Error calling signUp function:", error.message);
      // Handle the error and show a message to the user
    }
  };

  return (
    <div className="container">
      <div className="signup-container">
        <h2>Login</h2>
        {/* Buttons for signing up with Google, Facebook, or Email/Password */}

        <form onSubmit={handleSubmit} id="emailForm" className="signup-form">
          {iconEmail ? (
            <div className="form-group">
              <div className="label">
                <label htmlFor="email">Email:</label>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setIconEmail(false);
                  }}
                >
                  {" "}
                  <FaKeyboard className="password-icon" />
                </button>
              </div>
              <UserBox setEmail={setEmail} email={email} />
            </div>
          ) : (
            <div className="form-group">
              <div className="label">
                <label htmlFor="email">Email:</label>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setIconEmail(true);
                  }}
                >
                  {" "}
                  <FaBookSkull className="password-icon" />
                </button>
              </div>
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          )}
          {iconPassword ? (
            <div className="form-group">
              <div className="label">
                <label htmlFor="password">Password:</label>

                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setIconPassword(false);
                    setPassword();
                  }}
                >
                  {" "}
                  <FaKeyboard className="password-icon" />
                </button>
              </div>
              <Password setPassword={setPassword} />
            </div>
          ) : (
            <div className="form-group">
              <div className="label">
                <label htmlFor="password">Password:</label>

                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setIconPassword(true);
                  }}
                >
                  {" "}
                  <FaBookSkull className="password-icon" />
                </button>
              </div>
              <div className="div-password">
                <input
                  type={seePasswrod ? "text" : "password"}
                  id="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                {seePasswrod ? (
                  <FaEye
                    className="icon-eye"
                    onClick={() => {
                      setSeePassword(false);
                    }}
                  />
                ) : (
                  <FaRegEyeSlash
                    className="icon-eye"
                    onClick={() => {
                      setSeePassword(true);
                    }}
                  />
                )}
              </div>
            </div>
          )}

          <button type="submit" className="button">
            Log in with Email
          </button>
          <br />
          <br />
          <div className="link-redirect" style={{ textAlign: "center" }}>
            Don't have an account?{" "}
            <strong>
              {" "}
              <Link to="/signUp">Create an account here</Link>
            </strong>
          </div>
          <br />
          <br />
          <div className="link-redirect" style={{ textAlign: "center" }}>
            Forgot Your Password?{" "}
            <strong>
              {" "}
              <Link to="/forgot-password">reset here</Link>
            </strong>
          </div>
        </form>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Login;
