import React, { useState } from "react";
import { PiArrowArcLeftFill, PiArrowArcRightFill } from "react-icons/pi";

export const LandscapeFlipBook = (props) => {
  const pages = props.childrenItems;
  const [page, setPage] = useState(0);
  const handleFlip = props.handleFlip;
  return (
    <div className="landscape-flip-book-container">
      <div className="div-lanscape">
        <div className="page-cintainer">{pages[page]}</div>
        <div className="landscape-div-buttons">
          <div
            className="div-buttons-button-div"
            onClick={() => {
              handleFlip({ data: page - 1 });
              setPage(page - 1);
            }}
          >
            <button>
              <PiArrowArcLeftFill />
              <p>Back</p>
            </button>
          </div>
          <div
            className="div-buttons-button-div"
            onClick={() => {
              handleFlip({ data: page + 1 });
              setPage(page + 1);
            }}
          >
            <button>
              <PiArrowArcRightFill /> <p>Next</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
