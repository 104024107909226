import React, { useRef } from "react";
import {
  motion,
  useMotionTemplate,
  useMotionValue,
  useSpring,
} from "framer-motion";
import profileImg from "../../assets/img/logo.png";
import "../../style/tribute-profile.css";
import { StudentStats } from "../student/StudentStats";
import { IoLogoInstagram } from "react-icons/io5";
import { IoLogoTiktok } from "react-icons/io5";
import { SiYoutubeshorts } from "react-icons/si";
import { FaLine } from "react-icons/fa6";
import { BiLogoTelegram } from "react-icons/bi";

export const TributeProfile = (props) => {
  return (
    <div className="flex w-full  bg-gradient-to-br from-indigo-500 to-violet-500 px-4 py-12 text-slate-900 profile-container-main">
      <TiltCard props={props} />
      <div className="div-left">
        <h2>Dear {props.name}</h2>
        <h4>
          With your<strong> {props.minutes} minutes </strong>of reading, you've
          unlocked new worlds, expanded your knowledge, and fueled your
          imagination. That's incredible!
        </h4>
      </div>
    </div>
  );
};

const ROTATION_RANGE = 32.5;
const HALF_ROTATION_RANGE = 32.5 / 2;

const TiltCard = (input) => {
  const props = input.props;
  const ref = useRef(null);

  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const xSpring = useSpring(x);
  const ySpring = useSpring(y);

  const transform = useMotionTemplate`rotateX(${xSpring}deg) rotateY(${ySpring}deg)`;

  const handleMouseMove = (e) => {
    if (!ref.current) return [0, 0];

    const rect = ref.current.getBoundingClientRect();

    const width = rect.width;
    const height = rect.height;

    const mouseX = (e.clientX - rect.left) * ROTATION_RANGE;
    const mouseY = (e.clientY - rect.top) * ROTATION_RANGE;

    const rX = (mouseY / height - HALF_ROTATION_RANGE) * -1;
    const rY = mouseX / width - HALF_ROTATION_RANGE;

    x.set(rX);
    y.set(rY);
  };

  const handleMouseLeave = () => {
    x.set(0);
    y.set(0);
  };

  return (
    <motion.div
      ref={ref}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      style={{
        transformStyle: "preserve-3d",
        transform,
      }}
      className="relative h-96 w-72 rounded-xl bg-gradient-to-br from-indigo-300 to-violet-300 profile-container"
    >
      <div
        style={{
          transform: "translateZ(75px)",
          transformStyle: "preserve-3d",
        }}
        className="absolute right flex  flex-col rounded-xl bg-white shadow-lg profile-card-container p-0 p-5 "
      >
        <div className="div-img">
          <img src={profileImg} alt="profile image" />
        </div>

        <div className="div-name">
          <h2>{props.name}</h2>
        </div>
        <StudentStats
          readerProfile={{
            stars: props.readerProfile.stars,
            read: props.readerProfile.read,
            perfectScore: props.readerProfile.perfectScore,
            rank: props.readerProfile.rank,
            badge: props.readerProfile.badge,
            roblox: props.readerProfile.robux,
            league: "league-" + props.readerProfile.league,
            hideIncentive: true,
          }}
          rank={props.readerProfile.rank}
        />
        <div className="div-buttons">
          <button>
            <IoLogoInstagram />
          </button>
          <button>
            <IoLogoTiktok />
          </button>
          <button>
            <SiYoutubeshorts />
          </button>
          <button>
            <BiLogoTelegram />
          </button>
          <button>
            <FaLine />
          </button>
          <button className="btn-share-me">Share me</button>
        </div>
        <h4 className="text-center">book-battle.com</h4>
      </div>
    </motion.div>
  );
};
