import * as React from "react";
import { useMotionValue, Reorder } from "framer-motion";
import { useRaisedShadow } from "./use-raised-shadow";

export const Item = ({ item }) => {
  const y = useMotionValue(0);
  const boxShadow = useRaisedShadow(y);

  return (
    <Reorder.Item
      value={item}
      id={item}
      style={{ boxShadow, y }}
      className="list"
    >
      <img src={item} alt="img cup" className="img-cup" draggable={false} />
    </Reorder.Item>
  );
};
