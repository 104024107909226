import { useEffect, useState } from "react";

import {
  createDocumentWithRandomUid,
  getRandomPagesForPlacement,
} from "../../../firebase-functions/database";
import { auth } from "../../../firebase-functions/config";
import { toast } from "react-toastify";
import { CiHeart } from "react-icons/ci";

export const StepLeague = (props) => {
  const [league, setLeague] = useState("");
  const studentInfo = props.studentInfo;
  const [collection, setCollection] = useState();
  const [urls, setUrls] = useState([]);
  const [votes, setVotes] = useState({});
  const [options, setOptions] = useState([]);
  const [optionIndex, setOptionIndex] = useState(2);
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(0);
  const league1 = ["A", "B", "C", "D", "E", "F"];
  const league2 = ["G", "H", "I", "J", "K", "L", "M"];
  const league3 = ["N", "O", "P", "Q", "R", "S", "T"];
  const league4 = ["U", "V", "W", "X", "Y", "Z", "Z1", "Z2"];
  const [voteLeagues, setVoteLeagues] = useState({});
  const [viewTable, setViewTable] = useState(false);
  const handleJoinLeague = async (e) => {
    e.preventDefault();

    if (!league) {
      toast.error("please select a league first");
      return;
    }

    createDocumentWithRandomUid("demoReaders", {
      studentId: auth.currentUser.uid,
      name: studentInfo.name,
      read: studentInfo.read || 0,
      stars: studentInfo.stars || 0,
      quiz: studentInfo.quiz || 0,
      league: league,

      dateCreated: new Date(),
    }).then((res) => {
      if (res.success) {
        toast.success("You have joined " + league.split("-").join(" "));
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    });
  };

  const fetchPages = () => {
    getRandomPagesForPlacement(20).then((res) => {
      if (res.success) {
        setCollection(res.message);

        const tempCollection = res.message;
        setUrls([]);

        tempCollection.map((piece) => {
          const bookId = piece.id;
          const page = Math.floor(Math.random() * 3) + 5;
          const url =
            "https://mi.content.readinga-z.com/raz_book_image/" +
            bookId +
            "/projectable/large/1/book/page-" +
            page +
            ".jpg";
          setUrls((oldUrls) => [...oldUrls, { id: bookId, url: url }]);
        });
      }
    });
  };

  const handlePick = (id, ch) => {
    let newVotes = votes || {};
    if (newVotes[id]) {
      newVotes[id] = newVotes[id] + 1;
    } else {
      newVotes[id] = 1;
    }
    setVotes(newVotes);
    if (progress + 1 < 19) {
      setProgress(progress + 1);
    } else {
      setStep(step + 1);
      setLeague(
        "league-" +
          Object.keys(voteLeagues).find(
            (key) =>
              voteLeagues[key] === Math.max(...Object.values(voteLeagues))
          )
      );
    }

    let tempOptions = [...options];
    let ind = optionIndex;

    tempOptions[ch] = urls[ind];

    setOptions(tempOptions);
    setOptionIndex(ind + 1);

    let leagues = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
    };

    Object.keys(votes).map((voteId) => {
      const book = collection.find((bk) => voteId == bk.id);

      if (league1.includes(book?.level)) {
        leagues[1] = leagues[1] + votes[voteId];
      } else if (league2.includes(book?.level)) {
        leagues[2] = leagues[2] + votes[voteId];
      } else if (league3.includes(book?.level)) {
        leagues[3] = leagues[3] + votes[voteId];
      } else if (league4.includes(book?.level)) {
        leagues[4] = leagues[4] + votes[voteId];
      }
    });

    setVoteLeagues(leagues);
  };

  useEffect(() => {
    fetchPages();
  }, []);

  useEffect(() => {
    setOptions([urls[0], urls[1]]);
  }, [urls]);

  return (
    <div className="div-select-league">
      <form
        onSubmit={handleJoinLeague}
        id="paymentForm"
        className="form-select-league"
      >
        {step === 1 && (
          <div className="div-step-league">
            <h1>Let's Get You into a Right League</h1>
            <button
              onClick={() => {
                setStep(step + 1);
              }}
            >
              Get Started
            </button>
          </div>
        )}
        {step === 2 && (
          <div className="div-step-league">
            <h1>Pick the Pages That Look Better for You</h1>
            <button
              onClick={() => {
                setStep(step + 1);
              }}
            >
              Let's Go
            </button>
          </div>
        )}

        {step == 3 && (
          <div className="">
            <div
              className="progress-bar-league"
              style={{
                height: "1em",
                width: "80vw",
                background: "#ddd",
                margin: "1em",
              }}
            >
              <div
                className="bar"
                style={{
                  backgroundColor: "#3498db",
                  width: (progress * 100) / 19 + "%",
                  height: "100%",
                }}
              >
                &nbsp;
              </div>
            </div>
            <div className="pages">
              <div className="option option-1">
                <img
                  src={options[0]?.url}
                  alt="pick image 1"
                  onClick={() => {
                    handlePick(options[0].id, 1);
                  }}
                />
              </div>
              <div className="option option-2">
                <img
                  src={options[1]?.url}
                  alt="pick image 1"
                  onClick={() => {
                    handlePick(options[1].id, 0);
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {step === 4 && (
          <div
            className="div-step-league div-step-4-league"
            style={{ padding: "1em" }}
          >
            <h1>All Done</h1>
            <h1>
              Based on your choices, we recommend you to go with{" "}
              <strong>
                {" "}
                league{" "}
                {Object.keys(voteLeagues).find(
                  (key) =>
                    voteLeagues[key] === Math.max(...Object.values(voteLeagues))
                )}
              </strong>
            </h1>
            <h2 style={{ fontWeight: "100" }}>
              Remember, higher leagues can give you more Robux, but the books
              will be longer
            </h2>
            {viewTable && (
              <div className="table-league-results">
                <table className="table-votes" style={{ marginBottom: "2em" }}>
                  <thead>
                    <tr>
                      <th scope="col">League</th>
                      <th scope="col">Votes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(voteLeagues).map((league) => (
                      <tr key={league}>
                        <td>League {league}</td>
                        <td>
                          {[...Array(voteLeagues[league])].map((e, i) => (
                            <CiHeart key={i} />
                          ))}
                          <span>{voteLeagues[league]}</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            <div className="div-league-button-options">
              {!viewTable ? (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setViewTable(true);
                  }}
                >
                  View Details
                </button>
              ) : (
                <button
                  style={{ margin: "auto 2em" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setViewTable(false);
                  }}
                >
                  Show Less
                </button>
              )}

              <button
                style={{ margin: "auto 2em" }}
                onClick={(e) => {
                  e.preventDefault();
                  window.location.reload();
                }}
              >
                Choose Again
              </button>
            </div>
            <div className="final-choice">
              Go with{" "}
              <select
                value={league}
                onChange={(e) => {
                  setLeague(e.target.value);
                }}
              >
                <option value={"league-1"}>League 1</option>
                <option value={"league-2"}>League 2</option>
                <option value={"league-3"}>League 3</option>
                <option value={"league-4"}>League 4</option>
              </select>
              <button className="final-choice-btn-submit">Join</button>
            </div>
            <h2>Sample pages from {league}</h2>
            <div className="sample-pages">
              {league === "league-1" &&
                urls
                  .filter((bk) => {
                    return league1.includes(
                      Object.values(collection).find(
                        (book) => book.id === bk.id
                      ).level
                    );
                  })
                  .map((a) => <img key={a.url} src={a.url} alt="thumbnail" />)}
              {league === "league-2" &&
                urls
                  .filter((bk) => {
                    return league2.includes(
                      Object.values(collection).find(
                        (book) => book.id === bk.id
                      ).level
                    );
                  })
                  .map((a) => <img key={a.url} src={a.url} alt="thumbnail" />)}

              {league === "league-3" &&
                urls
                  .filter((bk) => {
                    return league3.includes(
                      Object.values(collection).find(
                        (book) => book.id === bk.id
                      ).level
                    );
                  })
                  .map((a) => <img key={a.url} src={a.url} alt="thumbnail" />)}
              {league === "league-4" &&
                urls
                  .filter((bk) => {
                    return league4.includes(
                      Object.values(collection).find(
                        (book) => book.id === bk.id
                      ).level
                    );
                  })
                  .map((a) => <img key={a.url} src={a.url} alt="thumbnail" />)}
            </div>
          </div>
        )}
      </form>
      <div className="div-thumbnails">
        {urls.map((url, i) => {
          return <img key={i} src={url.url} alt="thumbnail" />;
        })}
      </div>
    </div>
  );
};
