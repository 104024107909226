// firebase/database.js

import { firestore } from "./config"; // Import Firebase firestore and other configurations
import {
  doc,
  setDoc,
  updateDoc,
  getDoc,
  deleteDoc,
  limit,
} from "firebase/firestore";
import { getDocs, query, where, collection, orderBy } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import library from "../assets/json/library.json";
import { confirmPasswordReset } from "firebase/auth";

// Function to create a user document in Firestore

// Function to update user document in Firestore
export const updateUserDocument = async (uid, updatedData) => {
  try {
    await firestore.collection("students").doc(uid).update(updatedData);
    return { success: true, message: "User document updated successfully!" };
  } catch (error) {
    console.error("Error updating user document:", error);
    return { success: false, message: "Error updating user document." };
  }
};

export const getDocument = (col, docId) => {
  return new Promise((resolve, reject) => {
    getDoc(doc(firestore, col, docId))
      .then((snapshot) => {
        if (snapshot.exists()) {
          resolve({
            success: true,
            data: snapshot.data(),
          });
        } else {
          resolve({
            success: false,
            message: "Document does not exist",
          });
        }
      })
      .catch((error) => {
        reject({
          success: false,
          message: error,
        });
      });
  });
};

// Function to create a team document in Firestore
export const createDocument = (col, name, data) => {
  return new Promise((resolve, reject) => {
    setDoc(doc(firestore, col, name), data)
      .then((res) => {
        resolve({
          success: true,
          message: "document created successfully",
        });
      })
      .catch((error) => {
        reject({
          success: false,
          message: error,
        });
      });
  });
};

export const createDocumentWithRandomUid = (col, data) => {
  return new Promise((resolve, reject) => {
    const randomUid = uuidv4();

    setDoc(doc(firestore, col, randomUid), data)
      .then((res) => {
        resolve({
          success: true,
          message: "document created successfully",
          documentId: randomUid,
        });
      })
      .catch((error) => {
        reject({
          success: false,
          message: error,
        });
      });
  });
};

export const updateDocument = (col, docId, data, user) => {
  return new Promise((resolve, reject) => {
    // Assuming user.uid is the user's unique identifier, you can customize this based on your authentication setup

    if (!user || !user.uid) {
      reject({
        success: false,
        message: "User information missing or invalid",
      });
      return;
    }

    // Pass user information to Firestore security rules for validation
    const securityContext = { uid: user.uid };

    updateDoc(doc(firestore, col, docId), data, { context: securityContext })
      .then((res) => {
        resolve({
          success: true,
          message: res,
        });
      })
      .catch((error) => {
        reject({
          success: false,
          message: error,
        });
      });
  });
};

export const deleteDocument = (col, docId, user) => {
  return new Promise((resolve, reject) => {
    // Assuming user.uid is the user's unique identifier, you can customize this based on your authentication setup

    if (!user || !user.uid) {
      reject({
        success: false,
        message: "User information missing or invalid",
      });
      return;
    }

    // Pass user information to Firestore security rules for validation
    const securityContext = { uid: user.uid };

    deleteDoc(doc(firestore, col, docId), { context: securityContext })
      .then(() => {
        resolve({
          success: true,
          message: "Document deleted successfully",
        });
      })
      .catch((error) => {
        reject({
          success: false,
          message: error,
        });
      });
  });
};
// Function to get team document from Firestore
export const getTeamDocument = async (teamId) => {
  try {
    const teamDoc = await firestore.collection("teams").doc(teamId).get();
    return teamDoc.data();
  } catch (error) {
    console.error("Error getting team document:", error);
    return null;
  }
};

// Function to update team document in Firestore
export const updateTeamDocument = async (teamId, updatedData) => {
  try {
    await firestore.collection("teams").doc(teamId).update(updatedData);
    return { success: true, message: "Team document updated successfully!" };
  } catch (error) {
    console.error("Error updating team document:", error);
    return { success: false, message: "Error updating team document." };
  }
};

// Function to delete team document in Firestore
export const deleteTeam = (docId, user) => {
  return new Promise((resolve, reject) => {
    // Assuming user.uid is the user's unique identifier, you can customize this based on your authentication setup

    if (!user || !user.uid) {
      reject({
        success: false,
        message: "User information missing or invalid",
      });
      return;
    }

    deleteDocument("teams", docId, user)
      .then((res) => {
        resolve({
          success: true,
          message: "Team Deleted successfully",
        });
      })
      .catch((error) => {
        reject({
          success: false,
          message: "Error deleting team",
          error,
        });
      });
  });
};

export const submitPayment = async (uid, receiptData) => {
  try {
    // Assuming a 'payments' collection in Firestore
    await firestore.collection("payments").add({
      uid,
      ...receiptData,
      timestamp: firestore.FieldValue.serverTimestamp(),
    });

    // Update user document to indicate payment submission
    await firestore.collection("students").doc(uid).update({
      paymentSubmitted: true,
    });

    return { success: true, message: "Payment submitted successfully!" };
  } catch (error) {
    console.error("Error submitting payment:", error);
    return { success: false, message: "Error submitting payment." };
  }
};

// Function to approve payment and update user document

export const getLeaderBoard = (col, league, competition) => {
  return new Promise((resolve, reject) => {
    const q = query(
      collection(firestore, col),
      where("league", "==", league),
      where("competition", "==", competition),
      orderBy("stars", "desc")
    );

    getDocs(q)
      .then((snapshot) => {
        const students = [];

        snapshot.forEach((doc) => {
          const data = doc.data();

          students.push({
            id: doc.id,
            studentId: data.studentId,
            name: data.name,
            stars: data.stars || 0,
            league: data.league,
            read: data.read || 0,
            quiz: data.quiz || 0,
            teamName: data.teamName || "-",
            team: data.team,
          });
        });

        resolve({
          success: true,
          students: students,
        });
      })
      .catch((error) => {
        reject({
          success: false,
          message: error,
        });
      });
  });
};

export const createTeam = (data, user) => {
  return new Promise((resolve, reject) => {
    getReaderDocument(user.uid, data.competition).then((resReaderProfile) => {
      if (resReaderProfile.success) {
        data.teamLeader = resReaderProfile.message.id;
        data.createdBy = resReaderProfile.message.id;

        createDocumentWithRandomUid("teams", data).then((res) => {
          if (res.success) {
            updateDocument(
              "readers",
              data.createdBy,
              {
                teamName: data.teamName,
                team: res.documentId,
              },
              user
            ).then((res2) => {
              if (res2.success) {
                resolve({
                  success: true,
                  message: "team created successfully",
                });
              } else {
                reject({
                  success: false,
                  message: res2.message,
                });
              }
            });
          } else {
            reject({
              success: false,
              message: res.message,
            });
          }
        });
      }
    });
  });
};

export const leaveTeam = (readerId, league, teamId, user) => {
  return new Promise((resolve, reject) => {
    updateDocument(
      "readers",
      readerId,
      {
        teamName: null,
        team: null,
      },
      user
    )
      .then((res) => {
        getInTeamLeaderBoard("readers", league, teamId).then((teamInfo) => {
          if (teamInfo.students.length === 0) {
            deleteTeam(teamId, user);
          } else if (teamInfo.leader === readerId) {
            updateDocument(
              "teams",
              teamId,
              {
                leader: teamInfo.students[0].id,
              },
              user
            );
          }
        });
        resolve({
          success: true,
          message: "successfully left the team",
        });
      })
      .catch((error) => {
        reject({
          success: false,
          message: error,
        });
      });
  });
};

export const joinTeam = (user, userData, newTeam) => {
  return new Promise((resolve, reject) => {
    if (userData.team) {
      leaveTeam(userData.id, userData.league, userData.team, user).catch(
        (error) => {
          reject({
            success: false,
            message: error,
          });
        }
      );
    }

    updateDocument(
      "readers",
      userData.id,
      {
        team: newTeam.id,
        teamName: newTeam.teamName,
      },
      user
    )
      .then((res) => {
        if (res.success) {
          resolve({
            success: true,
            message:
              "Congratulations! Now you are a member of " + newTeam.teamName,
          });
        }
      })
      .catch((error) => {
        reject({
          success: false,
          message: error,
        });
      });
  });
};
export const getEveryOtherStudent = (user) => {
  return new Promise((resolve, reject) => {
    const queryStudents = query(collection(firestore, "students"));
    let students = [];

    getDocs(queryStudents)
      .then((snapshot) => {
        snapshot.forEach((student) => {
          if (student.id !== user.uid) {
            students.push({ id: student.id, name: student.data().name });
          }
        });
        resolve({
          success: true,
          message: students,
        });
      })
      .catch((error) => {
        reject({ success: false, message: "Couldn't get the students", error });
      });
  });
};

export const getTeamLeaderBoard = (user) => {
  return new Promise((resolve, reject) => {
    const queryStudents = query(
      collection(firestore, "readers"),
      where("league", "==", user.league),
      orderBy("stars", "desc")
    );

    const queryTeams = query(collection(firestore, "teams"));

    let teamsArray = [];

    getDocs(queryTeams)
      .then((teams) => {
        getLeaderBoard("readers", user.league)
          .then((res) => {
            const studentsArray = res.students;

            teams.forEach((team) => {
              let newTeamData = team.data();
              newTeamData["id"] = team.id;
              newTeamData["members"] = studentsArray.filter(
                (st) => st.team === team.id
              );
              newTeamData["numberOfMembers"] = newTeamData["members"].length;
              let stars = 0;
              newTeamData["members"].map((member) => {
                stars = stars + parseInt(member.stars);
              });
              newTeamData["stars"] = stars;

              newTeamData["leader"] = studentsArray.find(
                (st) => st.id === newTeamData.teamLeader
              );

              teamsArray.push(newTeamData);
            });

            teamsArray.sort((a, b) => b.stars - a.stars);

            resolve({
              success: true,
              teamLeaderboards: teamsArray,
            });
          })
          .catch((error) => {
            reject({
              success: false,
              message: error,
            });
          });
      })
      .catch((error) => {
        reject({
          success: false,
          message: error,
        });
      });

    //
  });
};

export const getInTeamLeaderBoard = (col, league, team) => {
  return new Promise((resolve, reject) => {
    const q = query(
      collection(firestore, col),
      where("league", "==", league),
      where("team", "==", team),
      orderBy("stars", "desc")
    );

    getDocs(q)
      .then((snapshot) => {
        const students = [];

        snapshot.forEach((doc) => {
          const data = doc.data();
          const stars = data.stars || 0;
          students.push({
            id: doc.id,
            name: data.name,
            stars: data.stars || 0,
            league: data.league,
            read: data.read || 0,
            listen: data.listen || 0,
            quiz: data.quiz || 0,
            email: data.email,
            teamName: data.teamName || "-",
          });
        });

        resolve({
          success: true,
          students: students,
        });
      })
      .catch((error) => {
        reject({
          success: false,
          message: error,
        });
      });
  });
};

export const getDefaultCompetition = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const q = query(
        collection(firestore, "competitions"),
        where("status", "==", "new"),
        orderBy("start_date"),
        limit(1) // Set the limit to 1 to get only the first document
      );

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        const competitionData = doc.data();
        resolve({
          success: true,
          message: {
            id: doc.id,
            data: competitionData,
          },
        });
      });
    } catch (error) {
      reject({
        success: false,
        message: "error retrieving default competition" + error,
      });
    }
  });
};

export const getAllCompetitionPayments = (competitionId, currentUser) => {
  return new Promise(async (resolve, reject) => {
    try {
      const q = query(
        collection(firestore, "payments"),
        where("competition", "==", competitionId),
        where("studentId", "==", currentUser.uid),
        orderBy("dateCreated", "desc")
      );
      const querySnapshot = await getDocs(q);
      let paymentsArray = [];
      querySnapshot.forEach((doc) => {
        paymentsArray.push(doc.data());
      });
      resolve({
        success: true,
        message: paymentsArray,
      });
    } catch (error) {
      reject({
        success: false,
        message:
          "Something Went Wrong with getting all competitions of the student" +
          error,
      });
    }
  });
};

export const getPaymentStatusForCompetition = (competitionId, currentUser) => {
  return new Promise((resolve, reject) => {
    try {
      getAllCompetitionPayments(competitionId, currentUser).then((res) => {
        if (res.success) {
          const paymentArray = res.message;
          let message = 0;
          if (
            paymentArray.filter((payment) => {
              return payment.status === "confirmed";
            }).length > 0
          ) {
            message = 6;
          } else if (
            paymentArray.filter((payment) => {
              return payment.status === "new";
            }).length > 0
          ) {
            message = 4;
          } else if (
            paymentArray.filter((payment) => {
              return payment.status === "denied";
            }).length > 0
          ) {
            message = 5;
          } else {
            message = 3;
          }
          resolve({
            success: true,
            message: message,

            data:
              message === 5
                ? paymentArray.filter((payment) => {
                    return payment.status === "denied";
                  })[0]
                : null,
          });
        } else {
          reject({
            success: false,
            message:
              "Something Went Wrong While Getting Payment Status" + res.message,
          });
        }
      });
    } catch (error) {
      reject({
        success: false,
        message: "Something Went Wrong While Getting Payment Status" + error,
      });
    }
  });
};

/// Accountant

export const getAllPendingPaymentsByCountry = (country) => {
  return new Promise(async (resolve, reject) => {
    try {
      let paymentsArray = [];
      const q = query(
        collection(firestore, "payments"),
        where("status", "==", "new"),
        where("country", "==", country),
        orderBy("dateCreated", "desc")
      );

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((snapshot) => {
        let newObj = snapshot.data();
        newObj.id = snapshot.id;

        paymentsArray.push(newObj);
      });

      resolve({
        success: true,
        message: paymentsArray,
      });
    } catch (error) {
      reject({
        success: false,
        message: "couldn't get pending payments" + error,
      });
    }
  });
};

export const getAllPendingPayments = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let paymentsArray = [];
      const q = query(
        collection(firestore, "payments"),
        where("status", "==", "new"),
        orderBy("dateCreated", "desc")
      );

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((snapshot) => {
        let newObj = snapshot.data();
        newObj.id = snapshot.id;
        paymentsArray.push(newObj);
      });
      resolve({
        success: true,
        message: paymentsArray,
      });
    } catch (error) {
      reject({
        success: false,
        message: "couldn't get pending payments" + error,
      });
    }
  });
};

export const getAllPayments = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let paymentsArray = [];
      const q = query(
        collection(firestore, "payments"),
        orderBy("dateCreated", "desc")
      );

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((snapshot) => {
        paymentsArray.push(snapshot.data());
      });
      resolve({
        success: true,
        message: paymentsArray,
      });
    } catch (error) {
      reject({
        success: false,
        message: "couldn't get pending payments" + error,
      });
    }
  });
};
export const getAllPaymentsByCountry = async (country) => {
  try {
    const q = query(
      collection(firestore, "payments"),
      where("country", "==", country),
      orderBy("dateCreated", "desc")
    );

    const querySnapshot = await getDocs(q);

    // Use Promise.all to wait for all asynchronous operations to complete
    const paymentsArray = await Promise.all(
      querySnapshot.docs.map(async (snapshot) => {
        const newObj = snapshot.data();
        newObj.id = snapshot.id;

        try {
          const res = await getDocument("students", newObj.studentId);

          if (res.success) {
            // Object.defineProperty(newObj, "student", res.data);
            newObj["student"] = res.data;

            return newObj; // Return the modified object
          } else {
            console.error(res.message);
            return null; // or handle the error accordingly
          }
        } catch (error) {
          console.error("Error fetching student:", error);
          return null; // or handle the error accordingly
        }
      })
    );

    return {
      success: true,
      message: paymentsArray.filter(Boolean), // Remove null values (errors)
    };
  } catch (error) {
    return {
      success: false,
      message: "Couldn't get payments: " + error,
    };
  }
};

export const declinePayment = (data, user) => {
  return new Promise((resolve, reject) => {
    try {
      const id = data.paymentId;
      delete data.paymentId;
      data.status = "denied";
      updateDocument("payments", id, data, user).then((res) => {
        if (res.success) {
          resolve({
            success: true,
            message: "Payment declined successfully",
          });
        } else {
          reject({
            success: false,
            message: "something went wrong" + res.message,
          });
        }
      });
    } catch (error) {
      reject({
        success: false,
        message: "couldn't decline payment" + error,
      });
    }
  });
};

export const approvePayment = (paymentInfo, user) => {
  return new Promise((resolve, reject) => {
    try {
      updateDocument(
        "payments",
        paymentInfo.id,
        {
          status: "confirmed",
          approvedBy: paymentInfo.approvedBy,
          dateApproved: paymentInfo.dateApproved,
          fee: paymentInfo.fee,
          note: paymentInfo?.note || "",
        },
        user
      ).then((updatePaymentRes) => {
        if (!updatePaymentRes.success) {
          reject({
            success: false,
            message: "couldn't approve payment" + updatePaymentRes.message,
          });
        }
      });

      getDemoReaderDocument(
        paymentInfo.demoProfile.studentId,
        paymentInfo.demoProfile.competition
      ).then((resDemoReader) => {
        if (resDemoReader.success) {
          createDocumentWithRandomUid("readers", {
            studentId: paymentInfo.demoProfile.studentId,
            name: paymentInfo.demoProfile.name,
            read: resDemoReader.message.read,
            stars: resDemoReader.message.stars,
            quiz: resDemoReader.message.quiz,
            league: paymentInfo.demoProfile.league,
            competition: paymentInfo.demoProfile.competition,
            dateCreated: new Date(),
            friend:
              paymentInfo.lead === "friend"
                ? paymentInfo?.leadStudent || null
                : null,
          }).then((createReaderRes) => {
            if (createReaderRes.success) {
              if (paymentInfo.lead === "friend" && paymentInfo?.leadStudent) {
                getDocument("students", paymentInfo.leadStudent).then(
                  (leadStudentRes) => {
                    if (leadStudentRes.success) {
                      const robux = leadStudentRes?.data?.robux || 0;

                      getDocument("competitions", paymentInfo.competition).then(
                        (competitionRes) => {
                          if (competitionRes.success) {
                            const reward =
                              competitionRes?.data?.inviteReward || 50;
                            updateDocument(
                              "students",
                              paymentInfo.leadStudent,
                              {
                                robux: robux + reward,
                              },
                              user
                            ).then((resUpdateRobuxRes) => {
                              if (!resUpdateRobuxRes.success) {
                                reject({
                                  success: false,
                                  message:
                                    "couldn't send 100 robux" +
                                    leadStudentRes.message,
                                });
                              }
                            });
                          }
                        }
                      );
                    } else {
                      reject({
                        success: false,
                        message:
                          "couldn't get Student Information" +
                          leadStudentRes.message,
                      });
                    }
                  }
                );
              }
            } else {
              reject({
                success: false,
                message:
                  "couldn't get Student Information" + createReaderRes.message,
              });
            }
          });
        }
      });

      deleteDocument("demoReaders", paymentInfo?.demoProfile?.id, user);

      resolve({
        success: true,
        message: "payment approved successfully!",
      });
    } catch (error) {
      reject({
        success: false,
        message: "couldn't decline payment" + error,
      });
    }
  });
};

export const getReaderDocument = (studentId, competitionId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const q = query(
        collection(firestore, "readers"),
        where("competition", "==", competitionId),
        where("studentId", "==", studentId),
        limit(1)
      );
      let readerData;

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((snapshot) => {
        readerData = snapshot.data();
        readerData.id = snapshot.id;
      });

      resolve({
        success: true,
        message: readerData,
      });
    } catch (error) {
      reject({
        success: false,
        message: "error getting reader data" + error,
      });
    }
  });
};

export const getDemoReaderDocument = (studentId) => {
  // removed competiton Id
  return new Promise(async (resolve, reject) => {
    try {
      const q = query(
        collection(firestore, "demoReaders"),

        where("studentId", "==", studentId),
        limit(1)
      );
      let readerData;

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((snapshot) => {
        readerData = snapshot.data();
        readerData.id = snapshot.id;
      });

      resolve({
        success: true,
        message: readerData,
      });
    } catch (error) {
      reject({
        success: false,
        message: "error getting demo reader data" + error,
      });
    }
  });
};

export const getRandomBooks = (leagues, readHistory, length, tag) => {
  return new Promise((resolve, reject) => {
    if (leagues && readHistory && length) {
      try {
        const filteredLibrary =
          tag !== undefined
            ? library.filter((book) => {
                return (
                  leagues.includes(book.level) &&
                  book.tags.includes(tag) &&
                  !readHistory.includes(book.id)
                );
              })
            : library.filter((book) => {
                return (
                  leagues.includes(book.level) && !readHistory.includes(book.id)
                );
              });

        if (filteredLibrary.length <= length) {
          resolve({
            success: true,
            message: filteredLibrary,
          });
        } else {
          // Function to get a random book from a given array of books
          function getRandomBook(books) {
            const randomIndex = Math.floor(Math.random() * books.length);
            return books[randomIndex];
          }
          let actionCount = 0;

          // Function to filter books by word length and return a random book
          // function getRandomBookByWordLength(books, wordLength) {
          //   const filteredBooks = books.filter(
          //     (book) => book.wordLength === wordLength
          //   );
          //   return getRandomBook(filteredBooks);
          // }

          // Function to filter books by word length range and return a random book
          // function getRandomBookByWordLengthRange(
          //   books,
          //   minWordLength,
          //   maxWordLength
          // ) {
          //   const filteredBooks = books.filter(
          //     (book) =>
          //       book.wordLength >= minWordLength &&
          //       book.wordLength <= maxWordLength
          //   );
          //   return getRandomBook(filteredBooks);
          // }

          // Get the shortest book
          // const shortestWordLength = Math.min(
          //   ...filteredLibrary.map((book) => book.wordLength)
          // );
          // const shortestBook = getRandomBookByWordLength(
          //   filteredLibrary,
          //   shortestWordLength
          // );
          // shortestBook.type = "shortest";

          // // Get the easiest book (assuming it refers to the shortest word length)
          // const easiestBook = shortestBook;
          // easiestBook.type = "easiest";

          // Get the longest book
          // const longestWordLength = Math.max(
          //   ...filteredLibrary.map((book) => book.wordLength)
          // );
          // const longestBook = getRandomBookByWordLength(
          //   filteredLibrary,
          //   longestWordLength
          // );
          // longestBook.type = "I dare you";

          // Calculate the number of additional books needed in the collection
          const additionalBooksCount = length + 3; // Subtracting 3 for the books already added

          // Get additional random books to fill the collection
          const additionalBooks = [];
          while (
            additionalBooks.length < additionalBooksCount &&
            actionCount < 3000
          ) {
            actionCount = actionCount + 1;
            const randomBook = getRandomBook(filteredLibrary);
            if (!additionalBooks.some((book) => book.id === randomBook.id)) {
              additionalBooks.push(randomBook);
            }
          }

          // Combine all books into the new collection
          const newCollection = [...additionalBooks];

          resolve({
            success: true,
            message: newCollection,
          });
        }
      } catch (error) {
        reject({
          success: false,
          message: "error getting reader data" + error,
        });
      }
    }
  });
};

export const getRandomPagesForPlacement = (length) => {
  return new Promise((resolve, reject) => {
    const newLibrary = library.filter(
      (book) => book.orientation === "portrait"
    );
    let collection = [];
    for (let i = 0; i < length; i++) {
      const rand = Math.floor(Math.random() * newLibrary.length);
      collection.push(newLibrary[rand]);
    }
    resolve({
      success: true,
      message: collection,
    });
  });
};

export const getBookTags = (leagues, readHistory) => {
  // const historyTags = readHistory

  return new Promise((resolve, reject) => {
    let tags = {};
    library
      .filter((book) => leagues.includes(book.level))
      .map((book) =>
        book.tags.map((tag) => {
          if (tags[tag]) {
            tags[tag] = tags[tag] += 1;
          } else {
            tags[tag] = 1;
          }
        })
      );

    readHistory
      .map((bookId) => library.find((book) => book.id === bookId))
      .map((book) => {
        book.tags.map((tag) => {
          if (tags[tag]) {
            tags[tag] = tags[tag] += 100;
          }
        });
      });

    resolve({
      success: true,
      message: tags,
    });
  });
};

export const getSavedBooks = (savedBooks, readHistory) => {
  return new Promise((resolve, reject) => {
    try {
      let newCollection = [];

      savedBooks.map((bkm) => {
        if (!readHistory.includes(bkm.id)) {
          newCollection.push(bkm.id);
        }
      });
      const filteredLibrary = library.filter((book) =>
        newCollection.includes(book.id)
      );

      resolve({
        success: true,
        message: filteredLibrary,
      });
    } catch (error) {
      reject({
        success: false,
        message: "error getting reader data" + error,
      });
    }
  });
};

export const getBookInfo = (bookId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const q = query(
        collection(firestore, "books"),
        where("bookId", "==", bookId),
        limit(1)
      );

      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        resolve({
          success: false,
          message: "No book found with the provided ID",
        });
        return;
      }

      querySnapshot.forEach((doc) => {
        const bookData = doc.data();
        resolve({
          success: true,
          message: {
            id: doc.id,
            data: bookData,
          },
        });
      });
    } catch (error) {
      // console.error("Error retrieving book data:", error);
      reject({
        success: false,
        message: "Error retrieving book data",
      });
    }
  });
};

export const getTributes = (competition) => {
  return new Promise(async (resolve, reject) => {
    try {
      const q = query(
        collection(firestore, "tributes"),
        where("competition", "==", competition)
      );

      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        reject({
          success: false,
          message: "No Tributes found with in that competition",
        });
        return;
      }
      let data = [];
      querySnapshot.forEach((sn) => {
        data.push(sn.data());
      });
      resolve({
        success: true,
        message: data,
      });
    } catch (error) {
      console.error("Error retrieving tribute data:", error);
      reject({
        success: false,
        message: "Error retrieving tribute data",
      });
    }
  });
};

export const getAllCompetitions = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let competitions = [];
      const q = query(collection(firestore, "competitions"));

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((snapshot) => {
        let newObj = snapshot.data();
        newObj.id = snapshot.id;

        competitions.push(newObj);
      });

      resolve({
        success: true,
        message: competitions,
      });
    } catch (error) {
      reject({
        success: false,
        message: "couldn't get pending payments" + error,
      });
    }
  });
};
export const getAllNewCompetitions = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let competitions = [];
      const q = query(
        collection(firestore, "competitions"),
        where("status", "==", "new")
      );

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((snapshot) => {
        let newObj = snapshot.data();
        newObj.id = snapshot.id;

        competitions.push(newObj);
      });

      resolve({
        success: true,
        message: competitions,
      });
    } catch (error) {
      reject({
        success: false,
        message: "couldn't get pending payments" + error,
      });
    }
  });
};
export const getAllFilteredUsers = async (pet, car, color) => {
  return new Promise(async (resolve, reject) => {
    const q = query(
      collection(firestore, "students"),
      where("pet", "==", pet),
      where("car", "==", car),
      where("color", "==", color),
      orderBy("name", "desc")
    );

    let students = [];
    const promises = [];

    try {
      const snapshot = await getDocs(q);
      snapshot.forEach((doc) => {
        const studentPromise = async () => {
          let data = doc.data();
          const res = await getDocument("users", doc.id);
          let email = res.data.email;
          students.push({
            name: data.name,
            email: email,
          });
        };
        promises.push(studentPromise());
      });

      // Wait for all promises to resolve before resolving the main promise
      await Promise.all(promises);
      resolve({
        success: true,
        message: students,
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const createGamePass = (pass, data, user) => {
  return new Promise((resolve, reject) => {
    if (user.role !== "admin") {
      reject({
        success: false,
        message: "Only admins can create game passes",
      });
    }

    createDocument("passes", pass, data).then((res) => {
      if (res.success) {
        resolve({ success: true, message: "Created Pass Successfully" });
      } else {
        reject({
          success: false,
          message: res.message,
        });
      }
    });
  });
};

export const getGamePasses = (competitionId, type, limitNumber, user) => {
  return new Promise(async (resolve, reject) => {
    if (user.role !== "admin") {
      reject({
        success: false,
        message: "Only admins can see game passes",
      });
    }
    let passes = [];
    const q = query(
      collection(firestore, "passes"),
      where("competition", "==", competitionId),
      where("type", "==", type),
      limit(limitNumber),
      orderBy("createdAt", "desc")
    );
    const snapshot = await getDocs(q);

    snapshot.forEach((doc) => {
      let data = doc.data();
      data.id = doc.id;
      passes.push(data);
    });
    resolve({
      success: true,
      message: passes,
    });
  });
};

export const getGamePass = (pass, readerInfo, userInfo) => {
  return new Promise((resolve, reject) => {
    try {
      getDocument("passes", pass).then((res) => {
        if (res.success) {
          // (
          //   "demoReaders",
          //   readerInfo.id,
          //   {
          //     stars: 0,
          //     read: 0,
          //     quiz: 0,
          //     perfectScore: 0,
          //     competition: pass.competition,
          //   },
          //   userInfo
          // );
          updateDocument(
            "demoReaders",
            readerInfo.id,
            {
              stars: 0,
              read: 0,
              quiz: 0,
              perfectScore: 0,
              competition: res.data.competition,
              performance: [],
              readHistory: [],
            },
            userInfo
          ).then((resUpdate) => {
            if (resUpdate.success) {
              if (res.data.type === "single") {
                deleteDocument("passes", pass, userInfo).then((resDelete) => {
                  if (resDelete.success) {
                    resolve({
                      success: true,
                      message: "Successfully joined the competition!",
                    });
                  }
                });
              }
            } else {
              reject({
                success: false,
                message: "Couldn't Update the user",
              });
            }
          });
        } else {
          resolve({
            success: false,
            message: "Game Pass Doesn't exist",
          });
        }
      });
    } catch (e) {
      reject({
        success: false,
        message: "document doesn't exist",
      });
    }
  });
};

export const getBooksWithoutAudio = (lm, idArray) => {
  return new Promise((resolve, reject) => {
    const q = query(
      collection(firestore, "books"),
      where("bookId", "in", idArray)
      // limit(lm)
    );

    getDocs(q).then((snapshot) => {
      const books = [];

      snapshot.forEach((doc) => {
        const data = doc.data();

        if (!data.audioLinks) {
          books.push(data);
        }
      });

      resolve({
        success: true,
        message: books,
      });
    });
  });
};

export const getBookIdsWithAudio = () => {
  return new Promise((resolve, reject) => {
    const q = query(
      collection(firestore, "books"),
      where("audioLinks", "!=", null)
    );

    getDocs(q).then((snapshot) => {
      const books = [];

      snapshot.forEach((doc) => {
        const data = doc.data();

        if (data.audioLinks) {
          books.push({
            id: data.bookId,
            audioLinks: data.audioLinks,
            pages: library.find((bk) => bk.id === data.bookId).pages,
          });
        }
      });

      resolve({
        success: true,
        message: books,
      });
    });
  });
};
