import "../../style/utils.css";
export const PopUpAreYouSure = (props) => {
  return (
    <>
      <div className="pop-up-are-you-sure">
        <div className="div-question">
          <h2 className="pop-up-question">{props.question}</h2>
        </div>
        <div className="div-options">
          <button
            className="btn-cancel"
            onClick={() => {
              props.setPopUp(false);
            }}
          >
            {props.cancelMessage || "Cancel"}
          </button>

          <button
            className="btn-proceed"
            onClick={() => {
              props.proceedFunction();
            }}
          >
            {props.proceedMessage}
          </button>
        </div>
      </div>
      <div
        className="div-bg"
        onClick={() => {
          props.cancel();
        }}
      >
        {" "}
      </div>
    </>
  );
};
