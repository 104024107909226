import React, { useState, useEffect } from "react";

import redCup from "../../../assets/img/cups/red.png";

import blueCup from "../../../assets/img/cups/blue.png";
import yellowCup from "../../../assets/img/cups/yellow.png";
import greenCup from "../../../assets/img/cups/green.png";
import darkCup from "../../../assets/img/cups/dark.png";
import { Reorder } from "framer-motion";
import { Item } from "./Item";

import "../../../style/cupGame.css";
import AnimatedNumber from "../readingRoom/AnimatedNumber";
import { MdOutlineStar } from "react-icons/md";
import { updateDocument } from "../../../firebase-functions/database";
import { auth } from "../../../firebase-functions/config";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const CupGame = (props) => {
  const [cups, setCups] = useState([
    { id: "red", color: redCup },
    { id: "blue", color: blueCup },
    { id: "yellow", color: yellowCup },
    { id: "green", color: greenCup },
    { id: "dark", color: darkCup },
  ]);

  const [startTime, setStartTime] = useState();
  const [isDone, setIsDone] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [tries, setTries] = useState(0);
  const [initialItems, setInitialItems] = useState([
    redCup,
    blueCup,
    yellowCup,
    greenCup,
    darkCup,
  ]);
  const [giveUp, setGiveUp] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [stars, setStars] = useState(0);

  const randomizeArray = (array) => {
    let newArray = [];
    let length = array.length;
    while (newArray.length < length) {
      const randomIndex = Math.floor(Math.random() * length);
      if (randomIndex === length) {
        randomIndex = length - 1;
      }
      if (!newArray.includes(array[randomIndex])) {
        newArray.push(array[randomIndex]);
      }
    }
    return newArray;
  };
  useEffect(() => {
    setInitialItems(randomizeArray(items));
    setItems(randomizeArray(items));

    setStartTime(new Date());
  }, []);

  const handleCheck = () => {
    setTries(tries + 1);
    const length = items.length;
    let correct = 0;
    initialItems.map((item, index) => {
      if (item === items[index]) {
        correct = correct + 1;
      }
    });
    setCorrectAnswers(correct);

    if (length === correct) {
      setIsDone(true);
      let tempStars = 10;
      if (tries <= 5) {
      } else if (tries <= 10) {
        tempStars = 5;
      } else if (tries <= 12) {
        tempStars = 4;
      } else if (tries <= 16) {
        tempStars = 3;
      } else if (tries <= 20) {
        tempStars = 2;
      } else if (tries <= 25) {
        tempStars = 1;
      }
      setStars(tempStars);
      const duration = new Date() - startTime;
      let cupGameHistoryDemoReader = props.readerProfile?.cupGameHistory || [];

      cupGameHistoryDemoReader.unshift({
        datePlayed: new Date(),
        tries: tries,
        duration: duration,
      });

      updateDocument(
        "demoReaders",
        props.readerProfile.id,
        {
          stars: parseInt(props.readerProfile.stars) + tempStars + props.stars,
          cupGameHistory: cupGameHistoryDemoReader,
        },
        auth.currentUser
      ).then((res) => {
        if (!res.success) {
          toast.error("something went wrong reader profile", res.message);
        }
      });

      let cupGameHistoryStudent = props?.studentInfo?.cupGameHistory || [];
      cupGameHistoryStudent.unshift({
        datePlayed: new Date(),
        tries: tries,
        duration: duration,
      });

      updateDocument(
        "students",
        auth.currentUser.uid,
        {
          cupGameHistory: cupGameHistoryStudent,
        },
        auth.currentUser
      ).then((res) => {
        if (!res.success) {
          toast.error("something went wrong reader profile", res.message);
        }
      });
    }
  };

  return (
    <div className="shelf">
      <div className="div-header">
        {items.length === correctAnswers ? (
          <h1>Great Job!</h1>
        ) : (
          <h1>
            {[0, 1, 2, 3, 4, 5].includes(correctAnswers)
              ? correctAnswers + " correct answers"
              : ""}{" "}
          </h1>
        )}
        <div className="tries">
          <h1>
            {tries} {tries === 1 ? " try" : " tries"}
          </h1>
        </div>
      </div>
      <div className="div-subheader">
        {tries < 5 && (
          <p className="game-explanation">
            Guess the correct position of the stars with the fewest tries!
          </p>
        )}
        <p className="game-explanation">
          <Link to={"https://youtu.be/jhwg6sRVOJ0"} target="_blank">
            Watch a video tutorial here
          </Link>
        </p>
      </div>
      <Reorder.Group
        axis="x"
        onReorder={setItems}
        values={items}
        className="ul"
      >
        {items.map((item) => (
          <Item key={item} item={item} />
        ))}
      </Reorder.Group>
      <div className="div-btn">
        <button
          className="btn-check btn-give-up"
          onClick={() => {
            setGiveUp(true);
            setIsDone(true);
          }}
        >
          Give up
        </button>
        <button
          className="btn-check"
          onClick={() => {
            if (!giveUp) {
              handleCheck();
            }
          }}
        >
          Check
        </button>
      </div>

      {isDone && (
        <div className="div-initial">
          <div className="white-container">
            {!giveUp && (
              <h1 className="tries">
                {tries} {tries === 1 ? " try" : " tries"}
              </h1>
            )}
            {!giveUp && (
              <div className="div-stars">
                <MdOutlineStar />
                <h3>
                  <AnimatedNumber value={stars} />
                </h3>
              </div>
            )}
            <ul className="ul">
              {initialItems.map((item) => (
                <li className="list" key={item}>
                  <img
                    src={item}
                    alt="img cup"
                    className="img-cup"
                    draggable={false}
                  />
                </li>
              ))}
            </ul>
            <div className="div-btn-go-back">
              <button
                onClick={() => window.location.reload()}
                className="btn-go-back"
              >
                Go back to books
              </button>
            </div>
          </div>

          <div className="bg-initial"></div>
        </div>
      )}
    </div>
  );
};

export default CupGame;
