import React from "react";
import "../../style/readingRoom.css";
import { FaAngleDoubleRight, FaAngleDoubleLeft } from "react-icons/fa";
export const Page = React.forwardRef((props, ref) => {
  return (
    <div className="demoPage" ref={ref}>
      {(props.number % 2 === 1 || window.innerWidth < 600) && (
        <FaAngleDoubleRight className="flip-helper flip-helper-right" />
      )}
      {(props.number % 2 === 2 || window.innerWidth < 600) && (
        <FaAngleDoubleLeft className="flip-helper flip-helper-left" />
      )}
      {props.children}
    </div>
  );
});
