import { Link } from "react-router-dom";
import "../../style/utils.css";
import { updateDocument } from "../../firebase-functions/database";
import { auth } from "../../firebase-functions/config";
import { toast } from "react-toastify";

export const DemoExpired = (props) => {
  const extensions = props.readerProfile?.extensions || 0;

  const offer = !extensions || extensions < 3 ? 0 : 10;
  return (
    <div className="contaier-make-payment">
      <h1> Demo expired </h1>
      <sub> Your trial account has expired</sub>
      <p>
        Make payment to continue with the competition. You will not lose any of
        your reads, stars, and quizzes from your trial account.
      </p>
      <div className="div-btn-payment">
        <Link to="/payment" className="btn-payment">
          Make Payment
        </Link>
      </div>
      <p>
        You can also extend your trial for one more hour for{" "}
        {offer === 0 ? "free" : "10 baht / 0.25 cents"}
      </p>
      {offer === 0 ? (
        <button
          className="btn-payment"
          onClick={() => {
            const currentDate = new Date();
            const hoursInMilliseconds = 1000 * 60 * 60; // Milliseconds in an hour
            const targetDate = new Date(
              currentDate.getTime() - hoursInMilliseconds * 71
            );

            updateDocument(
              "demoReaders",
              props.readerProfile.id,
              {
                dateCreated: targetDate,
                extensions: props.readerProfile?.extensions + 1 || 1,
              },
              auth.currentUser
            ).then((res) => {
              if (res.success) {
                toast.success("Done!");
                setTimeout(() => {
                  window.location.reload();
                }, 1500);
              }
            });
          }}
        >
          Extend
        </button>
      ) : (
        <Link to="/extend" className="btn-payment">
          Extend for 10 baht
        </Link>
      )}
    </div>
  );
};
