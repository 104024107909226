// SignUp.js

import React, { useEffect, useState } from "react";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaKeyboard } from "react-icons/fa";
import { FaBookSkull } from "react-icons/fa6";

import "../../App.css"; // Import the core CSS
import "../../style/auth.css"; // Import the custom CSS

import { signUpWithEmailAndPassword } from "../../firebase-functions/auth";

import { Link } from "react-router-dom";
import { StepThree } from "./SignUpSteps.jsx/StepThree";

import {
  getDocument,
  createDocument,
  getDefaultCompetition,
  getPaymentStatusForCompetition,
} from "../../firebase-functions/database";
import { auth } from "../../firebase-functions/config";
import { onAuthStateChanged, sendEmailVerification } from "firebase/auth";
import { StepTwo } from "./SignUpSteps.jsx/StepTwo";
import { Password } from "./Password";
const SignUp = (props) => {
  const [userData, setUserData] = useState();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [iconPassword, setIconPassword] = useState(true);
  const [paymentData, setPaymentData] = useState();

  useEffect(() => {
    onAuthStateChanged(auth, (u) => {
      setUserData(u);
    });
  });

  useEffect(() => {
    if (userData) {
      setStep(2);
      getDocument("students", userData.uid).then((res) => {
        const studentData = res?.data;
        if (studentData?.name) {
          setStep(3);
          getDefaultCompetition().then((res2) => {
            let newObj = res2.message.data;
            newObj.id = res2.message.id;

            if (res2.success) {
              getPaymentStatusForCompetition(
                res2.message.id,
                auth.currentUser
              ).then((res3) => {
                if (res3.success) {
                  setStep(res3.message);
                  if (res3.message === 5) {
                    setPaymentData(res3.data);
                  } else if (res3.message === 6) {
                    const toastId = "allSet";
                    if (!toast.isActive(toastId)) {
                      toast.info(
                        "You are all set. Redirecting to the dashboard",
                        {
                          toastId: toastId,
                          autoClose: 1900,
                          transition: Bounce,
                        }
                      );
                      setTimeout(() => {
                        window.location.replace("/");
                      }, 200);
                    }
                  }
                }
              });
            }
          });
        }
      });
    }
  }, [userData]);

  const handleCreateAccount = async (e) => {
    e.preventDefault();

    try {
      // Extract email and password from form or state

      // Perform user signup with email and password
      const response = await signUpWithEmailAndPassword(email, password);

      if (response.success) {
        const uid = response.message.uid;

        // Create user document (optional)
        createDocument("users", uid, {
          email: email,
          role: "new",
          createdAt: new Date(),
          updatedAt: new Date(),
          language: "en",
        });

        // Send email verification
        await sendEmailVerification(response.message); // Use response.message (user object)
        toast.success("Verification link sent to your email.");

        setTimeout(() => {
          window.location.replace("/");
        }, 1500);
        // Optionally, redirect to a confirmation page or display a success message
      } else {
        toast.error(response.message?.code); // Display error message using toast
      }
    } catch (error) {
      console.error("Error creating account:", error.message);
      // Optionally, display a generic error message to the user
    }
  };

  return (
    <div className="container">
      <div className="signup-container">
        <div className={`step ${step === 1 ? "active" : ""}`}>
          <h2>Create Account</h2>
          {/* Buttons for signing up with Google, Facebook, or Email/Password */}

          <form
            onSubmit={handleCreateAccount}
            id="emailForm"
            className="signup-form"
          >
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            {iconPassword ? (
              <div className="form-group">
                <div className="label">
                  <label htmlFor="password">Password:</label>

                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setIconPassword(false);
                      setPassword();
                    }}
                  >
                    {" "}
                    <FaKeyboard className="password-icon" />
                  </button>
                </div>

                <Password setPassword={setPassword} />
              </div>
            ) : (
              <div className="form-group">
                <div className="label">
                  <label htmlFor="password">Password:</label>

                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setIconPassword(true);
                    }}
                  >
                    {" "}
                    <FaBookSkull className="password-icon" />
                  </button>
                </div>
                <input
                  type="password"
                  id="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
            )}
            <div className="link-redirect">
              <Link to="/login">Already Have an account?</Link>
            </div>
            <button type="submit" className="button">
              Create Account
            </button>
          </form>
        </div>

        {step === 2 && <StepTwo userData={userData} setStep={setStep} />}
        {(step === 3 || step === 5) && (
          <StepThree
            setStep={setStep}
            paymentData={paymentData}
            readerProfile={props.readerProfile}
          />
        )}

        <div className={`step ${step === 4 ? "active" : ""}`}>
          <h2>Payment Submitted!</h2>
          <p>
            Please wait until our admins review your payment. It might take up
            to 24 hours.
          </p>
        </div>

        <div className={`step ${step === 6 ? "active" : ""}`}>
          <h2>All done!</h2>
          <p>Please wait until the competition. You are all set!</p>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SignUp;
