import React from "react";
import "../../style/guest.css";
import { CountDown } from "../student/CountDown";

export const WhyBookBattle = (props) => {
  return (
    <div className="how-it-works">
      <h1>Why Book-Battle?</h1>
      <div className="instructions-container-payment instructions-container-landing">
        <div className="instruction-box-payment instruction-box-odd">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/kids-az.appspot.com/o/public%2Flanding%2FScreenshot%202024-07-08%20at%2022.33.14.png?alt=media&token=fdb6ec81-8a80-4781-bd9b-ab212d41ad1f"
            alt="illustration-step-1"
            className="img-instructions"
          />
          <div className="div-text">
            <h4 className="instruction-text-payment">Extensive Library</h4>
            <p>
              Book-Battle offers a vast collection of illustrated books,
              audiobooks, and quizzes, all specially designed for kids learning
              phonics or advancing their reading skills. With tag options,
              students can easily explore different genres that interest them.
            </p>
          </div>
        </div>

        <div className="instruction-box-payment instruction-box-even">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/kids-az.appspot.com/o/public%2Flanding%2Fpexels-harrisonhaines-3536480.jpg?alt=media&token=5ee4e271-62aa-4242-bf94-d46d7b1955aa"
            alt="illustration-step-2"
            className="img-instructions"
          />
          <div className="div-text">
            <h4 className="instruction-text-payment">Child-Friendly Content</h4>
            <p>
              Every material on our platform is carefully curated to be engaging
              and enjoyable for kids, ensuring they look forward to their
              reading time.
            </p>
          </div>
        </div>

        <div className="instruction-box-payment instruction-box-odd">
          <div className="countdown">
            <CountDown competition={props.competitionInfo} />
          </div>
          <div className="div-text">
            <h4 className="instruction-text-payment">Exciting Competitions</h4>
            <p>
              Regular reading competitions keep kids motivated and excited,
              encouraging them to challenge their peers and themselves.
            </p>
          </div>
        </div>

        <div className="instruction-box-payment instruction-box-even">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/kids-az.appspot.com/o/public%2Flanding%2Fpexels-kampus-7414101.jpg?alt=media&token=3246d5b7-fe18-4fbe-b8c1-91b6a1ed146d"
            alt="illustration-step-4"
            className="img-instructions"
          />
          <div className="div-text">
            <h4 className="instruction-text-payment">
              Comprehensive Tracking Tools
            </h4>
            <p>
              Parents and teachers can effortlessly monitor progress with
              detailed tracking tools. See how many books have been read, along
              with percentages, pages, words, and more.
            </p>
          </div>
        </div>

        <div className="instruction-box-payment instruction-box-odd">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/kids-az.appspot.com/o/public%2Flanding%2Fstep-1.jpg?alt=media&token=868220f5-a93d-4e1a-9635-bb62162ef2db"
            alt="illustration-step-5"
            className="img-instructions"
          />
          <div className="div-text">
            <h4 className="instruction-text-payment">Simple Login Process</h4>
            <p>
              Our unique icon-based login system means even 5-year-olds can
              access their accounts without needing to type, making it
              user-friendly for the youngest readers.
            </p>
          </div>
        </div>

        <div className="instruction-box-payment instruction-box-even">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/kids-az.appspot.com/o/public%2Flanding%2FScreenshot%202024-07-08%20at%2022.38.06.png?alt=media&token=d972fe08-f9be-4465-ba77-03750f375dc1"
            alt="illustration-step-6"
            className="img-instructions"
          />
          <div className="div-text">
            <h4 className="instruction-text-payment">
              Motivational Milestones and Rewards
            </h4>
            <p>
              Hundreds of milestones, incentives, and badges are designed to
              encourage kids to read just one more book, fostering a continuous
              love for reading.
            </p>
          </div>
        </div>

        <div className="instruction-box-payment instruction-box-odd">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/kids-az.appspot.com/o/public%2Flanding%2Fpexels-pixabay-4158%20(1).jpg?alt=media&token=358bcf63-ba40-4878-9227-42541fa8b200"
            alt="illustration-step-7"
            className="img-instructions"
          />
          <div className="div-text">
            <h4 className="instruction-text-payment">
              Seamless Multi-Device Use
            </h4>
            <p>
              Book-Battle can be accessed on multiple devices, all synced with
              the cloud, providing a seamless and flexible reading experience.
            </p>
          </div>
        </div>

        <div className="instruction-box-payment instruction-box-even">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/kids-az.appspot.com/o/public%2Flanding%2Fpexels-artstel-4019754.jpg?alt=media&token=948fbba5-879d-4be7-af7f-a89b509ced78"
            alt="illustration-step-8"
            className="img-instructions"
          />
          <div className="div-text">
            <h4 className="instruction-text-payment">
              Supportive Learning Environment
            </h4>
            <p>
              We partner with parents and schools to create a nurturing and
              supportive reading environment, helping every child succeed and
              develop a lifelong love for books.
            </p>
          </div>
        </div>
      </div>
      <div className="disclaimer">
        <p>
          *Disclaimer: The photos used on this page are stock photos sourced
          from Pexels (https://www.pexels.com), a royalty-free image platform.
          The individuals depicted do not endorse our product nor are they aware
          of our existence.
        </p>
      </div>
    </div>
  );
};
