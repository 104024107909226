import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { auth } from "../../firebase-functions/config";
import { sendPasswordResetEmail } from "firebase/auth";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await sendPasswordResetEmail(auth, email);
      toast.success("Password reset email sent!");
    } catch (error) {
      toast.error("Error sending password reset email:", error.message);
    }
  };

  return (
    <div className="container">
      <div className="signup-container">
        <h2>Forgot Password</h2>
        <p>
          Enter your email address and we'll send you a link to reset your
          password.
        </p>

        <form onSubmit={handleSubmit} className="signup-form">
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <button type="submit" className="button">
            Send Reset Email
          </button>
          <br />
          <br />
          <div className="link-redirect" style={{ textAlign: "center" }}>
            Remembered your password?{" "}
            <strong>
              {" "}
              <Link to="/login">Log in</Link>
            </strong>
          </div>
        </form>
      </div>

      <ToastContainer />
    </div>
  );
};

export default ForgotPassword;
