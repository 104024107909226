import React, { useState } from "react";
import axios from "axios";

export function TextToSpeech(props) {
  const [audioSrc, setAudioSrc] = useState(null);

  const generateSpeech = async () => {
    try {
      const response = await axios.post(
        "https://api.openai.com/v1/audio/speech",
        {
          model: "tts-1",
          voice: "alloy",
          input: "props.text",
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_OPENAI_API_KEY,
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      );

      const url = URL.createObjectURL(
        new Blob([response.data], { type: "audio/mp3" })
      );
      setAudioSrc(url);
    } catch (error) {
      console.error("Error generating speech:", error);
    }
  };
  return (
    <div>
      <button onClick={generateSpeech}>Generate Speech</button>
      {audioSrc && (
        <audio controls>
          <source src={audioSrc} type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>
      )}
    </div>
  );
}

export default TextToSpeech;
