import React, { useState, useEffect } from "react";
import {
  getAuth,
  signInWithEmailLink,
  verifyPasswordResetCode,
  confirmPasswordReset,
  applyActionCode,
} from "firebase/auth";
import "../../style/auth.css";
import { Password } from "./Password";
import { FaRegEyeSlash, FaEye, FaKeyboard } from "react-icons/fa";
import { FaBookSkull } from "react-icons/fa6";
import { auth } from "../../firebase-functions/config";
import { toast } from "react-toastify";
export const ResetPassword = () => {
  const [iconPassword, setIconPassword] = useState(true);
  const [seePasswrod, setSeePassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState(1);
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("oobCode"); // Get the 'code' parameter

  const getParameterByName = (name) => {
    return urlParams.get(name);
  };

  function handleVerifyEmail(auth, actionCode, continueUrl, lang) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    // Try to apply the email verification code.

    applyActionCode(auth, actionCode)
      .then((resp) => {
        toast.success("email verified successfully");
        setTimeout(() => {
          window.location.replace("/signup");
        }, 300);
        // Email address has been verified.
        // TODO: Display a confirmation message to the user.
        // You could also provide the user with a link back to the app.
        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
      })
      .catch((error) => {
        toast.error(error);
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
      });
  }

  function handleResetPassword(
    auth,
    actionCode,
    continueUrl,
    lang,
    newPassword
  ) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.

    // Verify the password reset code is valid.
    verifyPasswordResetCode(auth, actionCode)
      .then((email) => {
        const accountEmail = email;

        // TODO: Show the reset screen with the user's email and ask the user for
        // the new password.

        // Save the new password.
        confirmPasswordReset(auth, actionCode, newPassword)
          .then((resp) => {
            toast.success("Password reset Successfully");
            setTimeout(() => {
              window.location.replace("/");
            }, 1000);
            // Password reset has been confirmed and new password updated.
            // TODO: Display a link back to the app, or sign-in the user directly
            // if the page belongs to the same domain as the app:
            // auth.signInWithEmailAndPassword(accountEmail, newPassword);
            // TODO: If a continue URL is available, display a button which on
            // click redirects the user back to the app via continueUrl with
            // additional state determined from that URL's parameters.
          })
          .catch((error) => {
            toast.error(error);
            // Error occurred during confirmation. The code might have expired or the
            // password is too weak.
          });
      })
      .catch((error) => {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
      });
  }

  document.addEventListener(
    "DOMContentLoaded",
    () => {
      // TODO: Implement getParameterByName()

      // Get the action to complete.
      const mode = urlParams.get("mode");
      // Get the one-time code from the query parameter.
      const actionCode = urlParams.get("oobCode");
      // (Optional) Get the continue URL from the query parameter if available.
      const continueUrl = urlParams.get("continueUrl");
      // (Optional) Get the language code if available.
      const lang = urlParams.get("lang") || "en";

      // Configure the Firebase SDK.
      // This is the minimum configuration required for the API to be used.
      const config = {
        apiKey: urlParams.get("apiKey"),
      };

      // Handle the user management action.
      switch (mode) {
        case "resetPassword":
          // Display reset password handler and UI.
          handleResetPassword(auth, actionCode, continueUrl, lang);
          break;

        case "verifyEmail":
          // Display email verification handler and UI.
          handleVerifyEmail(auth, actionCode, continueUrl, lang);
          break;
        default:
        // Error: invalid mode.
      }
    },
    false
  );

  useEffect(() => {
    const verifyCode = async () => {
      try {
        await verifyPasswordResetCode(auth, code);
      } catch (error) {
        toast.error("Error verifying reset code:", error);
        toast.error(error.message);
      }
    };

    verifyCode();
  }, [code, auth]);
  useEffect(() => {
    const mode = urlParams.get("mode");
    // Get the one-time code from the query parameter.
    const actionCode = urlParams.get("oobCode");
    // (Optional) Get the continue URL from the query parameter if available.
    const continueUrl = urlParams.get("continueUrl");
    // (Optional) Get the language code if available.
    const lang = urlParams.get("lang") || "en";

    // Configure the Firebase SDK.
    // This is the minimum configuration required for the API to be used.
    const config = {
      apiKey: urlParams.get("apiKey"),
    };
    if (mode === "verifyEmail") {
      handleVerifyEmail(auth, actionCode, continueUrl, lang);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword.length < 6) {
      toast.error("Password must be at least 6 characters long.");
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }

    try {
      handleResetPassword(
        auth,
        urlParams.get("oobCode"),
        "/",
        "en",
        newPassword
      );
    } catch (error) {
      console.error("Error resetting password:", error);

      toast.error(error.message);
    }
  };

  return (
    <div className="container">
      <div className="signup-container">
        <form onSubmit={handleSubmit}>
          {step === 1 && (
            <>
              {" "}
              {iconPassword ? (
                <div className="form-group">
                  <div className="label">
                    <label htmlFor="newPassword">New Password:</label>

                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setIconPassword(false);
                      }}
                    >
                      {" "}
                      <FaKeyboard className="password-icon" />
                    </button>
                  </div>
                  <Password setPassword={setNewPassword} />
                </div>
              ) : (
                <div className="form-group">
                  <div className="label">
                    <label htmlFor="newPassword">New Password:</label>

                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setIconPassword(true);
                      }}
                    >
                      {" "}
                      <FaBookSkull className="password-icon" />
                    </button>
                  </div>
                  <div className="div-password">
                    <input
                      type={seePasswrod ? "text" : "password"}
                      id="password"
                      placeholder="Enter your password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                    />
                    {seePasswrod ? (
                      <FaEye
                        className="icon-eye"
                        onClick={() => {
                          setSeePassword(false);
                        }}
                      />
                    ) : (
                      <FaRegEyeSlash
                        className="icon-eye"
                        onClick={() => {
                          setSeePassword(true);
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
            </>
          )}

          {step === 2 && (
            <>
              {" "}
              {iconPassword ? (
                <div className="form-group">
                  <div className="label">
                    <label htmlFor="confirmPassword">Confirm Password:</label>

                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setIconPassword(false);
                      }}
                    >
                      {" "}
                      <FaKeyboard className="password-icon" />
                    </button>
                  </div>
                  <Password setPassword={setConfirmPassword} />
                </div>
              ) : (
                <div className="form-group">
                  <div className="label">
                    <label htmlFor="confirmPassword">Confirm Password:</label>

                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setIconPassword(true);
                      }}
                    >
                      {" "}
                      <FaBookSkull className="password-icon" />
                    </button>
                  </div>
                  <div className="div-password">
                    <input
                      type={seePasswrod ? "text" : "password"}
                      id="password"
                      placeholder="Enter your password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                    {seePasswrod ? (
                      <FaEye
                        className="icon-eye"
                        onClick={() => {
                          setSeePassword(false);
                        }}
                      />
                    ) : (
                      <FaRegEyeSlash
                        className="icon-eye"
                        onClick={() => {
                          setSeePassword(true);
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
            </>
          )}

          {step === 2 ? (
            <button type="submit">Reset Password</button>
          ) : (
            <button
              onClick={(e) => {
                e.preventDefault();
                setStep(2);
              }}
            >
              Next
            </button>
          )}
        </form>
      </div>
    </div>
  );
};
