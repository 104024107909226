import { AiTwotoneTrophy } from "react-icons/ai";
import { Flat } from "@alptugidin/react-circular-progress-bar";
import { useEffect, useState } from "react";
import { getFile } from "../../firebase-functions/storage";
import badgesJSON from "../../assets/json/badges.json";
import { SiPowerpages } from "react-icons/si";
import { GiSandsOfTime } from "react-icons/gi";
import { MdOutlineSpeakerNotes } from "react-icons/md";
import { info } from "autoprefixer";

const BadgeBox = (props) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div
      className="badge-container"
      onMouseEnter={() => {
        setIsActive(true);
      }}
      onMouseLeave={() => {
        setIsActive(false);
      }}
    >
      {" "}
      <Flat
        progress={props.percentile}
        range={{ from: 0, to: props.max || 100 }}
        text={" "}
        showMiniCircle={true}
        showValue={false}
        sx={{
          strokeColor: "#2ecc71",
          barWidth: 10,
          bgColor: { value: "#000000", transparency: "20" },
          shape: "full",
          strokeLinecap: "round",
          valueSize: 20,
          valueWeight: "bold",
          valueColor: "#000000",
          valueFamily: "Trebuchet MS",
          textSize: 13,
          textWeight: "bold",
          textColor: "#000000",
          textFamily: "Trebuchet MS",
          loadingTime: 1000,
          miniCircleColor: "#34495e",
          miniCircleSize: 15,
          valueAnimation: true,
          intersectionEnabled: true,
        }}
      />
      <img
        src={props.src}
        alt="Badge 1"
        style={{ filter: `grayscale(${1 - props.percentile / props.max})` }}
      />
      <h4 className="badge-title">{props.title}</h4>
      {props.type && (
        <div className="badge-type-icon">
          {props.type === "page" && <SiPowerpages />}
          {props.type === "word" && <MdOutlineSpeakerNotes />}
          {props.type === "time" && <GiSandsOfTime />}
        </div>
      )}
      {isActive && (
        <div className="div-target-info">
          {props.type === "page" && <h2>Read {props.target} pages</h2>}
          {props.type === "word" && <h2>Read {props.target} words</h2>}
          {props.type === "time" && (
            <h2>
              Read for{" "}
              {props.target / 3600 >= 1 &&
                Math.floor(props.target / 3600) + " hours, "}
              {(props.target % 3600) / 60 >= 1 &&
                Math.floor((props.target % 3600) / 60) + " minutes"}{" "}
              {Math.floor(props.target % 60) > 0 &&
                Math.floor(props.target % 60) + ", seconds"}
            </h2>
          )}
          {props.type !== "time" ? (
            <p>
              {props.complete} {props.type}s complete
            </p>
          ) : (
            <p>
              {" "}
              {props.complete / 3600 >= 1 &&
                Math.floor(props.complete / 3600) + " hours, "}
              {(props.complete % 3600) / 60 >= 1 &&
                Math.floor((props.complete % 3600) / 60) + " minutes"}{" "}
              {Math.floor(props.complete % 60) > 0 &&
                Math.floor(props.complete % 60) + ", seconds"}{" "}
              complete
            </p>
          )}
        </div>
      )}
    </div>
  );
};

const CompletedBadgeBox = (props) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div
      className="claimed-badge-container"
      onMouseEnter={() => {
        setIsActive(true);
      }}
      onMouseLeave={() => {
        setIsActive(false);
      }}
    >
      <img src={props.badge?.url} alt="Badge 1" />
      <h4 className="claimed-badge-title">{props.badge?.title}</h4>

      {isActive && (
        <div className="div-badge-display-container">
          <h2>{props.badge.definition}</h2>
          <p>{props.badge.message}</p>
        </div>
      )}
    </div>
  );
};
export const Badges = (props) => {
  const badgeStats =
    props.studentInfo?.studentInfo?.performance ||
    props.studentInfo?.performance;
  const [pages, setPages] = useState();
  const [duration, setDuration] = useState();
  const [wordLength, setWordLength] = useState();
  const [wordLengthTarget, setWordLengthTarget] = useState();
  const [timeTarget, setTimeTarget] = useState();
  const [pagesTarget, setPagesTarget] = useState();
  const [claimedWordBadges, setClaimedWordBadges] = useState([]);
  const [claimedPageBadges, setClaimedPageBadges] = useState([]);
  const [claimedTimeBadges, setClaimedTimeBadges] = useState([]);
  const [claimedBadges, setClaimedBadges] = useState([]);
  useEffect(() => {
    let durationTemp = 0;
    let pagesTemp = 0;
    let wordLengthTemp = 0;
    badgeStats?.length > 0 &&
      badgeStats.map((book) => {
        durationTemp = durationTemp + book.stats.duration;
        wordLengthTemp = wordLengthTemp + book.book?.wordLength || 0;
        pagesTemp = pagesTemp + book.book?.pages || 0;
      });

    setWordLength(wordLengthTemp);
    setPages(pagesTemp);
    setDuration(durationTemp);
  }, []);

  useEffect(() => {
    if (wordLength) {
      const wordBadgesArray = badgesJSON.filter((a) => a.type === "word");
      const lastBadge = wordBadgesArray
        .filter((a) => a.target < wordLength)

        .sort((a, b) => b.target - a.target)[0];

      const nextBadge = wordBadgesArray
        .filter((a) => a.target > wordLength)
        .sort((a, b) => a.target - b.target)[0];

      getFile("public/badges/words/" + nextBadge.id + ".jpg").then((res) => {
        setWordLengthTarget({
          last: lastBadge,
          next: nextBadge,
          url: res.url,
        });
      });

      const tempClaimedBadges = [...claimedWordBadges];

      wordBadgesArray
        .filter((a) => a.target < wordLength)
        ?.map(async (badge) => {
          let tempB = badge;
          getFile("public/badges/words/" + badge.id + ".jpg").then(
            async (res) => {
              tempB.url = await res.url;
              tempClaimedBadges.push(tempB);
            }
          );
        });
      setClaimedWordBadges(tempClaimedBadges);
    }
  }, [wordLength]);

  useEffect(() => {
    if (pages) {
      const pageBadgesArray = badgesJSON.filter((a) => a?.type === "page");
      const lastBadge = pageBadgesArray
        .filter((a) => a.target < pages)
        .sort((a, b) => b.target - a.target)[0];

      const nextBadge = pageBadgesArray
        .filter((a) => a.target > pages)
        .sort((a, b) => a.target - b.target)[0];
      let url;
      getFile("public/badges/pages/" + nextBadge.id + ".jpg").then((res) => {
        setPagesTarget({
          last: lastBadge,
          next: nextBadge,
          url: res.url,
        });
      });

      const tempClaimedBadges = [...claimedPageBadges];

      pageBadgesArray
        .filter((a) => a.target < pages)
        ?.map(async (badge) => {
          let tempB = badge;
          getFile("public/badges/pages/" + badge.id + ".jpg").then(
            async (res) => {
              tempB.url = await res.url;
              tempClaimedBadges.push(tempB);
            }
          );
        });
      setClaimedPageBadges(tempClaimedBadges);
    }
  }, [pages]);

  useEffect(() => {
    if (duration) {
      const timeBadgesArray = badgesJSON.filter((a) => a?.type === "time");
      const lastBadge = timeBadgesArray
        .filter((a) => a.target < duration / 1000)
        .sort((a, b) => b.target - a.target)[0];

      const nextBadge = timeBadgesArray
        .filter((a) => a.target > duration / 1000)
        .sort((a, b) => a.target - b.target)[0];

      getFile("public/badges/time/" + nextBadge.id + ".jpg").then((res) => {
        setTimeTarget({
          last: lastBadge,
          next: nextBadge,
          url: res.url,
        });
      });

      const tempClaimedBadges = [...claimedTimeBadges];

      timeBadgesArray
        .filter((a) => a.target < duration / 1000)
        ?.map(async (badge) => {
          let tempB = badge;
          await getFile("public/badges/time/" + badge.id + ".jpg").then(
            (res) => {
              tempB.url = res.url;
              tempClaimedBadges.push(tempB);
            }
          );
        });

      setClaimedTimeBadges(tempClaimedBadges);
    }
  }, [duration]);

  return (
    <div className="stats-badge-conainer">
      <h2>Badges</h2>
      {claimedWordBadges.length +
        claimedPageBadges.length +
        claimedTimeBadges.length >=
      1 ? (
        <div className="div-badges-continer">
          <div className="total-badges-container">
            <span className="total-badge-count">
              {claimedWordBadges.length +
                claimedPageBadges.length +
                claimedTimeBadges.length -
                3}
            </span>
            <AiTwotoneTrophy />
          </div>
          <div className="badge-shelf-container">
            <BadgeBox
              percentile={wordLength - wordLengthTarget?.last?.target}
              max={
                wordLengthTarget?.next?.target - wordLengthTarget?.last?.target
              }
              target={wordLengthTarget?.next?.target}
              complete={wordLength}
              src={wordLengthTarget?.url}
              title={wordLengthTarget?.next?.title}
              type={wordLengthTarget?.last?.type}
            />

            <BadgeBox
              percentile={pages - pagesTarget?.last?.target}
              max={pagesTarget?.next?.target - pagesTarget?.last?.target}
              src={pagesTarget?.url}
              title={pagesTarget?.next?.title}
              type={pagesTarget?.last.type}
              target={pagesTarget?.next?.target}
              complete={pages}
            />

            <BadgeBox
              percentile={duration / 1000 - timeTarget?.last?.target}
              max={timeTarget?.next?.target - timeTarget?.last?.target}
              src={timeTarget?.url}
              title={timeTarget?.next?.title}
              type={timeTarget?.last?.type}
              target={timeTarget?.next?.target}
              complete={duration / 1000}
            />
          </div>

          <div className=" completed-badge-shelf-container">
            {claimedWordBadges?.length > 1 &&
              claimedWordBadges
                .filter((a) => a.id > 0)
                .sort((a, b) => a.id - b.id)
                .map((badge) => {
                  return (
                    <CompletedBadgeBox
                      badge={badge}
                      key={badge.type + badge.id}
                    />
                  );
                })}

            {claimedPageBadges?.length > 1 &&
              claimedPageBadges
                .filter((a) => a.id > 0)
                .sort((a, b) => a.id - b.id)
                .map((badge) => {
                  return (
                    <CompletedBadgeBox
                      badge={badge}
                      key={badge.type + badge.id}
                    />
                  );
                })}

            {claimedTimeBadges?.length > 1 &&
              claimedTimeBadges
                .filter((a) => a.id > 0)
                .sort((a, b) => a.id - b.id)
                .map((badge) => {
                  return (
                    <CompletedBadgeBox
                      badge={badge}
                      key={badge.type + badge.id}
                    />
                  );
                })}
          </div>
        </div>
      ) : (
        <div>
          <p>Nothing so far</p>
        </div>
      )}
    </div>
  );
};
