import { useEffect, useState } from "react";
import { StudentLeaderBoard } from "../competition/StudentLeaderBoard";

import { StudentStats } from "./StudentStats";
import { CountDown } from "./CountDown";

import { Download } from "./Download";

const StudentDashboard = (props) => {
  const [rank, setRank] = useState();
  const [greeting, setGreeting] = useState("");

  useEffect(() => {
    const greetingTime = require("greeting-time");
    setGreeting(greetingTime(new Date()));
  }, []);

  return (
    <div>
      <h2 className="greeting">
        {greeting}, {(props.user && props.readerProfile?.name) || ""}!
      </h2>
      <StudentStats
        studentData={props}
        rank={rank}
        readerProfile={props.readerProfile}
      />

      <CountDown competition={props.competitionInfo} />
      {window.screen.orientation.type === "portrait-primary" && <Download />}
      <h2 className="title-leaderboard">
        Leaderboard{" "}
        {props.readerProfile?.league
          ? "for " + props.readerProfile?.league
          : ""}
      </h2>
      <p className="disclaimer">
        <strong>Disclaimer: </strong> Some accounts are bots and they are marked
        as [bot] in their names.
        <br /> They help the developer fix bugs and test the website. But they
        do not participate in the competition.{" "}
      </p>

      <StudentLeaderBoard
        user={props.user}
        setRank={setRank}
        readerProfile={props.readerProfile}
        competitionInfo={props.competitionInfo}
      />
    </div>
  );
};

export default StudentDashboard;
