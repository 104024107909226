import { useEffect, useState } from "react";
import { getInTeamLeaderBoard } from "../../firebase-functions/database";
import "../../style/leaderBoard.css";
import { FaBookOpen, FaRegStarHalfStroke } from "react-icons/fa6";
import { FaHeadphonesAlt } from "react-icons/fa";
import { SiGoogleforms } from "react-icons/si";
import { IoMdRibbon } from "react-icons/io";
import { SiRoblox } from "react-icons/si";
import { onAuthStateChanged } from "firebase/auth";

import { auth } from "../../firebase-functions/config";

export const InTeamLeaderBoard = (props) => {
  const leaderBoardData = props.leaderBoardData;
  const setLeaderBoardData = props.setLeaderBoardData;

  const [helper, setHelper] = useState();

  useEffect(() => {
    if (props?.readerProfile?.league && props?.readerProfile?.team) {
      getInTeamLeaderBoard(
        "readers",
        props?.readerProfile?.league,
        props?.readerProfile?.team
      ).then((res) => {
        setLeaderBoardData(res.students || []);
      });
    }
  }, [props.readerProfile]);

  const Reward = (props) => {
    const index = props.index;
    return (
      <div>
        {index === 0 ? 1000 : index === 1 ? 500 : index === 2 ? 250 : 0}
      </div>
    );
  };

  return (
    <div className="leaderboard-container">
      {leaderBoardData.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Name</th>

              <th>Stars</th>
              <th>Rewards</th>
            </tr>
          </thead>
          <tbody>
            {leaderBoardData.map((ld, index) => (
              <tr
                key={ld.id}
                className={
                  ld.id === props.readerProfile.id
                    ? "self-class"
                    : index % 2 === 0
                    ? "table-odd"
                    : "table-even"
                }
              >
                <td>
                  {index + 1}
                  {index === 0 && <IoMdRibbon className="ribbon-gold" />}
                  {index === 1 && <IoMdRibbon className="ribbon-silver" />}
                  {index === 2 && <IoMdRibbon className="ribbon-bronze" />}
                </td>
                <td>{ld.name}</td>

                <td
                  className="td-stars"
                  onMouseEnter={(e) => {
                    setHelper(index);
                  }}
                  onMouseLeave={(e) => {
                    if (helper === index) setHelper(null);
                  }}
                >
                  <>
                    <FaRegStarHalfStroke className="star-sign" />
                    {ld.stars}
                  </>
                  <div
                    className={`stars-helper-container ${
                      helper === index ? "stars-helper-visible" : ""
                    }`}
                  >
                    <FaBookOpen />
                    {ld.read} <> </>
                    <SiGoogleforms />
                    {ld.quiz}
                  </div>
                </td>
                <td className="row-reward">
                  <SiRoblox className="icon-roblox" />
                  {<Reward index={index} />}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {leaderBoardData.length === 0 && <h3>No data available</h3>}
    </div>
  );
};
