import { useEffect, useState } from "react";
import "../../style/email.css";
import {
  createDocument,
  createDocumentWithRandomUid,
} from "../../firebase-functions/database";
import { toast, ToastContainer } from "react-toastify";
import { onAuthStateChanged } from "firebase/auth";

export const Email = () => {
  const subjectContent = (school) => {
    return `Transform Reading into a Thrilling Competition: BookBattle Exclusive Offer for ${school}`;
  };

  const emailContent = (school) => {
    return `<!DOCTYPE html>
  <html>
  <head>
    <title>Spark a Love of Reading with <a href="https://book-battle.com" target="_blank">book-battle.com</a>!</title>
    <style>
      /* Define your color palette */
      :root {
        --primary-color: #3498db; /* Blue */
        --secondary-color: #2ecc71; /* Green */
        --dark-color: #34495e; /* Dark Gray */
        --light-color: #ecf0f1; /* Light Gray */
      }
  
      /* Body and general styles */
      body {
        font-family: "Arial", sans-serif;
        background-color: var(--light-color);
        color: var(--dark-color);
        margin: 0;
        padding: 0;
        max-width: 100vw;
        overflow-x: hidden !important;
        margin-bottom: 5em;
      }
  
      /* Headers and text styles */
      h1, h2, h3, a {
        color: var(--primary-color);
      }
  
      p {
        line-height: 1.6;
      }
  
      /* Links */
      a {
        text-decoration: none;
        transition: color 0.3s ease;
      }
  
      a:hover {
        color: var(--primary-color);
      }
  
      /* Buttons */
      .button {
        background-color: var(--primary-color);
        color: #fff;
        padding: 10px 15px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
      }
  
      .button:hover {
        background-color: var(--secondary-color);
      }
  
      /* Input fields */
      input, textarea {
        padding: 10px;
        margin: 5px 0;
        border: 1px solid var(--dark-color);
        border-radius: 5px;
        box-sizing: border-box;
        position: relative;
      }
  
      .div-password {
        position: relative;
      }
  
      .icon-eye {
        position: absolute;
        top: 0.7em;
        right: 0.5em;
        font-size: 1.2em;
        opacity: 0.7;
        cursor: pointer;
      }
  
      .icon-eye:active {
        opacity: 1;
      }
  
      /* Responsive design */
      @media screen and (max-width: 600px) {
        /* Adjust styles for smaller screens */
        body {
          max-width: 100vw;
          overflow-x: hidden;
        }
      }
    </style>
  </head>
  <body>
    <table border="0" cellpadding="0" cellspacing="0" width="100%">
      <tr>
        <td align="center">
          <img src="https://firebasestorage.googleapis.com/v0/b/kids-az.appspot.com/o/public%2Flogo.png?alt=media&token=cc583918-796e-4ebb-8094-986e29948cf1" alt="BookBattle Logo" width="200" height="50" />
        </td>
      </tr>
      <tr>
        <td align="center">
          <h1>Spark a Love of Reading with BookBattle!</h1>
        </td>
      </tr>
      <tr>
        <td align="center">
          <p>Dear ${school} School Staff,</p>
          <p>My name is David, the creator of <a href="https://book-battle.com" target="_blank">book-battle.com</a>, and a passionate educator with over 5 years of teaching experience. I understand the challenge of motivating students to read, and that's why I built <a href="https://book-battle.com" target="_blank">book-battle.com</a> – a 10-day online reading competition designed to gamify the reading experience for early and intermediate-level readers.</p>
        </td>
      </tr>
      <tr>
        <td align="center">
          <h2>What is <a href="https://book-battle.com" target="_blank">book-battle.com</a>?</h2>
          <ul>
            <li>A fun and engaging platform with over 2,500 books categorized by difficulty for students to choose from.</li>
            <li>A 10-day competition focusing on reading comprehension, not speed. Students read at their own pace.</li>
            <li>Interactive quizzes to test understanding and award stars based on performance.</li>
            <li>Leaderboards to track progress and encourage friendly competition among classmates.</li>
            <li><strong>Free to try and participate!</strong> Students receive full access to reading tools and track their stats for 3 days before deciding to continue for a small fee of <strong>$10</strong>. (Payment is handled securely through ABA bank transfer.)</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td align="center">
          <h2>Benefits for Students:</h2>
          <ul>
            <li>Develop a love for reading through gamification and positive reinforcement.</li>
            <li>Improve reading comprehension and critical thinking skills.</li>
            <li>Compete with peers in a safe and educational online environment.</li>
            <li>Earn certificates and recognition for their reading achievements.</li>
            <li><strong>Win prizes even if they don't continue after the free trial!</strong></li>
          </ul>
        </td>
      </tr>
      <tr>
        <td align="center">
          <h2>Benefits for Schools:</h2>
          <ul>
            <li>Aligns with the <strong>Common Core State Standards(CCSS)</strong>  for Reading.</li>
            <li><strong>Engages students!</strong> Provides a fun and interactive way to promote reading outside the classroom.</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td align="center">
          <p><strong>How We Can Partner:</strong></p>
          <p>I would be happy to provide a live demo of book-battle.com to your staff or interested parents. I'm in Cambodia and happy to connect virtually or even meet in person if you're located nearby. Let me know what works best for you!</p>
          <p><strong>Let's work together to foster a love of reading in our students!</strong></p>
          <p>Please don't hesitate to contact me at support@book-battle.com for more information.</p>
<p>To learn more about me, you can also visit my website: <a href="https://t-david.com" target="_blank">t-david.com</a>.</p>
        </td>
      </tr>
      <tr>
        <td align="center">
          <p>Sincerely,</p>
          <p>David<br>Creator, <a href="https://book-battle.com" target="_blank">book-battle.com</a>.</p>
        </td>
      </tr>
    </table>
  </body>
  </html>
  `;
  };
  const [email, setEmail] = useState();
  const [subject, setSubject] = useState();
  const [schoolName, setSchoolName] = useState();
  const [preview, setPreview] = useState(true);
  const [html, setHtml] = useState();

  const sendEmail = () => {
    if (!email) {
      toast.error("Email is required");
      return;
    }
    if (!subject) {
      toast.error("Subject is required");
      return;
    }

    if (!html) {
      toast.error("HTML is required");
      return;
    }
    createDocumentWithRandomUid("mail", {
      to: email,
      message: {
        subject: subject,
        html: html,
      },
    }).then((res) => {
      if (res.success) {
        toast.success("Email sent");
      } else {
        toast.error("couldn't send email", res.message);
      }
    });
  };

  useEffect(() => {
    if (schoolName) {
      setHtml(emailContent(schoolName));
      setSubject(subjectContent(schoolName));
    }
  }, [schoolName]);

  return (
    <div className="email-container">
      <ToastContainer />
      <h2 className="email-contianer-header">Email</h2>

      <div className="form-email">
        <div className="div-form">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>

        <div className="div-form">
          <label htmlFor="text">School Name</label>
          <input
            type="text"
            name="text"
            value={schoolName}
            onChange={(e) => {
              setSchoolName(e.target.value);
            }}
          />
        </div>
        <div className="div-form">
          <label htmlFor="subject">HTML</label>
          <button
            onClick={() => {
              setPreview(!preview);
            }}
            className="btn-preview"
          >
            {preview ? "HTML" : "Preview"}
          </button>
          {!preview && (
            <textarea
              name="html"
              id="html"
              cols="60"
              rows="20"
              value={html}
              onChange={(e) => {
                setHtml(e.target.value);
              }}
            ></textarea>
          )}
          {preview && (
            <div
              className="email-preview"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          )}
        </div>
        <div className="div-form">
          <button
            className="submit"
            onClick={() => {
              sendEmail();
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};
