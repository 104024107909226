import { useEffect, useState } from "react";
import library from "../../assets/json/library.json";
import {
  getBookInfo,
  getBooksWithoutAudio,
  updateDocument,
} from "../../firebase-functions/database";
import { auth } from "../../firebase-functions/config";
import { ToastContainer, toast } from "react-toastify";

const AudioBookBox = (props) => {
  const book = props.book;
  const i = props.i;
  const [src, setSrc] = useState();
  const list = props.list;
  const handleUpdate = async () => {
    getBookInfo(book.bookId).then((res) => {
      if (res.success) {
        const uid = res.message.id;
        updateDocument(
          "books",
          uid,
          {
            audioLinks: {
              title: src,
            },
          },
          auth.currentUser
        ).then((resUpdate) => {
          if (resUpdate.success) {
            toast.success("src added successfully");
          } else {
            console.error(resUpdate.message);
            toast.error(resUpdate.message);
          }
        });
      } else {
        console.log(res.message);
      }
    });
  };
  return (
    <>
      {list.includes(book.title) ? (
        <div className="div-audio-book">
          <h4>{i + 1}</h4>
          <h4>{book.bookId}</h4>
          <h4 className="div-audio-book-title">{book.title}</h4>
          <input
            type="text"
            value={src}
            onChange={(e) => {
              setSrc(e.target.value);
            }}
          />
          {src && (
            <button
              onClick={() => {
                handleUpdate();
              }}
            >
              Save
            </button>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export const AudioBooks = () => {
  const [stopVar, setStopVar] = useState("");
  const [books, setBooks] = useState([]);
  const [level, setLevel] = useState(localStorage.getItem("level"));
  const [step, setStep] = useState(parseInt(localStorage.getItem("step")));
  const [list, setList] = useState("");
  const handleGetBooks = () => {
    const fetchAudio = (url) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await fetch(url);

          if (response.ok) {
            resolve({
              success: true,
              url: url,
            });
          }
        } catch (error) {
          resolve({ sucess: false });
        }
      });
    };

    // fetchAudio();

    const getAudioOrder = (book) => {
      let order = -1;
      const array = library
        .filter((bk) => bk.level === book.level)
        .sort((a, b) => a.id - b.id);

      array.map((bk, i) => {
        if (bk.id === book.id) {
          order = i;
        }
      });

      return order;
    };
    const libraryTemp = [];
    books.map((bk) => {
      const bookId = bk.bookId;
      libraryTemp.push(library.find((a) => a.id === bookId));
    });

    libraryTemp.map(async (book) => {
      const bookId = book.id;
      const bookTitle = book.title;

      const LinkHead =
        "https://mi.content.raz-kids.com/audio/" +
        bookId +
        "/raz_" +
        bookTitle
          .toLowerCase()
          .replace(/[^a-z]/g, "")
          .replace(/\s+/g, "") +
        "_l" +
        book.level.toLowerCase();

      const LinkTail = "_title_text.mp3";
      const tempAudioOrder = getAudioOrder(book);

      for (let i = tempAudioOrder - 10; i < tempAudioOrder + 10; i++) {
        let src = LinkHead;
        if (i < 10) {
          src += "0";
        }
        src += i + LinkTail;

        await fetchAudio(src).then((res) => {
          if (res.success) {
            getBookInfo(bookId).then((bookIdRes) => {
              if (bookIdRes.success) {
                const uid = bookIdRes.message.id;

                updateDocument(
                  "books",
                  uid,
                  {
                    audioLinks: {
                      title: res.url,
                    },
                  },
                  auth.currentUser
                ).then((resUpdate) => {
                  if (resUpdate.success) {
                    toast.success("src added successfully");
                    console.log("Success! src added successfully");
                  } else {
                    console.error(resUpdate.message);
                  }
                });
              } else {
                console.error(bookIdRes.message);
              }
            });
          }
        });
      }
    });
  };

  useEffect(() => {
    const bookArray = library
      .filter((bk) => bk.level === level)
      .map((bk) => bk.id)
      .slice(step * 30, (step + 1) * 30);

    getBooksWithoutAudio(10, bookArray).then((res) => {
      if (res.success) {
        setBooks(res.message);
      }
    });
    localStorage.setItem("level", level);
    localStorage.setItem("step", step);
  }, [level, step]);

  useEffect(() => {
    if (books.length > 0) {
      console.log(books);
    }
  }, [books]);
  return (
    <div className="admin-audio-books-contianer">
      <div className="admin-audio-books-contianer-header">
        <h2>Audio Books ({books.length})</h2>
        <button
          onClick={() => {
            handleGetBooks();
          }}
        >
          Get URLs
        </button>
        <select
          name=""
          id=""
          value={level}
          onChange={(e) => {
            setLevel(e.target.value);
          }}
        >
          <option value="A">A</option>
          <option value="B">B</option>
          <option value="C">C</option>
          <option value="D">D</option>
          <option value="E">E</option>
          <option value="F">F</option>
          <option value="G">G</option>
          <option value="H">H</option>
          <option value="I">I</option>
          <option value="J">J</option>
          <option value="K">K</option>
          <option value="L">L</option>
          <option value="M">M</option>
          <option value="N">N</option>
          <option value="O">O</option>
          <option value="P">P</option>
          <option value="Q">Q</option>
          <option value="R">R</option>
          <option value="S">S</option>
          <option value="T">T</option>
          <option value="U">U</option>
          <option value="V">V</option>
          <option value="W">W</option>
          <option value="X">X</option>
          <option value="Y">Y</option>
          <option value="Z">Z</option>
          <option value="Z1">Z1</option>
          <option value="Z2">Z2</option>
        </select>
        <select
          name="step"
          id="step"
          value={step}
          onChange={(e) => {
            setStep(e.target.value);
          }}
        >
          <option value={0}>0</option>
          <option value={1}>1</option>
          <option value={2}>2</option>
        </select>

        <button
          onClick={() => {
            if (step === 2) {
              setStep(0);
              if (level === "A") {
                setLevel("B");
              }
              if (level === "B") {
                setLevel("C");
              }
              if (level === "D") {
                setLevel("E");
              }
              if (level === "E") {
                setLevel("F");
              }
              if (level === "F") {
                setLevel("G");
              }
              if (level === "G") {
                setLevel("H");
              }
              if (level === "H") {
                setLevel("I");
              }
              if (level === "I") {
                setLevel("J");
              }
              if (level === "J") {
                setLevel("K");
              }
              if (level === "K") {
                setLevel("L");
              }
              if (level === "L") {
                setLevel("M");
              }
              if (level === "M") {
                setLevel("N");
              }
              if (level === "N") {
                setLevel("O");
              }
              if (level === "O") {
                setLevel("P");
              }
              if (level === "P") {
                setLevel("Q");
              }
              if (level === "Q") {
                setLevel("R");
              }
              if (level === "R") {
                setLevel("S");
              }
              if (level === "S") {
                setLevel("T");
              }
              if (level === "T") {
                setLevel("U");
              }
              if (level === "U") {
                setLevel("V");
              }
              if (level === "V") {
                setLevel("W");
              }
              if (level === "W") {
                setLevel("X");
              }
              if (level === "X") {
                setLevel("Y");
              }
              if (level === "Y") {
                setLevel("Z");
              }
              if (level === "Z") {
                setLevel("Z1");
              }
              if (level === "Z1") {
                setLevel("Z2");
              }
              if (level === "Z2") {
                setLevel("A");
              }
            } else {
              setStep(step + 1);
            }
          }}
        >
          Next
        </button>
        <textArea
          value={list}
          onChange={(e) => {
            setList(e.target.value);
          }}
        ></textArea>
      </div>

      <div className="books-table">
        {books.map((book, i) => (
          <AudioBookBox key={book.bookId} book={book} i={i} list={list} />
        ))}
      </div>
    </div>
  );
};
