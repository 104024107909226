import { useEffect, useState } from "react";
import {
  createGamePass,
  getGamePasses,
} from "../../firebase-functions/database";
import { toast } from "react-toastify";
import {
  createRandomIntegerArray,
  decodeHash,
  encodeHash,
} from "../../js/decodeHash";
import { UniqueIconById } from "../auth/Password";

export const GamePasses = (props) => {
  const [tab, setTab] = useState("list");
  const [type, setType] = useState();
  const [number, setNumber] = useState();
  const [enterpriseName, setEnterPriseName] = useState();
  const [enterpriseNumber, setEnterPriseNumber] = useState(10);
  const [viewType, setViewType] = useState("single");
  const [viewLimit, setViewLimit] = useState(5);
  const [gamePasses, setGamePasses] = useState([]);

  const handleCretePass = () => {
    if (type === "enterprise") {
      const array = createRandomIntegerArray(5, 0, 24, true);
      const pass = encodeHash(array);
      createGamePass(
        pass,
        {
          competition: props.competition.id,
          createdAt: new Date(),
          type: "enterprise",
          company: enterpriseName,
          amount: enterpriseNumber,
        },

        props.userInfo
      ).then((res) => {
        if (res.success) {
          toast.success("Pass " + enterpriseName + " Created Successfully");
        } else {
          toast.error(res.message);
        }
      });
    } else {
      const repeat = type === "single" ? 1 : number;

      for (let i = 0; i < repeat; i++) {
        const array = createRandomIntegerArray(5, 0, 24, true);
        const pass = encodeHash(array);
        createGamePass(
          pass,
          {
            competition: props.competition.id,
            createdAt: new Date(),
            type: "single",
          },

          props.userInfo
        ).then((res) => {
          if (res.success) {
            toast.success("Pass Created Successfully");
          } else {
            toast.error(res.message);
          }
        });
      }
      setTab("list");
    }
  };

  useEffect(() => {
    if (props.competition.id) {
      getGamePasses(
        props.competition.id,
        viewType,
        viewLimit,
        props.userInfo
      ).then((res) => {
        if (res.success) {
          setGamePasses(res.message);
        } else {
          toast.error(res.message);
        }
      });
    }
  }, [viewLimit, viewType, props.competition.id]);
  return (
    <div className="game-pass-tab-container">
      <div className="form-group-row form-group-header-create-pass">
        <div className="form-group">
          <button
            className="btn-create-pass"
            onClick={() => {
              setTab("create");
            }}
          >
            Create
          </button>
        </div>
        <div className="form-group">
          <h3>Game Passes</h3>
        </div>
        <div className="div-pass-list-anchor">
          <div className="div-pass-list-filter-type">
            <button
              className={
                viewType === "single"
                  ? "div-pass-list-filter-type div-pass-list-filter-type-active"
                  : "div-pass-list-filter-type"
              }
              onClick={() => {
                setViewType("single");
              }}
            >
              Individual
            </button>
            <button
              className={
                viewType === "enterprise"
                  ? "div-pass-list-filter-type div-pass-list-filter-type-active"
                  : "div-pass-list-filter-type"
              }
              onClick={() => {
                setViewType("enterprise");
              }}
            >
              Enterprise
            </button>
          </div>
          <select
            name="limit"
            id="limit"
            value={viewLimit}
            className="div-pass-list-select"
            onChange={(e) => setViewLimit(e.target.value)}
          >
            <option value={1}>1</option>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
          </select>
        </div>
      </div>
      {tab === "create" && (
        <div className="div-create-game-pass">
          <div className="form-group">
            <h3>Create Game Pass</h3>
          </div>
          <div className="form-group game-pass-type-container">
            <div className=" game-pass-type">
              <input
                type="radio"
                name="game-pass-type"
                value="single"
                onChange={(e) => {
                  setType("single");
                }}
              />
              <p>Single</p>
            </div>
            <div className="game-pass-type">
              <input
                type="radio"
                name="game-pass-type"
                value="multiple"
                onChange={(e) => {
                  setType("multiple");
                }}
              />
              <p>Multiple</p>
            </div>
            <div className="game-pass-type">
              <input
                type="radio"
                name="game-pass-type"
                value="enterprise"
                onChange={(e) => {
                  setType("enterprise");
                }}
              />
              <p>Enterprise</p>
            </div>
          </div>
          {type === "multiple" && (
            <div className="form-group">
              <input
                type="number"
                value={number}
                onChange={(e) => setNumber(parseInt(e.target.value))}
              />
            </div>
          )}

          {type === "enterprise" && (
            <div className="form-group">
              <div className="form-group">
                <label htmlFor="enertprise name">Name</label>
                <input
                  type="text"
                  value={enterpriseName}
                  onChange={(e) => setEnterPriseName(e.target.value)}
                />
              </div>

              <div className="form-group">
                <label htmlFor="enertprise name">Amount</label>
                <input
                  type="text"
                  value={enterpriseNumber}
                  onChange={(e) =>
                    setEnterPriseNumber(parseInt(e.target.value) || 0)
                  }
                />
              </div>
            </div>
          )}
          <div className="form-group-create-pass-save">
            <button
              className="btn-create-pass-save btn-create-pass-cancel"
              onClick={() => {
                setTab("list");
              }}
            >
              Cancel
            </button>
            <button className="btn-create-pass-save" onClick={handleCretePass}>
              Create
            </button>
          </div>
        </div>
      )}

      <div className="passes-list-container">
        {gamePasses.map((p) =>
          p.type === "enterprise" ? (
            <div className="pass-grid-box-enterprise">
              <h3 style={{ textAlign: "center" }}>{p.company}</h3>
              <div className="pass-grid-box" key={p.id}>
                {decodeHash(p.id).map((i) => (
                  <UniqueIconById id={i} key={p.id + "-" + i} />
                ))}
              </div>
            </div>
          ) : (
            <div className="pass-grid-box" key={p.id}>
              {decodeHash(p.id).map((i) => (
                <UniqueIconById id={i} key={p.id + "-" + i} />
              ))}
            </div>
          )
        )}
      </div>
    </div>
  );
};
