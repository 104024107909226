import { useState } from "react";
import {
  createDocument,
  updateDocument,
} from "../../../firebase-functions/database";
import { toast } from "react-toastify";
import { auth } from "../../../firebase-functions/config";
import { RoomIcons } from "./RoomIcons";

export const StepTwo = (props) => {
  const [name, setName] = useState();
  const [color, setColor] = useState();
  const [pet, setPet] = useState();
  const [car, setCar] = useState();
  // const [grade, setGrade] = useState("");
  // const [lineId, setLineId] = useState("");

  const handleCreateStudent = async (e) => {
    e.preventDefault();
    if (!pet) {
      toast.error("Please choose a pet");
      return;
    }
    if (!car) {
      toast.error("Please choose a vehicle");
      return;
    }
    if (!color) {
      toast.error("Please choose a color");
      return;
    }
    createDocument("students", props.userData.uid, {
      name: name,
      // grade: grade,
      // lineId: lineId,
      robux: 0,
      read: 0,
      quiz: 0,
      perfectScore: 0,
      readHistory: [],
      color: color,
      car: car,
      pet: pet,
    }).then((res2) => {
      if (res2.success) {
        const toastId = "res2";
        if (!toast.isActive(toastId)) {
          toast.success(res2.message, {
            toastId: toastId,
          });
        }
        window.location.reload();
      } else {
        toast.error(res2.message);
      }
    });

    updateDocument(
      "users",
      auth.currentUser.uid,
      {
        role: "student",
        updatedAt: new Date(),
      },
      auth.currentUser
    );
  };

  const handleClick = (tag, val) => {
    if (tag === "pet") {
      if (!pet) {
        setPet(val);
      } else {
        setPet();
      }
    } else if (tag === "car") {
      if (!car) {
        setCar(val);
      } else {
        setCar();
      }
    } else if (tag === "color") {
      if (!color) {
        setColor(val);
      } else {
        setColor();
      }
    }
  };

  return (
    <div className="step active">
      <h2>Student Information</h2>
      {/* Form for collecting student information */}
      <form onSubmit={handleCreateStudent} id="infoForm">
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="name"
            id="name"
            placeholder="Enter your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <h2>Remember these 3. You need them to log in</h2>
        <RoomIcons
          pet={pet}
          car={car}
          color={color}
          handleClick={handleClick}
        />
        {/* <div className="form-group">
          <label htmlFor="lineId">Line Id:</label>
          <input
            type="lineId"
            id="lineId"
            placeholder="Enter your Line Id"
            value={lineId}
            onChange={(e) => setLineId(e.target.value)}
            required
          />
        </div> */}
        {/* <div className="form-group">
          <label htmlFor="grade">Grade:</label>
          <select
            name="grade"
            value={grade}
            onChange={(e) => setGrade(e.target.value)}
            className="dropdown-full"
          >
            <option value="k-1">K1</option>
            <option value="k-2">K2</option>
            <option value="k-3">K3</option>
            <option value="p-1">P1</option>
            <option value="p-2">P2</option>
            <option value="p-3">P3</option>
            <option value="p-4">P4</option>
            <option value="p-5">P5</option>
            <option value="p-6">P6</option>
            <option value="m-1">M1</option>
            <option value="m-2">M2</option>
            <option value="m-3">M3</option>
            <option value="m-4">M4</option>
            <option value="m-5">M5</option>
            <option value="m-6">M6</option>
          </select>
        </div> */}

        <button type="submit" className="button">
          Next
        </button>
      </form>
    </div>
  );
};
