import { Books } from "./Books";
import { Landing } from "./Landing";
import { Message } from "./Message";

import "../../style/tribute.css";
import { WordCloudPage } from "./WordCloudPage";
import { TributeProfile } from "./TributeProfile";
import { NextPageButton } from "./NextPageButton";
import { useEffect, useState } from "react";
import { getDocument } from "../../firebase-functions/database";
import { toast, ToastContainer } from "react-toastify";
import { HallOfFame } from "./HallOfFame";
import { useLocation } from "react-router-dom";

export const Tribute = () => {
  const [studentInfo, setStudentInfo] = useState();
  const [trigger, setTrigger] = useState(false);
  let toastShown = false;
  const location = useLocation();

  useEffect(() => {
    const localName = window.location.pathname.split("/")[2];
    const name = localName.charAt(0).toUpperCase() + localName.substring(1);

    window.scrollTo({ top: 0, behavior: "smooth" });

    if (name) {
      getDocument("tributes", name).then((res) => {
        if (res.success) {
          setStudentInfo(res.data);
        } else {
          if (!toastShown) {
            toast.error(res.message);
            toastShown = true;
          }
        }
      });
    }
  }, [location]);
  return (
    <div className="container tribute-container ">
      {studentInfo && (
        <>
          <Landing name={studentInfo.name} words={studentInfo.wordLength} />
          <Message
            header={"Thank you for joining BookBattle."}
            subtext={
              " It was fun watching you hit awesome milestones. \nThis is a simple website we put together to honor your achievements"
            }
            bgColor="white"
          />
          <TributeProfile
            readerProfile={studentInfo.readerProfile}
            name={studentInfo.name}
            minutes={studentInfo.minutes}
          />
          <Books
            readHistory={studentInfo.readHistory}
            reads={studentInfo.readerProfile.read}
          />
          <Message
            header={`We noticed that you love reading  ${
              studentInfo?.tags?.fiction > studentInfo?.tags?.nonfiction
                ? "Fiction"
                : "Non-Fiction"
            }`}
            subtext={`${studentInfo?.tags?.fiction || 0}/${
              studentInfo?.readerProfile.read
            } of your reads were fiction, ${
              studentInfo?.tags?.nonfiction || 0
            } were nonfiction. We hope you continue reading more books.`}
            bgColor="white"
          />

          <WordCloudPage wordList={studentInfo.wordList} />
          {studentInfo?.youtube ? (
            <iframe
              width={window.screen.width * 0.9}
              height={window.screen.width * 0.562 * 0.9}
              src={
                "https://www.youtube.com/embed/" +
                studentInfo?.youtube.split("youtu.be/")[1]
              }
              style={{
                marginLeft: "5vw",
              }}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          ) : (
            <div>
              <p>No Vieo Available</p>
            </div>
          )}
          <HallOfFame trigger={trigger} setTrigger={setTrigger} />
          <NextPageButton />
        </>
      )}
    </div>
  );
};
