export const DownloadIOS = () => {
  return (
    <div className="download-container">
      <h2>Add Book Battle to Home Screen</h2>
      <sub>
        Unfortunately, our app is not available for iOS yet. You can, however
        add it to your home screen by following the instructions in the tutorial
        below.
      </sub>
      <iframe
        style={{ marginTop: "2em" }}
        width={window.screen.width * 0.85}
        height={window.screen.width * 0.45}
        src="https://www.youtube.com/embed/I4e1aoi0P-o?si=KBwYjlTfKmEF8iux"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </div>
  );
};
