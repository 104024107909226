import { useEffect, useState } from "react";
import { storage } from "../../firebase-functions/config";
import { getDownloadURL, ref } from "firebase/storage";

export const Apk = () => {
  const [downloading, setDownloading] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [downloadError, setDownloadError] = useState(null);

  const handleDownload = async () => {
    if (!downloading) {
      setDownloading(true);
      setDownloadError(null); // Clear any previous errors

      try {
        const url = await getDownloadURL(ref(storage, "apk/BookBattle.apk"));
        setDownloadUrl(url);
      } catch (error) {
        setDownloadError(error.message);
      } finally {
        setDownloading(false);
      }
    }
  };

  return (
    <div style={{ width: "80vw", margin: "auto", marginTop: "5em" }}>
      <h2 style={{ textAlign: "center" }}>Download the APK file</h2>
      <p>
        <strong>Disclaimer: </strong>Since it takes a bit time to register at
        Play store and AppStore, the download link is from our database.
        Download at your own risk
      </p>
      {downloadError && <p style={{ color: "red" }}>Error: {downloadError}</p>}
      <button
        onClick={handleDownload}
        disabled={downloading}
        style={{
          width: "20vw",
          marginLeft: "30vw",
          marginTop: "3em",
          minWidth: "fit-content",
        }}
      >
        {downloading ? "Downloading..." : "Download"}
      </button>
      {downloadUrl && (
        <a href={downloadUrl} download="BookBattle.apk">
          Download Completed (Click to Install)
        </a>
      )}
    </div>
  );
};
