import { useEffect, useState } from "react";
import { getTributes } from "../../firebase-functions/database";
import { Link } from "react-router-dom";
import "../../style/tribute.css";

export const HallOfFame = (props) => {
  const [tributes, setTributes] = useState([]);
  const leagues = [1, 2, 3, 4];
  useEffect(() => {
    getTributes("pilot").then((res) => {
      if (res.success) {
        setTributes(res.message);
      } else {
        console.error(res.message);
      }
    });
  }, []);
  return (
    <div className="hof-container">
      <h1>Hall Of Fame</h1>
      <div className="leagues">
        {leagues.map((lg, ind) => {
          return (
            <ol key={ind}>
              <h5>League {lg}</h5>
              {tributes
                ?.filter(
                  (tribute) =>
                    tribute.readerProfile.league == lg &&
                    tribute.readerProfile.rank <= 5
                )
                ?.sort((a, b) => a.readerProfile.rank - b.readerProfile.rank)
                ?.map((tribute, index) => {
                  return (
                    <li key={index}>
                      <Link
                        to={`/tribute/${tribute.name}`}
                        onClick={() => {
                          if (props?.setTrigger) {
                            props?.setTrigger(props?.trigger);
                          }
                        }}
                      >
                        {tribute.name}
                      </Link>
                    </li>
                  );
                })}
            </ol>
          );
        })}
      </div>
    </div>
  );
};
