import { useEffect, useState } from "react";
import { DemoBook } from "./DemoBook";
import library from "../../../assets/json/library.json";
import "../../../style/readingRoom.css";
import { FaLevelUpAlt, FaLevelDownAlt, FaDice } from "react-icons/fa";
import { IoHeadsetSharp, IoBook } from "react-icons/io5";
import { DemoQuiz } from "./DemoQuiz";
import sampleBooks from "../../../assets/json/sampleBooks.json";

const DemoBookCard = (props) => {
  const setIsQuiz = props.setIsQuiz;
  const bk = props.bk;
  const [info, setInfo] = useState();
  const setOpenBookListen = props.setOpenBookListen;
  const setActiveBook = props.setActiveBook;

  const handleNavigatePage = (level, inc) => {
    const levels = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "Z1",
      "Z2",
    ];

    const newLevel =
      inc === 1
        ? levels[levels.indexOf(level) + 1]
        : inc === -1
        ? levels[levels.indexOf(level) - 1]
        : levels[levels.indexOf(level)];

    const links = sampleBooks[newLevel];
    const newLink = links[Math.floor(Math.random() * links.length)];

    window.location.replace(newLink);
  };
  return (
    <div className="guest-reading-room-info-container">
      <div
        className={
          !info
            ? "book-container"
            : bk.orientation === "portrait"
            ? "book-container book-container-active"
            : "book-container book-container-active book-container-active-landscape"
        }
        index={bk?.id}
      >
        <img
          className={
            bk.orientation === "portrait"
              ? "book-thumbnail"
              : "book-thumbnail book-thumbnail-landscape"
          }
          src={`
https://mi.content.readinga-z.com/raz_book_image/${bk.id}/projectable/small/1/book/page-0.jpg`}
        />
        <div className="extra-book-info-div">
          <p className="book-title">{bk.title}</p>
          <div className="div-tags">
            {bk.tags.map((tag) => (
              <p className="book-tag" key={tag}>
                {" "}
                {tag}
              </p>
            ))}

            <p className="book-tag">{bk.wordLength} words</p>
          </div>
          {!props.doneReading ? (
            <p
              className="summary"
              dangerouslySetInnerHTML={{ __html: bk.summary }}
            ></p>
          ) : (
            <div>
              <p> Speed: {props.wpm} words per minute</p>
              <p>
                Duration: {Math.floor(props.readingDuration / 1000)} seconds
              </p>
              {props.score && <p>Score: {props.score}</p>}
              {props.stars && <p>Stars: {props.stars}</p>}
            </div>
          )}
          {!props.score && (
            <div className="div-info-active-buttons">
              <button
                onClick={() => {
                  setOpenBookListen(false);
                  setActiveBook(bk);
                }}
              >
                Read
                <IoBook />
              </button>
              {bk.audio && (
                <button
                  onClick={() => {
                    setOpenBookListen(true);
                    setActiveBook(bk);
                  }}
                >
                  Listen
                  <IoHeadsetSharp />
                </button>
              )}

              {props.doneReading && (
                <button
                  onClick={() => {
                    setIsQuiz(true);
                    setActiveBook(bk);
                  }}
                >
                  Quiz
                </button>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="div-navigate-to-other-books">
        <h2>Pick Another Book</h2>
        <div className="div-buttons">
          <button
            onClick={() => {
              handleNavigatePage(bk.level, -1);
            }}
          >
            {" "}
            <FaLevelDownAlt /> Easier
          </button>
          <button
            onClick={() => {
              handleNavigatePage(bk.level, 0);
            }}
          >
            {" "}
            <FaDice /> Similar
          </button>
          <button
            onClick={() => {
              handleNavigatePage(bk.level, 1);
            }}
          >
            {" "}
            <FaLevelUpAlt /> Harder
          </button>
        </div>
      </div>
    </div>
  );
};

export const GuestReadingRoom = () => {
  const [bookId, setBookId] = useState();
  const [activeBook, setActiveBook] = useState();
  const [openBookListen, setOpenBookListen] = useState(false);
  const [doneReading, setDoneReading] = useState(false);
  const [isQuiz, setIsQuiz] = useState(false);
  const [readingTime, setReadingTime] = useState();
  const [wpm, setWpm] = useState();
  const [readingDuration, setReadingDuration] = useState(0);
  const [bookInfo, setBookInfo] = useState();
  const [score, setScore] = useState();
  const [stars, setStars] = useState();

  useEffect(() => {
    if (window.location.pathname.split("/")[2]) {
      const str = window.location.pathname.split("/")[2];
      const fl = parseInt(str, 36) - 17;

      if (
        parseInt(fl / process.env.REACT_APP_SALT) ===
        fl / process.env.REACT_APP_SALT
      ) {
        setBookId(fl / process.env.REACT_APP_SALT);
      } else {
        setBookId("NON");
      }
    }
  }, []);

  return (
    <div>
      {bookId ? (
        activeBook && !doneReading ? (
          <DemoBook
            bookId={bookId}
            setDoneReading={setDoneReading}
            doneReading={doneReading}
            isQuiz={isQuiz}
            setIsQuiz={setIsQuiz}
            setReadingTime={setReadingTime}
            setWpm={setWpm}
            setReadingDuration={setReadingDuration}
            readingTime={readingTime}
            wpm={wpm}
            readingDuration={readingDuration}
            bookInfo={bookInfo}
            setBookInfo={setBookInfo}
            activeBook={activeBook}
            openBookListen={openBookListen}
          />
        ) : isQuiz ? (
          <DemoQuiz
            bookInfo={bookInfo}
            readingTime={readingDuration}
            wpm={wpm}
            score={score}
            setScore={setScore}
            setIsQuiz={setIsQuiz}
            setStars={setStars}
          />
        ) : (
          <DemoBookCard
            setActiveBook={setActiveBook}
            bk={library.find((bk) => bk.id === bookId)}
            setOpenBookListen={setOpenBookListen}
            doneReading={doneReading}
            setIsQuiz={setIsQuiz}
            readingTime={readingTime}
            wpm={wpm}
            readingDuration={readingDuration}
            score={score}
            stars={stars}
          />
        )
      ) : (
        <h2>Please enter a book Id</h2>
      )}{" "}
    </div>
  );
};
