import { PiDogFill } from "react-icons/pi";
import { IoLogoOctocat } from "react-icons/io5";
import { GiParrotHead } from "react-icons/gi";
import { GiTigerHead } from "react-icons/gi";

import { GiKoala } from "react-icons/gi";

import { RiMotorbikeFill } from "react-icons/ri";
import { FaTruckMonster } from "react-icons/fa6";
import { GiSteamLocomotive } from "react-icons/gi";
import { GoRocket } from "react-icons/go";
import { GiMiniSubmarine } from "react-icons/gi";

import { RiRestartLine } from "react-icons/ri";

import { useEffect, useState } from "react";
import { getAllFilteredUsers } from "../../firebase-functions/database";

import { IoTriangleSharp } from "react-icons/io5";
import { FaSquare } from "react-icons/fa";
import { FaCircle } from "react-icons/fa";
import { FaPlusCircle } from "react-icons/fa";
import { PiDivideFill } from "react-icons/pi";
import { FaMinusCircle } from "react-icons/fa";
import { ImCross } from "react-icons/im";

import { TbMedicalCrossFilled } from "react-icons/tb";
import { AiFillHeart } from "react-icons/ai";
import { GoStarFill } from "react-icons/go";
import { AiFillMoon } from "react-icons/ai";
import { BsDropletFill } from "react-icons/bs";
import { FaShieldAlt } from "react-icons/fa";

export const UserBox = (props) => {
  const [step, setStep] = useState(1);
  const [pet, setPet] = useState();
  const [car, setCar] = useState();
  const [color, setColor] = useState();
  const [userNames, setUserNames] = useState([]);

  const userNameIcons = [
    <IoTriangleSharp />,
    <FaSquare />,
    <FaCircle />,
    <FaPlusCircle />,
    <PiDivideFill />,
    <FaMinusCircle />,
    <ImCross />,
    <TbMedicalCrossFilled />,
    <AiFillHeart />,
    <GoStarFill />,
    <BsDropletFill />,
    <FaShieldAlt />,
  ];
  const handleClick = (category, val) => {
    setStep(step + 1);
    if (category === "pet") {
      setPet(val);
    }
    if (category === "car") {
      setCar(val);
    }
    if (category === "color") {
      setColor(val);
    }
  };

  useEffect(() => {
    if (step === 4) {
      getAllFilteredUsers(pet, car, color).then((res) => {
        setUserNames(res.message);
        // for (let i = 0; i < 3; i++) {
        //   res.message.map((us) => {
        //     setUserNames((oldList) => [...oldList, us]);
        //   });
        // }
      });
    }
  }, [step]);

  return (
    <div className="user-box-container">
      {step === 1 && (
        <div className="username-icon-box-container">
          <h3>Choose your pet</h3>

          <div className="username-icon-box">
            <PiDogFill
              onClick={() => {
                handleClick("pet", 1);
              }}
            />
            <IoLogoOctocat
              onClick={() => {
                handleClick("pet", 2);
              }}
            />
            <GiParrotHead
              onClick={() => {
                handleClick("pet", 3);
              }}
            />
            <GiTigerHead
              onClick={() => {
                handleClick("pet", 4);
              }}
            />
            <GiKoala
              onClick={() => {
                handleClick("pet", 5);
              }}
            />
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="username-icon-box-container">
          <h3>Choose your vehicle</h3>

          <div className="username-icon-box">
            <RiMotorbikeFill
              onClick={() => {
                handleClick("car", 1);
              }}
            />
            <FaTruckMonster
              onClick={() => {
                handleClick("car", 2);
              }}
            />
            <GiSteamLocomotive
              onClick={() => {
                handleClick("car", 3);
              }}
            />
            <GoRocket
              onClick={() => {
                handleClick("car", 4);
              }}
            />

            <GiMiniSubmarine
              onClick={() => {
                handleClick("car", 5);
              }}
            />
          </div>
        </div>
      )}
      {step === 3 && (
        <div className="username-icon-box-container">
          <h3>Choose your color</h3>

          <div className="username-icon-box">
            <div
              className="username-icon-box-color"
              style={{
                background: `linear-gradient(to right, #ff6666 0%, #dd0000 100%)`,
              }}
              onClick={() => {
                handleClick("color", 1);
              }}
            >
              &nbsp;{" "}
            </div>
            <div
              className="username-icon-box-color"
              style={{
                background: `linear-gradient(to right, #0000FF 0%, #1E90FF 100%)`,
              }}
              onClick={() => {
                handleClick("color", 2);
              }}
            >
              &nbsp;{" "}
            </div>
            <div
              className="username-icon-box-color"
              style={{
                background: `linear-gradient(to right, #A2FFFF 0%, #40E0D0 100%)`,
              }}
              onClick={() => {
                handleClick("color", 3);
              }}
            >
              &nbsp;{" "}
            </div>
            <div
              className="username-icon-box-color"
              style={{
                background: `linear-gradient(to right, #FF69B4 0%, #FF1493 100%)`,
              }}
              onClick={() => {
                handleClick("color", 4);
              }}
            >
              &nbsp;{" "}
            </div>
            <div
              className="username-icon-box-color"
              style={{
                background: `linear-gradient(to right, #FFDAB9 0%, #FFA07A 100%)`,
              }}
              onClick={() => {
                handleClick("color", 5);
              }}
            >
              &nbsp;{" "}
            </div>
          </div>
        </div>
      )}

      {step === 4 && (
        <div className="username-icon-box-container">
          {userNames && (
            <div className="username-icons">
              {userNames.map((us, index) => (
                <div
                  className={
                    props.email === us.email
                      ? "username-icon-user-box username-icon-user-box-active"
                      : "username-icon-user-box"
                  }
                  key={us.email}
                >
                  <div
                    className="username-icon-icon-box"
                    onClick={() => {
                      props.setEmail(us.email);
                    }}
                  >
                    {userNameIcons[index]}
                  </div>
                  <p className="username-icon-name">{us.name}</p>
                </div>
              ))}
            </div>
          )}
          {step === 4 && (
            <button
              className="username-icon-btn-restart"
              onClick={(e) => {
                e.preventDefault();
                setStep(1);
              }}
            >
              Go back <RiRestartLine />
            </button>
          )}
        </div>
      )}
    </div>
  );
};
