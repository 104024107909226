import { Link } from "react-router-dom";

import "../../style/teams.css";
export const NavbarTeams = (props) => {
  const pathname = window.location.pathname;
  return (
    <div className="teams-navbar">
      <Link
        className={`teams-navbar-item ${
          pathname === "/teams" ? "teams-navbar-item-active" : ""
        }`}
        to="/teams"
      >
        All teams
      </Link>
      <Link
        className={`teams-navbar-item ${
          pathname === "/teams/my-team" ? "teams-navbar-item-active" : ""
        }`}
        to="/teams/my-team"
      >
        My team
      </Link>
    </div>
  );
};
