import { useEffect, useState } from "react";
import {
  createDocumentWithRandomUid,
  deleteDocument,
  updateDocument,
} from "../../firebase-functions/database";
import { auth } from "../../firebase-functions/config";
import { toast } from "react-toastify";

export const CreateCompetitionForm = (props) => {
  const [name, setName] = useState();
  const [startDate, setStartDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
  const [priceCambodia, setPriceCambodia] = useState(5);
  const [priceThailand, setPriceThailand] = useState(120);

  const competition = props?.competition;
  const [status, setStatus] = useState();
  const [toggleUpdate, setUpdate] = useState(false);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState();

  const handleSubmit = () => {
    if (!competition) {
      createDocumentWithRandomUid(
        "competitions",
        {
          fee: {
            Cambodia: priceCambodia,
            Thailand: priceThailand,
          },
          leagues: ["league-1", "league-2", "league-3", "league-4"],
          name: name,
          start_date: startDate,
          end_date: endDate,
          status: "new",
        },
        auth.currentUser
      ).then((res) => {
        if (res.success) {
          toast.success("competition created successfully");
        } else {
          toast.error(res.message);
        }
      });
    } else {
      updateDocument(
        "competitions",
        competition.id,
        {
          fee: {
            Cambodia: priceCambodia,
            Thailand: priceThailand,
          },
          name: name,
          start_date: startDate,
          end_date: endDate,
          status: status,
        },
        auth.currentUser
      ).then((res) => {
        if (res.success) {
          toast.success("Updated successfully");
        } else {
          toast.error(res.message);
        }
      });
    }
  };

  const handleDelete = () => {
    competition &&
      deleteMessage === "delete" &&
      deleteDocument("competitions", competition.id, auth.currentUser).then(
        (res) => {
          if (res.success) {
            toast.success(res.message);
          } else {
            toast.error(res.message);
          }
        }
      );
  };
  useEffect(() => {
    if (competition) {
      setName(competition.name);
      setStartDate(competition.start_date);
      setEndDate(competition.end_date);
      setPriceCambodia(competition?.fee?.Cambodia);
      setPriceThailand(competition?.fee?.Thailand);
      setStatus(competition.status);
    }
  }, [competition]);

  useEffect(() => {
    if (competition) {
      const similar =
        name === competition.name &&
        startDate === competition.start_date &&
        endDate === competition.end_date &&
        priceCambodia === competition?.fee?.Cambodia &&
        priceThailand === competition?.fee?.Thailand &&
        status === competition.status;

      setUpdate(!similar);
    }
  }, [name, startDate, endDate, priceCambodia, priceThailand, status]);
  return (
    <form
      className="create-competition-container"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <h1>{competition ? competition.name : "Create a new Competition"} </h1>

      {competition ? (
        <div className="form-group-row">
          <div className="form-group">
            <label htmlFor="competition-name">Competition Name</label>
            <input
              type="text"
              name="competition-name"
              value={name}
              required
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="competition-status">Competition Status</label>

            <select
              name="competition-status"
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <option value="new">New</option>
              <option value="in progress">In Progress</option>
              <option value="completed">Completed</option>
            </select>
          </div>
        </div>
      ) : (
        <div className="form-group">
          <label htmlFor="competition-name">Competition Name</label>
          <input
            type="text"
            name="competition-name"
            value={name}
            required
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
      )}
      <div className="form-group-row">
        <div className="form-group">
          <label htmlFor="starting-date">Starts on</label>
          <input
            aria-label="Date"
            type="date"
            value={startDate}
            required
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="ending-date">Ends on</label>
          <input
            aria-label="Date"
            type="date"
            value={endDate}
            required
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="form-group-row">
        <div className="form-group">
          <label htmlFor="Price Cambodia">Price Cambodia</label>
          <input
            type="number"
            name="Price Cambodia"
            value={priceCambodia}
            required
            onChange={(e) => {
              setPriceCambodia(e.target.value);
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="Price Thailand">Price Thailand</label>
          <input
            type="number"
            name="Price Thailand"
            value={priceThailand}
            required
            onChange={(e) => {
              setPriceThailand(e.target.value);
            }}
          />
        </div>
      </div>
      {!competition && (
        <div className="form-group">
          <button type="submit">Create</button>
        </div>
      )}

      {competition && (
        <div className="form-group">
          <button
            style={{ bgColor: "red" }}
            onClick={(e) => {
              e.preventDefault();
              setToggleDelete(true);
            }}
          >
            {" "}
            delete
          </button>
          {toggleDelete && (
            <div className="form-group">
              <input
                type="text"
                name="deleteMessage"
                id=""
                value={deleteMessage}
                onChange={(e) => {
                  setDeleteMessage(e.target.value);
                }}
              />
            </div>
          )}
          {deleteMessage === "delete" && (
            <button
              onClick={(e) => {
                e.preventDefault();
                handleDelete();
              }}
              style={{ bgColor: "red", color: "white" }}
            >
              delete
            </button>
          )}
        </div>
      )}
      {toggleUpdate && (
        <div className="form-group">
          <button type="submit">Update</button>
        </div>
      )}
    </form>
  );
};
