import { useEffect, useState } from "react";
import { getDocument } from "../../firebase-functions/database";
import { toast } from "react-toastify";
import "../../style/countdown.css";

export const CountDown = (props) => {
  const [competitionData, setCompetitonData] = useState();

  const [day, setDay] = useState(0);
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [second, setSecond] = useState(0);

  useEffect(() => {
    const compId = props.competition?.id;

    if (compId) {
      getDocument("competitions", compId).then((res) => {
        if (res.success) {
          setCompetitonData(res.data);
        } else {
          toast.error("couldn't load competition information");
        }
      });
    }
  }, [props]);

  const DateCounter = () => {
    let date = new Date();
    if (competitionData) {
      if (competitionData.status === "new") {
        date = new Date(competitionData.start_date);
      } else if (competitionData.status === "in progress") {
        date = new Date(competitionData.end_date);
      }
    }

    setInterval(() => {
      const currentDate = new Date();

      let remainingTime = date - currentDate;

      setDay(Math.floor(remainingTime / (1000 * 60 * 60 * 24)));
      setHour(
        Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      );
      setMinute(Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60)));
      setSecond(Math.floor((remainingTime % (1000 * 60)) / 1000));
    }, 1000);

    return (
      <div className="countdown-table">
        <div className="countdown-box">
          <div className="coundown-figure">
            {day < 10 ? "0" : ""}
            {day}
          </div>
          <div className="countdown-title">days</div>
        </div>

        <div className="countdown-box">
          <div className="coundown-figure">
            {hour < 10 ? "0" : ""}
            {hour}
          </div>
          <div className="countdown-title">hours</div>
        </div>

        <div className="countdown-box">
          <div className="coundown-figure">
            {minute < 10 ? "0" : ""}
            {minute}
          </div>
          <div className="countdown-title">minutes</div>
        </div>

        <div className="countdown-box">
          <div className="coundown-figure">
            {second < 10 ? "0" : ""}
            {second}
          </div>
          <div className="countdown-title">seconds</div>
        </div>
      </div>
    );
  };

  return (
    <div className="countdown-container">
      {competitionData && competitionData.status === "new" && (
        <h2>Competition Starts In</h2>
      )}

      {competitionData && competitionData.status === "in progress" && (
        <h2>Competition Ends In</h2>
      )}
      {competitionData && <DateCounter />}
    </div>
  );
};
