import { useEffect, useState } from "react";

import { uploadPhoto } from "../../../firebase-functions/storage";
import { SearchableDropdown } from "../../utils/SearchableDropdown";
import {
  createDocumentWithRandomUid,
  getDefaultCompetition,
  getEveryOtherStudent,
  updateDocument,
} from "../../../firebase-functions/database";
import { auth } from "../../../firebase-functions/config";
import { toast } from "react-toastify";

import ABAQR from "../../../assets/img/payment/photo_2024-03-10_11-01-13.jpg";

export const StepThree = (props) => {
  const [lead, setLead] = useState();
  const [leadStudent, setLeadStudent] = useState("Select option...");
  const [failedLeadStudent, setFailedLeadStudent] = useState();
  const [otherLead, setOtherLead] = useState();

  const [country, setCountry] = useState("Thailand");
  const [paymentSlip, setPaymentSlip] = useState();
  const [paymentSlipURL, setPaymentSlipURL] = useState();
  const [students, setStudents] = useState([]);
  const [defaultCompetition, setDefaultCompetition] = useState();
  const [competitionId, setCompetitionId] = useState();
  const [fee, setFee] = useState(250);

  const paymentData = props.paymentData;

  useEffect(() => {
    getDefaultCompetition().then((res) => {
      if (res.success) {
        setDefaultCompetition(res?.message);
      } else {
        toast.error(res.message);
      }

      if (paymentData) {
        setFee(paymentData.fee || 999);
        setLead(paymentData.lead);
        setFailedLeadStudent(paymentData.leadStudent);
        setCompetitionId(paymentData.competition);

        setCountry(paymentData.country);
      }
    });
  }, [paymentData]);

  useEffect(() => {
    setFee(defaultCompetition?.data?.fee[country]);
  }, [country, defaultCompetition]);

  const handleSubmitPayment = async (e) => {
    e.preventDefault();

    if (!paymentSlip) {
      toast.error("please add slip");
      return;
    }

    if (!lead) {
      setLead("");
    }

    uploadPhoto(
      paymentSlip,
      "payment-slips",
      paymentSlip.name,
      auth.currentUser
    ).then((res) => {
      if (res.success) {
        createDocumentWithRandomUid("payments", {
          studentId: auth.currentUser.uid,
          paymentSlip: res.downloadURL,
          demoProfile: props?.readerProfile,
          lead: lead,
          leadStudent: failedLeadStudent
            ? failedLeadStudent
            : leadStudent?.id
            ? leadStudent?.id
            : null,
          status: "new",
          dateCreated: new Date(),
          competition: defaultCompetition.id,
          country: country,
          fee: fee,
        }).then((res2) => {
          setPaymentSlipURL(res.downloadURL);

          if (res2.success) {
            toast.success("Sayment Slip Updated Successfully");
            props.setStep(4);
          } else {
            toast.error("something went wrong");
          }
        });
      }
    });
  };

  useEffect(() => {
    getEveryOtherStudent(auth.currentUser).then((res) => {
      if (res.success) {
        setStudents(res.message);
      } else {
        toast.error(res.message);
      }
    });
  }, []);

  const handleExtendPayment = (e) => {
    e.preventDefault();
    uploadPhoto(
      paymentSlip,
      "extensions",
      paymentSlip.name,
      auth.currentUser
    ).then((res) => {
      const currentDate = new Date();
      const hoursInMilliseconds = 1000 * 60 * 60; // Milliseconds in an hour
      const targetDate = new Date(
        currentDate.getTime() - hoursInMilliseconds * 71
      );

      updateDocument(
        "demoReaders",
        props?.readerProfile?.id,
        {
          dateCreated: targetDate,
          extensions: props.readerProfile?.extensions + 1 || 1,
        },
        auth.currentUser
      ).then((res2) => {
        if (res2.success) {
          toast.success("Done!");
          setTimeout(() => {
            window.location.replace("/");
          }, 1500);
        }
      });
    });
  };
  return (
    <div className="step active ">
      {paymentData ? (
        <div>
          <h2>Something went wrong with your payment</h2>
          <p>please submit again</p>
          <h4 className="payment-note">{paymentData?.note}</h4>
          <img
            src={paymentData.paymentSlip}
            alt="payment slip"
            className="img-payment"
          />
        </div>
      ) : (
        <div>
          <h2>Make Payment</h2>
        </div>
      )}

      <form
        onSubmit={
          window.location.pathname !== "/extend"
            ? handleSubmitPayment
            : handleExtendPayment
        }
        id="paymentForm"
      >
        <div className="make-payment-information-contianer">
          {!paymentData && (
            <>
              {window.location.pathname !== "/extend" && (
                <div className="form-group">
                  <label htmlFor="lead">Where did you hear about us?</label>

                  <select
                    name="lead"
                    value={lead || "google search"}
                    onChange={(e) => setLead(e.target.value)}
                    className="dropdown-full"
                    required
                  >
                    <option value="google search">Google Search</option>
                    <option value="social media ad">Social Media Ad</option>
                    <option value="instagram">Instagram</option>
                    <option value="facebook group">Facebook</option>
                    <option value="tiktok">Tiktok</option>
                    <option value="telegram">Telegram</option>
                    <option value="school poster">School Poster</option>
                    <option value="teacher david">Teacher David</option>
                    <option value="friend">A Friend Told Me</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              )}
              {lead === "friend" && (
                <>
                  <label htmlFor="lead-id">Friend Name</label>
                  <SearchableDropdown
                    options={students || []}
                    label="name"
                    id="id"
                    selectedVal={leadStudent?.name || ""}
                    handleChange={(val) => {
                      setLeadStudent(val);
                    }}
                  />
                </>
              )}

              {lead === "other" && (
                <>
                  <label htmlFor="lead-other">Tell us more</label>
                  <input
                    type="lead-other"
                    value={otherLead}
                    onChange={(e) => setOtherLead(e.target.value)}
                  />
                </>
              )}

              <div className="country-options">
                <button
                  type="button"
                  onClick={() => setCountry("Thailand")}
                  className={`btn-country${
                    country === "Thailand" ? "-active" : ""
                  }`}
                >
                  Thailand
                </button>
                <button
                  type="button"
                  onClick={() => setCountry("Cambodia")}
                  className={`btn-country${
                    country === "Cambodia" ? "-active" : ""
                  }`}
                >
                  Cambodia
                </button>
              </div>
            </>
          )}
          {country === "Thailand" ? (
            <div className="receipt-box">
              <h2 className="info-price">
                {window.location.pathname === "/extend"
                  ? "10 baht"
                  : "250 baht"}
              </h2>

              <p className="bank-name">Bangkok Bank</p>
              <p className="receiver">Davranbek Rozmetov</p>
              <p className="bank-number">037-714-208-8</p>
            </div>
          ) : (
            <div className="receipt-box">
              <h2 className="info-price">
                {window.location.pathname === "/extend" ? "$0.5" : "$10"}
              </h2>
              <p className="bank-name">ABA Bank</p>
              <p className="receiver">Davranbek Rozmetov</p>
              <p className="bank-number">007 - 628 - 162</p>
              <img src={ABAQR} alt="bank account" className="img-aba" />
            </div>
          )}
        </div>

        {paymentSlipURL && (
          <img alt={paymentSlip} className="img-payment" src={paymentSlipURL} />
        )}
        {!paymentSlipURL && (
          <input
            type="file"
            required
            onChange={(e) => {
              setPaymentSlip(e.target.files[0]);
              setPaymentSlipURL(URL.createObjectURL(e.target.files[0]));
            }}
          />
        )}
        <p>
          Note: Please ensure you enter the correct reference to avoid any
          delays in processing your payment.
        </p>
        <button type="submit" className="button">
          Submit Payment Receipt
        </button>
      </form>
    </div>
  );
};
