import { useEffect, useState } from "react";
import { getDocument, getLeaderBoard } from "../../firebase-functions/database";
import "../../style/leaderBoard.css";
import { FaBookOpen, FaRegStarHalfStroke } from "react-icons/fa6";
import { FaHeadphonesAlt } from "react-icons/fa";
import { SiGoogleforms } from "react-icons/si";
import { IoMdRibbon } from "react-icons/io";
import { SiRoblox } from "react-icons/si";
import { onAuthStateChanged } from "firebase/auth";

import { auth } from "../../firebase-functions/config";
import { toast } from "react-toastify";

export const StudentLeaderBoard = (props) => {
  const [leaderBoardData, setLeaderBoardData] = useState([]);

  const [helper, setHelper] = useState();
  const setRank = props.setRank;
  const [leagueInfo, setLeagueInfo] = useState();

  useEffect(() => {
    if (props?.readerProfile?.league && props?.readerProfile?.competition) {
      let tempLeaderBoard = [];

      getLeaderBoard(
        "readers",
        props?.readerProfile?.league,
        props?.readerProfile?.competition
      ).then((res) => {
        res.students.map((st, i) => {
          tempLeaderBoard.push(st);
        });
      });

      getLeaderBoard(
        "demoReaders",
        props?.readerProfile?.league,
        props?.readerProfile?.competition
      ).then((res) => {
        res.students.map((st, i) => {
          tempLeaderBoard.push(st);
          if (st.studentId === props?.readerProfile?.studentId) {
            setRank(tempLeaderBoard.length);
          }
        });
      });

      setLeaderBoardData(tempLeaderBoard);

      getDocument("leagues", props?.readerProfile?.league).then((res) => {
        if (res.success) {
          setLeagueInfo(res.data);
        } else {
          toast.error("Couldn't load league info", res.message);
        }
      });
    }
  }, [props.user, props.readerProfile]);

  useEffect(() => {
    const calculateRank = () => {
      for (let index = 0; index < leaderBoardData.length; index++) {
        const st = leaderBoardData[index];

        if (st?.studentId === auth.currentUser.uid) {
          setRank(index + 1);
          return; // Exit loop once rank is found
        }
      }
    };

    calculateRank();
  }, [leaderBoardData, auth.currentUser.uid]);

  const Reward = (props) => {
    const index = props.index;
    return <div>{leagueInfo?.rewards[index + 1]}</div>;
  };

  return (
    <div className="leaderboard-container">
      {leaderBoardData.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Name</th>
              {/* <th>Team</th> */}
              <th>Stars</th>
              <th>Rewards</th>
            </tr>
          </thead>
          <tbody>
            {leaderBoardData
              .sort((s1, s2) => {
                return s2.stars - s1.stars;
              })
              .map((ld, index) => {
                if (ld.studentId === props?.readerProfile?.studentId) {
                  setRank(index + 1);
                }
                return (
                  <tr
                    key={ld.id}
                    className={
                      ld.studentId === auth.currentUser.uid
                        ? "self-class"
                        : index % 2 === 0
                        ? "table-odd"
                        : "table-even"
                    }
                  >
                    <td>
                      {index + 1}
                      {index === 0 && <IoMdRibbon className="ribbon-gold" />}
                      {index === 1 && <IoMdRibbon className="ribbon-silver" />}
                      {index === 2 && <IoMdRibbon className="ribbon-bronze" />}
                    </td>
                    <td>{ld.name}</td>
                    {/* <td>{ld.teamName}</td> */}
                    <td
                      className="td-stars"
                      onMouseEnter={(e) => {
                        setHelper(index);
                      }}
                      onMouseLeave={(e) => {
                        if (helper === index) setHelper(null);
                      }}
                    >
                      <>
                        <FaRegStarHalfStroke className="star-sign" />
                        {ld.stars}
                      </>
                      <div
                        className={`stars-helper-container ${
                          helper === index ? "stars-helper-visible" : ""
                        }`}
                      >
                        <FaBookOpen />
                        {ld.read} <> </>
                        <FaHeadphonesAlt /> {ld.listen} <></>
                        <SiGoogleforms />
                        {ld.quiz}
                      </div>
                    </td>
                    <td className="row-reward">
                      <SiRoblox className="icon-roblox" />
                      {<Reward index={index} />}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}

      {leaderBoardData.length === 0 && <h3>No data available</h3>}
    </div>
  );
};
