import { useState } from "react";
import { FaRegHandPointDown } from "react-icons/fa";

export const NextPageButton = () => {
  return (
    <button
      className="btn-next"
      onClick={() => {
        window.scrollTo({ top: window.screen.height, behavior: "smooth" });
      }}
    >
      <FaRegHandPointDown />
    </button>
  );
};
