import incentivesTable from "../../../assets/json/incentives.json";
import { useEffect, useState } from "react";
import "../../../style/incentive.css";
import ProgressBar from "@ramonak/react-progress-bar";
import { auth } from "../../../firebase-functions/config";
import {
  createDocument,
  createDocumentWithRandomUid,
  updateDocument,
} from "../../../firebase-functions/database";
import { toast } from "react-toastify";

export const Incentive = (props) => {
  const [level, setLevel] = useState(0);
  const [current, setCurrent] = useState();
  const [target, setTarget] = useState();
  const inc = props.increment;

  useEffect(() => {
    setLevel(
      incentivesTable
        .filter(
          (a) => a[props?.readerProfile?.league] <= props?.readerProfile?.read
        )
        .sort((a, b) => b?.level - a?.level)[0]?.level
    );
  }, []);

  useEffect(() => {
    setCurrent(incentivesTable[level]);
    setTarget(incentivesTable[level + 1]);

    if (props?.setBadge) {
      props.setBadge({
        level: level + 1,
        target: incentivesTable[level + 1][props?.readerProfile?.league],
      });
    }
  }, [level]);

  useEffect(() => {
    if (target) {
      if (
        target[props?.readerProfile?.league] -
          props?.readerProfile?.read -
          inc ===
          0 &&
        inc === 1
      ) {
        props?.setStars && props.setStars(props?.stars + target.reward);
        updateDocument(
          props.readerProfile.type === "demo" ? "demoReaders" : "readers",
          props.readerProfile.id,
          {
            stars: parseInt(props.readerProfile.stars) + target.reward,
            badge: target?.title,
          },
          auth.currentUser
        ).then((res) => {
          if (!res.success) {
            toast.error("something went wrong reader profile", res.message);
          }
        });
        if (target.email) {
          createDocumentWithRandomUid("mail", {
            to: auth.currentUser.email,
            message: {
              subject: "You Have Received " + target?.reward + " Stars",
              html: `<!DOCTYPE html>
            <html lang="en">
            <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>Congratulations! You've Leveled Up!</title>
              <style>
                body {
                  font-family: Arial, sans-serif;
                  margin: 0;
                  padding: 0;
                  background-color: #f5f5f5; /* Light gray background */
                }
            
                .container {
                  
                  margin: 50px auto;
                  padding: 30px;
                  background-color: #fff; /* White background for content */
                  border-radius: 5px; /* Rounded corners */
                  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
                }
            
                h1, h2 {
                  font-size: 24px;
                  margin-bottom: 15px;
                  text-align: center;
                }
            
                p {
                  font-size: 16px;
                  line-height: 1.5;
                }
            
                .level-name {
                  font-weight: bold;
                }
            
                .logo {
                  display: block;
                  margin-bottom: 20px;
                  margin: auto;
                }
              </style>
            </head>
            <body>
              <div class="container">                
                <h1 class="level-name">${target?.header}</h1>
                <h2 class="level-name">${target?.reward} stars</h2>
                <p>${target?.message}</p>
                <p>Keep up the fantastic reading! </p>

                <a href= "https://book-battle.com"> <button> Go back to reading </button> </a>
                <p>Sincerely,</p>
                <p><a href= "https://book-battle.com"> The Book Battle Team </a></p>
                
               <a href= "https://book-battle.com"> <img src="https://firebasestorage.googleapis.com/v0/b/kids-az.appspot.com/o/public%2Flogo.png?alt=media&token=cc583918-796e-4ebb-8094-986e29948cf1" alt="Book Battle Logo" class="logo" width="200" height="50" > </>

              </div>
            </body>
            </html>
            `,
            },
          });
        }
      }
    }
  }, [target]);
  return (
    <div className="incentive-container">
      {target &&
      target[props?.readerProfile?.league] - props?.readerProfile?.read - inc >
        0 ? (
        <h2>
          Read{" "}
          {target[props?.readerProfile?.league] -
            props?.readerProfile?.read -
            inc}{" "}
          more book
          {target[props?.readerProfile?.league] -
            props?.readerProfile?.read -
            1 ===
          1
            ? ""
            : "s"}{" "}
          to win {target?.reward} stars
        </h2>
      ) : (
        <div>
          <h2>{target?.header}</h2>
          <p>{target?.message}</p>
        </div>
      )}

      <div className="progress-bar">
        <div className="justify-between">
          <p>{current && current[props?.readerProfile?.league]}</p>
          <p>{current && target[props?.readerProfile?.league]}</p>
        </div>

        <ProgressBar
          completed={(props?.readerProfile?.read + inc) * 100 || 10}
          maxCompleted={
            (current && target[props?.readerProfile?.league]) * 100 || 1000000
          }
          bgColor="#3498db"
          baseBgColor="#ecf0f1aa"
          labelColor="#ffffff"
          isLabelVisible={true}
          customLabel={props?.readerProfile?.read + inc + " books"}
          transitionTimingFunction="ease-in"
        />
        <div className="justify-between">
          <p className="level-title">{current?.title}</p>
          <p className="level-title">{target?.title}</p>
        </div>
      </div>
    </div>
  );
};
