import { useEffect, useState } from "react";

import "../../../style/readingRoom.css";
import { Book } from "./Book";

import {
  getDocument,
  getRandomBooks,
  getSavedBooks,
  getBookTags,
  getAllNewCompetitions,
  getGamePass,
} from "../../../firebase-functions/database";
import { toast } from "react-toastify";
import { BookCard } from "./bookCard";
import { Incentive } from "./Incentive";
import { CountDown } from "../CountDown";
import { JoinReadingCompetition } from "../JoinReadingCompetition";
import { auth } from "../../../firebase-functions/config";
import { Badges } from "../Badges";
import library from "../../../assets/json/library.json";

export const ReadingRoom = (props) => {
  const [books, setBooks] = useState([]);
  const [savedBooks, setSavedBooks] = useState([]);
  const [leagueInfo, setLeagueInfo] = useState([]);
  const [activeBook, setActiveBook] = useState();
  const [quote, setQuote] = useState();
  const [bookmarks, setBookmarks] = useState([]);
  const [bookmarksView, setBookmarksView] = useState(false);
  const [tags, setTags] = useState();
  const [activeTag, setActiveTag] = useState();
  const [booksToAvoidHistory, setBooksToAvoidHistory] = useState([]);
  const [upComingCompetitions, setUpComingCompetitions] = useState([]);
  const [popUpJoin, setPopUpJoin] = useState(false);
  const [competitionInfo, setCompetitionInfo] = useState();
  const [openBookListen, setOpenBookListen] = useState(false);

  const handleActiveTag = (tag) => {
    if (activeTag && tag === activeTag) {
      setActiveTag();
    } else {
      setActiveTag(tag);
    }
  };

  useEffect(() => {
    // setActiveBook({
    //   id: 2472,
    //   title: "How Many Teeth?",
    //   summary:
    //     "Some animals only have a couple teeth while others can have over twenty thousand teeth! <i>How Many Teeth?</i> is an informative and entertaining book about how different animals have different numbers of teeth. Detailed photographs of unusual animals will keep readers engaged. The book can also be used to teach students to ask and answer questions and to classify information.",
    //   level: "E",
    //   orientation: "portrait",
    //   tags: ["Informational", "nonfiction"],
    //   wordLength: 67,
    //   lexile: 250,
    //   pages: 13,
    // });

    // setActiveBook({
    //   id: 2014,
    //   title: "Yours or Mine?",
    //   summary:
    //     "<em>Yours or Mine?</em> is a sweet tale of a girl and her mischievous cat. Simple sentences, engaging illustrations and high-frequency words support the text. Use this book to discuss the similarities and differences between people and pets while teaching students about identifying the main idea and details and using complete sentences.",
    //   level: "B",
    //   orientation: "landscape",
    //   tags: ["Realistic", "fiction"],
    //   wordLength: 41,
    //   lexile: 20,
    //   pages: 12,
    //   audio: true,
    // });
    if (localStorage.getItem("book")) {
      const activeBookTemp = parseInt(localStorage.getItem("book"));

      setActiveBook(library.find((book) => book.id === activeBookTemp));

      localStorage.removeItem("book");
    }
  }, []);

  useEffect(() => {
    if (props?.readerProfile?.league) {
      getDocument("leagues", props?.readerProfile?.league).then((res) => {
        if (res.success) {
          setLeagueInfo(res.data);
        } else {
          toast.error("couldn't load the league info", res.message);
        }
      });
    }
    // console.log(props.readerProfile);
    if (!props.readerProfile?.currentCompetition) {
      getAllNewCompetitions().then((res) => {
        if (res.success) {
          setUpComingCompetitions(res.message);
        }
      });
    }
  }, [props.readerProfile]);

  useEffect(() => {
    if (props?.competitionInfo && props.readerProfile) {
      let newObject = props.competitionInfo;
      newObject.id = props?.readerProfile?.competition;
      setCompetitionInfo(newObject);
    }
  }, [props.readerProfile, props?.competitionInfo]);
  useEffect(() => {
    if (
      leagueInfo != undefined &&
      props?.readerProfile &&
      props?.studentInfo &&
      !props.league
    ) {
      let booksToAvoid = [];
      props.studentInfo?.readHistory?.map((bk) => booksToAvoid.push(bk));
      props.studentInfo?.bookmarks?.map((bk) => booksToAvoid.push(bk.id));
      setBooksToAvoidHistory(booksToAvoid);
      if (!props?.league) {
        getRandomBooks(leagueInfo.levels, booksToAvoid, 3, activeTag).then(
          (res) => {
            if (res.success) {
              setBooks(res.message);
              getBookTags(leagueInfo.levels, booksToAvoid).then((res) => {
                if (res.success) {
                  setTags(res.message);
                }
              });
            } else {
              toast.error("Couldn't load the books", res.message);
            }
          }
        );
      }
      if (
        props?.studentInfo?.bookmarks !== undefined &&
        props?.studentInfo?.readHistory !== undefined
      ) {
        getSavedBooks(
          props?.studentInfo?.bookmarks,
          props?.studentInfo?.readHistory || []
        ).then((res) => {
          if (res.success) {
            setSavedBooks(res.message);
          } else {
            toast.error("Couldn't load the saved books", res.message);
          }
        });
      }
    }
  }, [leagueInfo]);

  useEffect(() => {
    getRandomBooks(leagueInfo.levels, booksToAvoidHistory, 3, activeTag).then(
      (res) => {
        if (res.success) {
          setBooks(res.message);
        } else {
          toast.error("Couldn't load the books", res.message);
        }
      }
    );
  }, [props?.league, activeTag]);

  useEffect(() => {
    setBookmarks(props?.studentInfo?.bookmarks || []);
  }, [props.studentInfo]);

  const handleJoinCompetition = (pass) => {
    try {
      getGamePass(pass, props?.readerProfile, auth.currentUser).then((res) => {
        if (res.success) {
          toast.success(res.message);
          setPopUpJoin(false);
        } else {
          toast.error(res.message);
        }
      });
    } catch (res) {
      toast.error(res.message);
    }
  };

  return !activeBook ? (
    <div className="reading-room-contianer">
      {!props.competitionInfo &&
        upComingCompetitions.length > 0 &&
        upComingCompetitions.map((comp) => (
          <div key={comp.id} className="competition-promo-container">
            <div className="competition-promo-inner-box">
              {!props.readerProfile?.competition && (
                <>
                  <div className="promo-header-contianer">
                    <h2>New Competition {comp.name}</h2>
                  </div>
                  <div className="promo-body-container">
                    <p>
                      10 day reading competition. Earn Stars as you read books
                      and complete quizzes. Students with the most stars will
                      win.
                    </p>
                    <p>
                      Based on your league and ranking, you can win up to 1000
                      robux.
                    </p>
                  </div>
                </>
              )}
            </div>
            <CountDown competition={comp} />
            {/* {console.log("comp map", comp)} */}
            {!props.readerProfile?.competition && (
              <div className="container-join-competition">
                <button
                  className="btn-join"
                  onClick={() => {
                    setPopUpJoin(true);
                  }}
                >
                  Join
                </button>
              </div>
            )}
          </div>
        ))}
      {popUpJoin && (
        <JoinReadingCompetition
          setPopUpJoin={setPopUpJoin}
          handleJoinCompetition={handleJoinCompetition}
        />
      )}
      {competitionInfo && (
        <div className="competition-promo-container">
          <CountDown competition={competitionInfo} />
          {/* {console.log("comp props", props?.competitionInfo)} */}
          {props?.competitionInfo.status === "new" && (
            <h2 style={{ textAlign: "center" }}>
              Meanwhile, let's read just for fun
            </h2>
          )}
          <Badges studentInfo={props.studentInfo} />
        </div>
      )}

      {props.readerProfile?.competition &&
        props.competitionInfo?.status === "in progress" && (
          <div className="quote-div">
            <Incentive readerProfile={props?.readerProfile} increment={0} />
          </div>
        )}
      {!props?.league && (
        <div className="book-shelf-tab">
          <button
            onClick={() => setBookmarksView(false)}
            className={bookmarksView ? "btn-tab" : "tab-active"}
          >
            All Books
          </button>
          <button
            onClick={() => setBookmarksView(true)}
            className={!bookmarksView ? "btn-tab" : "tab-active"}
          >
            Saved Books
          </button>
        </div>
      )}
      {props?.league && (
        <h1 className="some-of-the-books">
          Sample books for {props?.league.name}
        </h1>
      )}

      {!bookmarksView && (
        <div className="book-tags-container">
          {tags &&
            Object.keys(tags)
              .sort((a, b) => tags[b] - tags[a])
              .map((tag) => (
                <button
                  key={tag}
                  className={
                    activeTag && activeTag === tag ? "active-book-tag" : ""
                  }
                  onClick={() => {
                    handleActiveTag(tag);
                  }}
                >
                  {tag.toLowerCase()}
                </button>
              ))}
        </div>
      )}
      {!bookmarksView ? (
        <>
          <div className="bookshelf-container">
            {books
              ?.filter((bk) => bk.orientation === "portrait")
              .map((bk) => {
                return (
                  <BookCard
                    bk={bk}
                    setOpenBookListen={setOpenBookListen}
                    key={bk.id}
                    setActiveBook={setActiveBook}
                    studentInfo={props.studentInfo}
                    bookmarks={bookmarks !== undefined ? bookmarks : []}
                    setBookmarks={setBookmarks}
                    isDemo={true}
                  />
                );
              })}
          </div>
          <div className="bookshelf-container landscape-bookshelf-container">
            {books
              ?.filter((bk) => bk.orientation === "landscape")
              .map((bk) => {
                return (
                  <BookCard
                    bk={bk}
                    setOpenBookListen={setOpenBookListen}
                    key={bk.id}
                    setActiveBook={setActiveBook}
                    studentInfo={props.studentInfo}
                    bookmarks={bookmarks !== undefined ? bookmarks : []}
                    setBookmarks={setBookmarks}
                    isDemo={true}
                  />
                );
              })}
          </div>
        </>
      ) : (
        <div className="bookshelf-container">
          {savedBooks?.map((bk) => {
            return (
              <BookCard
                bk={bk}
                key={bk.id}
                setActiveBook={setActiveBook}
                studentInfo={props.studentInfo}
                bookmarks={bookmarks}
                setBookmarks={setBookmarks}
              />
            );
          })}
        </div>
      )}
    </div>
  ) : (
    <Book
      bookId={activeBook?.id}
      setActiveBook={setActiveBook}
      activeBook={activeBook}
      readerProfile={props.readerProfile}
      studentInfo={props.studentInfo}
      competitionInfo={props.competitionInfo}
      openBookListen={openBookListen}
    />
  );
};
