import { useEffect, useState } from "react";
import "../../style/account.css";
import { sendPasswordResetEmail } from "firebase/auth";
import { toast } from "react-toastify";
import { auth } from "../../firebase-functions/config";
import { updateDocument } from "../../firebase-functions/database";
import { PopUpAreYouSure } from "../utils/PopUpAreYouSure";
import "../../style/utils.css";
import { logout } from "../../firebase-functions/auth";
import { IconsRow, RoomIcons } from "./SignUpSteps.jsx/RoomIcons";
export const Account = (props) => {
  const [name, setName] = useState("");
  const [league, setLeague] = useState("");
  const [status, setStatus] = useState("");
  const [tributes, setTributes] = useState([]);
  const [competition, setCompetition] = useState("");
  const [expiresIn, setExpiresIn] = useState();

  const [popUpLogout, setPopUpLogout] = useState(false);
  const [popUpUpChangeLeague, setPopUpUpChangeLeague] = useState(false);
  const [popUpChangeName, setPopUpChangeName] = useState(false);
  const [popUpResetPassword, setPopUpResetPassword] = useState(false);
  const [popUpRoomIcons, setPopUpRoomIcons] = useState(false);
  const [pet, setPet] = useState();
  const [color, setColor] = useState();
  const [car, setCar] = useState();

  const [tempPet, setTempPet] = useState();
  const [tempCar, setTempCar] = useState();
  const [tempColor, setTempColor] = useState();

  const handleClick = (tag, val) => {
    if (tag === "pet") {
      if (!tempPet) {
        setTempPet(val);
      } else {
        setTempPet();
      }
    } else if (tag === "car") {
      if (!tempCar) {
        setTempCar(val);
      } else {
        setTempCar();
      }
    } else if (tag === "color") {
      if (!tempColor) {
        setTempColor(val);
      } else {
        setTempColor();
      }
    }
  };

  const handleSaveIcons = () => {
    if (!tempPet) {
      toast.error("Please Select a Pet");
      return;
    }
    if (!tempCar) {
      toast.error("Please Select a Car");
      return;
    }
    if (!tempColor) {
      toast.error("Please Select a Color");
      return;
    }
    setPet(tempPet);
    setCar(tempCar);
    setColor(tempColor);
    setPopUpRoomIcons(false);

    updateDocument(
      "students",
      auth.currentUser.uid,
      {
        pet: tempPet,
        car: tempCar,
        color: tempColor,
      },
      auth.currentUser
    ).then((res) => {
      if (res.success) {
        toast.success("Icons changed successfully");
      }
    });
  };
  useEffect(() => {
    if (props.studentInfo) {
      setName(props.studentInfo.name);
      setPet(props.studentInfo.pet);
      setCar(props.studentInfo.car);
      setColor(props.studentInfo.color);
    }
    if (props.readerProfile) {
      setLeague(props.readerProfile.league);
      setStatus(props.readerProfile.type);
    }

    if (props.competitionInfo) {
      setCompetition(props.competitionInfo);
      console.log(props.competitionInfo);
    }
  }, [props.competitionInfo]);

  const getTimeLeft = (seconds) => {
    return seconds;
  };
  useEffect(() => {
    if (props?.readerProfile?.type === "demo") {
      let day, hour, minute, second;
      const date = props.readerProfile.dateCreated.toDate();

      setInterval(() => {
        const currentDate = new Date();

        let remainingTime = date - currentDate + 1000 * 60 * 60 * 24 * 3;

        day = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
        hour = Math.floor(
          (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        minute = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
        second = Math.floor((remainingTime % (1000 * 60)) / 1000);
        let message = "";

        if (remainingTime <= 0) {
          message = "demo expired";
        } else {
          if (Math.floor(day) > 0) {
            message = message + day + "d : ";
          }
          if (Math.floor(hour) > 0) {
            message = message + hour + "h : ";
          }
          if (Math.floor(minute) > 0) {
            message = message + minute + "m : ";
          }
          if (Math.floor(second) > 0) {
            message = message + second + "s";
          }
        }
        setExpiresIn(message);
      }, 1000);
    }
  }, [props?.readerProfile]);

  const handleSendResetEmail = async () => {
    try {
      await sendPasswordResetEmail(auth, auth.currentUser.email);
      toast.success("Reset Password Link Sent To Your Email!");
    } catch (error) {
      toast.error("Error sending password reset email:", error.message);
    }
  };

  const handleChangeName = () => {
    if (name.length < 3) {
      toast.error("The name should be at least 3 letters long");
      return;
    }

    updateDocument(
      "students",
      auth.currentUser.uid,
      {
        name: name,
      },
      auth.currentUser
    ).then((res) => {
      if (res.success) {
        toast.success("Name changed successfully");
      }
    });
    updateDocument(
      props?.readerProfile?.type === "demo" ? "demoReaders" : "readers",
      props?.readerProfile?.id,

      {
        name: name,
      },
      auth.currentUser
    ).then((res) => {
      if (res.success) {
        toast.success("Name changed successfully");
      }
    });
  };

  const handleLogout = () => {
    logout().then((res) => {
      if (res.success) {
        toast.success("logged out successfully");
        setTimeout(() => {
          window.location.replace("/login");
        }, 500);
      } else {
        toast.error("Something went horribly wrong");
      }
    });
  };

  const handleChangeLeague = () => {};
  return (
    <div className="container account-container">
      <h2>Account</h2>
      <div className="div-profile-pic">
        <img src="" alt="" />
      </div>
      <div className="table-container">
        <div className="form-line">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          {name !== props.studentInfo.name && (
            <button
              onClick={() => {
                setPopUpChangeName(true);
              }}
            >
              Save
            </button>
          )}
        </div>

        <div className="form-line">
          <label htmlFor="room-icons">Room Icons</label>

          {!popUpRoomIcons ? (
            <IconsRow pet={pet} car={car} color={color} />
          ) : (
            <div className="form-group form-group-pop-up-icons">
              <div
                className="bg-pop-up-icons"
                onClick={() => {
                  setPopUpRoomIcons(false);
                }}
              >
                &nbsp;{" "}
              </div>
              <RoomIcons
                pet={tempPet}
                color={tempColor}
                car={tempCar}
                handleClick={handleClick}
              />
              <div className="div-save-icon-changes">
                <button
                  className="btn-save-icon-changes"
                  onClick={handleSaveIcons}
                >
                  Save Changes
                </button>
              </div>
            </div>
          )}

          <button
            onClick={() => {
              setPopUpRoomIcons(true);
            }}
          >
            Change
          </button>
        </div>
        <div className="form-line">
          <label htmlFor="competition">Competition</label>
          <input
            type="text"
            name="competition"
            disabled
            value={competition?.name}
          />
        </div>
        <div className="form-line">
          <label htmlFor="league">League</label>

          <select
            name="league"
            id="league"
            value={league}
            onChange={(e) => {
              setLeague(e.target.value);
            }}
          >
            <option value="league-1">League 1</option>
            <option value="league-2">League 2</option>
            <option value="league-3">League 3</option>
            <option value="league-4">League 4</option>
          </select>
          {league !== props.readerProfile.league && false && (
            <button onClick={() => setPopUpUpChangeLeague(true)}>Save</button>
          )}
        </div>
        <div className="form-line">
          <label htmlFor="league">Status</label>
          {status !== "demo" ? (
            <input value={"Premium"} disabled></input>
          ) : (
            <input value={expiresIn} disabled />
          )}
          {status === "demo" && (
            <button
              onClick={() => {
                window.location.replace("/payment");
              }}
            >
              Pay
            </button>
          )}
        </div>
      </div>
      {/* <div className="form-line form-tributes">
        <h4>Your Tributes</h4>
        <button>Pilot</button>
        <button>Songkran</button>
      </div> */}
      <div className="form-line form-reset-password">
        <button
          onClick={() => {
            setPopUpResetPassword(true);
          }}
        >
          Reset Password
        </button>
      </div>
      <div className="form-line form-logout">
        <button
          onClick={() => {
            handleLogout();
          }}
        >
          Logout
        </button>
      </div>

      {popUpUpChangeLeague && (
        <PopUpAreYouSure
          question="This feature is coming soon"
          setPopUp={setPopUpUpChangeLeague}
          cancel={() => {
            setPopUpUpChangeLeague(false);
          }}
          // proceedFunction={handleChangeLeague}
          proceedMessage="Won't work"
          cancelMessage="Cancel"
        />
      )}
      {popUpChangeName && (
        <PopUpAreYouSure
          question="Are you sure you want to change your name?"
          setPopUp={setPopUpChangeName}
          proceedFunction={handleChangeName}
          proceedMessage="Change"
          cancelMessage="Cancel"
        />
      )}
      {popUpResetPassword && (
        <PopUpAreYouSure
          question="Send Reset Password?"
          setPopUp={popUpResetPassword}
          proceedFunction={handleSendResetEmail}
          proceedMessage="Change"
          cancelMessage="Cancel"
        />
      )}
    </div>
  );
};
