import { useEffect, useState } from "react";

import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase-functions/config";
import {
  approvePayment,
  declinePayment,
  getAllPendingPayments,
  getAllPendingPaymentsByCountry,
  getDocument,
} from "../../firebase-functions/database";

import "../../style/accountant.css";
import { toast } from "react-toastify";

export const PendingPayments = (props) => {
  const [accountantInfo, setAccountantInfo] = useState();
  const [pendingPayments, setPendingPayments] = useState([]);
  const [activePayment, setActivePayment] = useState();
  const [differentPayment, setDifferentPayment] = useState(false);
  const [actualPayment, setActualPayment] = useState();
  const [note, setNote] = useState();

  useEffect(() => {
    onAuthStateChanged(auth, (u) => {
      getDocument("accountants", auth.currentUser.uid).then((res) => {
        if (res.success) {
          setAccountantInfo(res.data);
        }
      });
    });
  }, []);

  useEffect(() => {
    if (accountantInfo) {
      if (props.userInfo.role === "accountant") {
        getAllPendingPaymentsByCountry(accountantInfo.country).then((res) => {
          if (res.success) {
            setPendingPayments(res.message);
          }
        });
      }
    } else if (props.userInfo.role === "admin") {
      getAllPendingPayments().then((res) => {
        if (res.success) {
          setPendingPayments(res.message);
        }
      });
    }
  }, [accountantInfo, props.userInfo]);

  const getPaymentAge = (date) => {
    const currentDate = new Date();

    let paymentSince =
      parseInt(currentDate.getTime()) - parseInt(date.seconds) * 1000;

    const day = Math.floor(paymentSince / (1000 * 60 * 60 * 24));
    const hour = Math.floor(
      (paymentSince % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minute = Math.floor((paymentSince % (1000 * 60 * 60)) / (1000 * 60));

    if (day > 0) {
      return day + " day" + (day > 1 ? "s" : "") + " ago";
    }
    if (hour > 0) {
      return hour + " hour" + (hour > 1 ? "s" : "") + " ago";
    }
    if (minute > 0) {
      return minute + " minute" + (minute > 1 ? "s" : "") + " ago";
    } else {
      return "just now";
    }
  };

  const handleDeclinePayment = (paymentId) => {
    if (!note) {
      toast.error("please add some note on why it was declined");
      return;
    }
    declinePayment(
      {
        paymentId: paymentId,
        note: note,
        declinedBy: auth.currentUser.uid,
        dateDeclined: new Date(),
      },
      auth.currentUser
    ).then((res) => {
      if (res.success) {
        toast.success(res.message);
        setTimeout(() => {
          setActivePayment();

          let newArray = pendingPayments.filter((p) => p.id !== paymentId);
          setPendingPayments(newArray);
        }, 700);
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleApprovePayment = (payment, paymentId) => {
    payment.approvedBy = auth.currentUser.uid;
    payment.id = paymentId;
    payment.dateApproved = new Date();
    if (differentPayment) {
      payment.fee = actualPayment;
    }
    if (note) {
      payment.note = note;
    } else if (payment.note) {
      delete payment.note;
    }

    approvePayment(payment, auth.currentUser).then((res) => {
      if (res.success) {
        toast.success(res.message);
        setTimeout(() => {
          setActivePayment();

          let newArray = pendingPayments.filter((p) => p.id !== payment.id);
          setPendingPayments(newArray);
        }, 700);
      } else {
        toast.error(res.message);
      }
    });
  };
  return (
    <div className="container">
      <h2>
        {pendingPayments.length > 0
          ? `${pendingPayments.length} Pending Payment${
              pendingPayments.length > 1 ? "s" : ""
            }`
          : "Wow! No Pending Payments"}
      </h2>

      {pendingPayments.length > 0 && (
        <table className="payments-table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Amount</th>

              <th scope="col">Created</th>
            </tr>
          </thead>
          <tbody>
            {pendingPayments.map((payment, index) => {
              return (
                <tr
                  key={payment.paymentSlip}
                  onClick={() => setActivePayment(payment)}
                >
                  <td>{index + 1}</td>
                  <td>{payment.fee}</td>

                  <td>{getPaymentAge(payment?.dateCreated)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {activePayment && (
        <div>
          <div className="payment-pop-up-container">
            <h2>Payment</h2>
            <img
              src={activePayment.paymentSlip}
              className="payment-slip-large"
              alt="paymentSlip"
            />
            <div className="form-group">
              <div className="form-group">
                <button
                  className="btn-approve"
                  onClick={() => {
                    handleApprovePayment(activePayment, activePayment.id);
                  }}
                >
                  Approve
                </button>
              </div>

              <div className="form-checkbox">
                <input
                  type="checkbox"
                  name="actual-payment"
                  onChange={(e) => {
                    setDifferentPayment(e.target.checked);
                  }}
                  id=""
                />
                <label htmlFor="actual-payment">
                  payment is not {activePayment.fee}?
                </label>
              </div>
              {differentPayment && (
                <div className="form-group">
                  <label htmlFor="actual-payment">Actual Payment</label>
                  <input
                    type="number"
                    name="actual-payment"
                    value={actualPayment}
                    onChange={(e) => setActualPayment(e.target.value)}
                  />
                </div>
              )}
              <div className="form-group">
                <label htmlFor="note">Note</label>
                <textarea
                  type="text"
                  name="note"
                  className="note"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>
              <div className="form-group">
                <button
                  className="btn-decline"
                  onClick={() => handleDeclinePayment(activePayment.id)}
                >
                  Decline
                </button>
              </div>
            </div>
          </div>

          <div
            className="payment-pop-up-background"
            onClick={() => setActivePayment()}
          ></div>
        </div>
      )}
    </div>
  );
};
