import React from "react";
import { useSpring, animated } from "@react-spring/web";

function AnimatedNumber({ value }) {
  const { number } = useSpring({
    from: { number: 1 },
    to: { number: value },
    config: { duration: 1000 },
  });

  return <animated.span>{number.to((n) => Math.floor(n))}</animated.span>;
}

export default AnimatedNumber;
